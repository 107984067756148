import { Grid } from '@mui/material';
import Page from '../../common/Page';
import { Routes, useCompanyRoutes } from '../../router';
import { CompanyForm } from './CompanyForm';

export function CompanyCreate({
  isReseller = false,
}: {
  isReseller?: boolean;
}) {
  const { appRoute } = useCompanyRoutes();
  return (
    <Page
      breadcrumbs={[
        { label: 'sidebar.globalSettings' },
        {
          label: 'sidebar.companies',
          url: appRoute(Routes.COMPANIES),
        },
      ]}
      title="companiesDashboard.createCompany"
    >
      <Grid container>
        <Grid item xs={12}>
          <CompanyForm isReseller={isReseller} />
        </Grid>
      </Grid>
    </Page>
  );
}
