import { useStatsControllerStatsQuery } from '../../app/services/futbolProdeApi';
import { Grid, CardContent, Stack, Typography, Skeleton } from '@mui/material';
import { isNil } from 'ramda';
import { useTranslation } from 'react-i18next';
import { Unless } from 'react-if';
import { RoundedCard } from '../../common/MuiExtensions';

export function StatsCards() {
  const { data: stats, isLoading } = useStatsControllerStatsQuery();

  return (
    <Grid container alignItems="stretch" columnSpacing={2}>
      <StatCard
        color="warning"
        isLoading={isLoading}
        main={{
          name: 'registeredUsersCount',
          value: stats?.registeredUsersCount,
          total: stats?.totalUsersCount,
        }}
        secondary={{
          name: 'pendingUsersCount',
          value: stats?.pendingUsersCount,
        }}
      />
      <StatCard
        color="info"
        isLoading={isLoading}
        main={{
          name: 'lastWeekActiveUsersCount',
          value: stats?.lastWeekActiveUsersCount,
        }}
        secondary={{
          name: 'lastWeekActiveUsersCountExplain',
        }}
      />
      <StatCard
        color="error"
        isLoading={isLoading}
        main={{
          name: 'areasCount',
          value: stats?.areasCount,
        }}
      />
      <StatCard
        color="success"
        isLoading={isLoading}
        main={{
          name: 'nextWeekMatchesCount',
          value: stats?.nextWeekMatchesCount,
        }}
        secondary={{
          name: 'nextWeekMatchesCountExplain',
        }}
      />
    </Grid>
  );
}
export function StatCard({ main, secondary, color, isLoading }: StatCardProps) {
  const { t } = useTranslation();
  return (
    <Grid item xs={12} md={3}>
      <RoundedCard sx={{ height: '100%' }}>
        <CardContent sx={{ height: 'inherit' }}>
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ height: 'inherit' }}
          >
            <Stack direction="row" alignItems="baseline" spacing={0.5}>
              <Typography
                color={`${color}.main`}
                variant="h3"
                fontWeight="bold"
              >
                {isLoading ? <Skeleton width={50} /> : main.value}
              </Typography>
              <Unless condition={isNil(main.total)}>
                <Typography color={`${color}.main`} variant="h5">
                  / {main.total}
                </Typography>
              </Unless>
            </Stack>
            <Typography color={`${color}.light`} gutterBottom>
              {t(`homePage.${main.name}`)}
            </Typography>
            <Unless condition={isNil(secondary)}>
              <Typography color="text.secondary" variant="body2" mt={2}>
                {t(`homePage.${secondary?.name}`)}{' '}
                <Typography
                  color="text.primary"
                  variant="body2"
                  display="inline"
                >
                  {isLoading ? (
                    <Skeleton width={30} sx={{ display: 'inline-block' }} />
                  ) : (
                    secondary?.value
                  )}
                </Typography>
              </Typography>
            </Unless>
          </Stack>
        </CardContent>
      </RoundedCard>
    </Grid>
  );
}
interface Stat {
  name: string;
  value?: number;
  total?: number;
}

export interface StatCardProps {
  main: Stat;
  secondary?: Stat;
  isLoading: boolean;
  color: 'success' | 'error' | 'warning' | 'info' | 'primary' | 'secondary';
}
