import {
  Stack,
  Checkbox,
  FormControlLabel,
  Alert,
  LinearProgress,
} from '@mui/material';
import {
  User,
  useAreasControllerFindAllQuery,
  useUsersControllerFindOneQuery,
  useUsersControllerUpdateMutation,
  useCompaniesControllerFindMeQuery,
  useInvitationsControllerCreateMutation,
  useInvitationsControllerFindOneQuery,
  useInvitationsControllerUpdateMutation,
} from '../../app/services/futbolProdeApi';
import { LoadingButton } from '@mui/lab';
import { Formik } from 'formik';
import ImageUploadField from '../../common/ImageUploadField';
import { PageSection } from '../../common/PageSection';
import { Input } from '../../common/form/Input';
import { useParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useTranslation } from 'react-i18next';
import AreaSelect from './AreaSelect';
import { any, isNil } from 'ramda';
import { externalIdLabel } from './userHelpers';
import { LoginStrategy } from './LoginStrategy';
import { When } from 'react-if';
import { LanguageSelect } from '../../common/form/LanguageSelect';

type UserWithAuth = User & { password: string; repeatPassword: string };

export function UserForm({
  kind,
  isSelf = false,
}: {
  kind: 'invitation' | 'user';
  isSelf?: boolean;
}) {
  const { t } = useTranslation();
  const { id } = useParams();

  const isCreating = isNil(id);

  const { data: user, isLoading: userIsLoading } =
    useUsersControllerFindOneQuery(
      kind === 'user' && !isCreating ? id : skipToken,
    );

  const { data: invitation, isLoading: invitationIsLoading } =
    useInvitationsControllerFindOneQuery(
      kind === 'invitation' && !isCreating ? id : skipToken,
    );

  const initialValue = ((kind === 'user' ? user : invitation) ??
    {}) as UserWithAuth;
  const isLoading = userIsLoading || invitationIsLoading;

  const { data: company } = useCompaniesControllerFindMeQuery();
  const { data: areasLevel1, isLoading: isLoadingAreasLevel1 } =
    useAreasControllerFindAllQuery(isSelf ? skipToken : 1);
  const { data: areasLevel2, isLoading: isLoadingAreasLevel2 } =
    useAreasControllerFindAllQuery(isSelf ? skipToken : 2);
  const { data: areasLevel3, isLoading: isLoadingAreasLevel3 } =
    useAreasControllerFindAllQuery(isSelf ? skipToken : 3);

  const [updateUser, updateUserResult] = useUsersControllerUpdateMutation();
  const [createInvitation, createInvitationResult] =
    useInvitationsControllerCreateMutation();
  const [updateInvitation, updateInvitationResult] =
    useInvitationsControllerUpdateMutation();

  const isSubmitting = any(
    (it) => it.isLoading,
    [createInvitationResult, updateInvitationResult, updateUserResult],
  );

  const handleSubmit = async (formValues: User) => {
    const updateUserDto = {} as any;
    [
      'name',
      'externalId',
      'email',
      'password',
      'preferredLanguage',
      'role',
      'profilePictureUrl',
      'portraitPictureUrl',
    ].forEach((field) => {
      // @ts-ignore
      const formFieldValue = formValues[field];
      // @ts-ignore
      if (formFieldValue && formFieldValue !== initialValue?.[field]) {
        updateUserDto[field] = formFieldValue;
      }
    });

    if (formValues.emailVerified !== (initialValue?.emailVerified ?? false)) {
      updateUserDto.emailVerified = formValues.emailVerified;
    }
    ['areaLevel1', 'areaLevel2', 'areaLevel3'].forEach((areaField) => {
      // @ts-ignore
      const formFieldValue = formValues[areaField];

      if (isNil(formFieldValue)) {
        updateUserDto[areaField] = null;
      } else if (
        formFieldValue?.id &&
        // @ts-ignore
        formFieldValue?.id !== initialValue?.[areaField]?.id
      ) {
        updateUserDto[areaField] = { id: formFieldValue.id };
      }
    });

    if (kind === 'invitation') {
      if (isCreating) {
        return createInvitation(updateUserDto);
      }

      return updateInvitation({
        id: initialValue.id.toString(),
        updateInvitationDto: updateUserDto,
      });
    }

    updateUser({
      id: initialValue.id,
      updateUserDto,
    });
  };

  const handleValidation = (values: any) => ({
    ...(values.repeatPassword === values.password
      ? {}
      : { password: t('validations.passwords.doNotMatch') }),
  });

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <Formik
      initialValues={initialValue}
      onSubmit={handleSubmit}
      validateOnChange={false}
      handleSubmit
      validate={handleValidation}
    >
      {({ handleSubmit, handleChange, values, errors, setFieldValue }) => (
        <form onSubmit={handleSubmit}>
          <Stack spacing={2}>
            <PageSection title="companyForm.basicData">
              <Input
                value={values.name}
                name={'name'}
                label={`${t('usersForm.fullName')} *`}
                onChange={handleChange}
                error={errors.name}
              />
              <When
                condition={
                  company?.loginStrategy === LoginStrategy.byExternalId
                }
              >
                <Input
                  value={values.externalId}
                  name={'externalId'}
                  label={externalIdLabel(t)}
                  onChange={handleChange}
                  error={errors.externalId}
                />
              </When>
              <Input
                value={values.email}
                name={'email'}
                label={`${t('usersForm.email')} *`}
                onChange={handleChange}
                error={errors.email}
              />
              <When condition={isSelf}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.emailVerified}
                      disabled={user?.emailVerified}
                      name="emailVerified"
                      value={values.emailVerified}
                      onChange={handleChange}
                    />
                  }
                  label={t('usersForm.emailConfirmed')}
                />
              </When>
              <When condition={!isSelf && kind === 'user'}>
                <LanguageSelect
                  value={values.preferredLanguage}
                  onChange={handleChange}
                  name="preferredLanguage"
                />
              </When>
            </PageSection>
            <When condition={kind === 'user'}>
              <PageSection title="usersForm.auth">
                <Alert severity="warning">
                  {t('usersForm.changePasswordWarning')}
                </Alert>
                <Input
                  value={values.password}
                  name={'password'}
                  label={'usersForm.password'}
                  onChange={handleChange}
                  error={errors.password}
                />
                <Input
                  value={values.repeatPassword}
                  name={'repeatPassword'}
                  label={'usersForm.repeatPassword'}
                  onChange={handleChange}
                  error={errors.repeatPassword}
                />
              </PageSection>
            </When>
            {isSelf ? null : (
              <PageSection title="usersForm.areas">
                {!isNil(areasLevel1) && !isLoadingAreasLevel1 ? (
                  <AreaSelect
                    areas={areasLevel1}
                    initialValue={user?.areaLevel1}
                    placeholderLabel={t('usersForm.areaLevel1')}
                    onValueUpdated={(area) => {
                      setFieldValue('areaLevel1', area);
                    }}
                  />
                ) : (
                  <></>
                )}
                {!isNil(areasLevel2) && !isLoadingAreasLevel2 ? (
                  <AreaSelect
                    areas={
                      values.areaLevel1
                        ? areasLevel2.filter(
                            (it) => it.parentArea.id === values.areaLevel1.id,
                          )
                        : areasLevel2
                    }
                    initialValue={user?.areaLevel2}
                    placeholderLabel={t('usersForm.areaLevel2')}
                    onValueUpdated={(area) => {
                      setFieldValue('areaLevel2', area);
                    }}
                  />
                ) : (
                  <></>
                )}
                {!isNil(areasLevel3) && !isLoadingAreasLevel3 ? (
                  <AreaSelect
                    areas={
                      values.areaLevel2
                        ? areasLevel3.filter(
                            (it) => it.parentArea.id === values.areaLevel2.id,
                          )
                        : areasLevel3
                    }
                    initialValue={user?.areaLevel3}
                    placeholderLabel={t('usersForm.areaLevel3')}
                    onValueUpdated={(area) => {
                      setFieldValue('areaLevel3', area);
                    }}
                  />
                ) : (
                  <></>
                )}
              </PageSection>
            )}
            <When condition={kind === 'user'}>
              <PageSection title="usersForm.picturesUrl">
                <ImageUploadField
                  label={t('usersForm.profilePictureUrl')}
                  error={errors.profilePictureUrl}
                  value={values.profilePictureUrl ?? ''}
                  fieldName={'profilePictureUrl'}
                  setFieldValue={setFieldValue}
                />
                <ImageUploadField
                  label={t('usersForm.portraitPictureUrl')}
                  error={errors.portraitPictureUrl}
                  value={values.portraitPictureUrl ?? ''}
                  fieldName={'portraitPictureUrl'}
                  setFieldValue={setFieldValue}
                />
              </PageSection>
            </When>
            <LoadingButton
              disabled={isLoading}
              loading={isSubmitting}
              fullWidth
              type="submit"
              variant="contained"
            >
              {t('save')}
            </LoadingButton>
          </Stack>
        </form>
      )}
    </Formik>
  );
}
