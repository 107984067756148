import { MuiColorInput } from 'mui-color-input';
import InputWrapper, { InputWrapperProps } from './InputWrapper';
import { FormHelperText } from '@mui/material';
import { FormikErrors, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { isEmpty, path } from 'ramda';

export type ColorInputProps<T> = Omit<InputWrapperProps, 'children'> & {
  values: T;
  errors: FormikErrors<T>;
  name: keyof T | string;
  label: string;
  helperText?: string;
  disabled?: boolean;
  setFieldValue: FormikHelpers<T>['setFieldValue'];
};

export default function ColorInput<T>({
  values,
  errors,
  setFieldValue,
  disabled,
  ...props
}: ColorInputProps<T>) {
  const { t } = useTranslation();
  return (
    <InputWrapper {...props}>
      <>
        <MuiColorInput
          fullWidth
          disabled={disabled}
          name={props.name}
          value={path(props.name.split('.'), values) ?? ''}
          onChange={(value: string) =>
            setFieldValue(props.name, isEmpty(value) ? null : value)
          }
        />
        <FormHelperText error>
          {t(path(props.name.split('.'), errors) as string)}
        </FormHelperText>
      </>
    </InputWrapper>
  );
}
