import { useTranslation } from 'react-i18next';
import { RoundedAccordion } from '../../common/MuiExtensions';
import {
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from '@mui/material';
import AccountInfoCard from './AccountInfoCard';
import { ExpandMore } from '@mui/icons-material';
import { useCompaniesControllerMyTournamentsQuery } from '../../app/services/futbolProdeApi';
import { useCompanyTranslations } from '@futbolprode/ui-common';
import { gameUrl } from './companyHelpers';

export default function AccountInfo() {
  const { t } = useTranslation();
  const { data: company, isLoading } =
    useCompaniesControllerMyTournamentsQuery();
  const { tournamentName } = useCompanyTranslations();

  return (
    <RoundedAccordion
      sx={{
        // Quitar divider
        '&.MuiAccordion-root:before': {
          opacity: 0,
        },
      }}
    >
      <AccordionSummary
        sx={{
          color: 'primary.contrastText',
          backgroundColor: 'primary.main',
          borderRadius: 'inherit',
        }}
        expandIcon={
          <ExpandMore
            sx={{
              backgroundColor: 'black',
              color: 'white',
              borderRadius: '4px',
            }}
          />
        }
      >
        <Typography variant="h6">{t('homePage.accountInfo.title')}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={3} pt={1}>
          <AccountInfoCard
            isLoading={isLoading}
            title={t('homePage.accountInfo.data')}
            data={[
              ['entity.company._name', company?.name],
              ['homePage.accountInfo.gameUrl', gameUrl(company)],
            ]}
          />
          <AccountInfoCard
            isLoading={isLoading}
            title={t('homePage.accountInfo.contract')}
            data={[
              [
                'sidebar.tournament',
                company?.tournaments.map(tournamentName).join(', '),
              ],
              [
                'homePage.accountInfo.usersCount',
                t('homePage.accountInfo.usersCountValue', {
                  value: company?.usersCount,
                  max: company?.maxUsersAmount,
                }) as string,
              ],
            ]}
          />
        </Grid>
      </AccordionDetails>
    </RoundedAccordion>
  );
}
