import { Grid, Fab } from '@mui/material';
import { t } from 'i18next';
import ImageUploadField from '../../common/ImageUploadField';
import { Delete } from '@mui/icons-material';
import { Image } from 'mui-image';
import { FormikErrors, FormikHelpers } from 'formik';
import { capitalizeFirst } from '../../common/helpers/stringHelpers';
import { useMemo } from 'react';
import { BannerKind, BannerVersion, CompanyBannersDto } from './BannerForm';

const specs = {
  superior: {
    desktop: {
      explanationImage:
        'https://d1g4ax6chcx3tu.cloudfront.net/4/f8921a02-f326-438a-8715-ff6bbc04a996.jpg',
      dimensions: '1488px * 200px',
    },
    mobile: {
      explanationImage:
        'https://d1g4ax6chcx3tu.cloudfront.net/4/a6b1b4a4-0159-4ac5-855e-e22c3164f9d3.jpg',
      dimensions: '368px * 150px',
    },
  },
  middle: {
    desktop: {
      explanationImage:
        'https://d1g4ax6chcx3tu.cloudfront.net/4/ddd1f7d9-010e-4b7f-8015-94f296900924.jpg',
      dimensions: '1488px * 200px',
    },
    mobile: {
      explanationImage:
        'https://d1g4ax6chcx3tu.cloudfront.net/4/0bb73224-f475-44bd-b3cd-192b1598e900.jpg',
      dimensions: '360px * 200px',
    },
  },
  side: {
    desktop: {
      explanationImage:
        'https://d1g4ax6chcx3tu.cloudfront.net/4/b5c537a7-47cf-435f-a863-21451a8e33ea.jpg',
      dimensions: '468px * 200px',
    },
    mobile: {
      explanationImage:
        'https://d1g4ax6chcx3tu.cloudfront.net/4/93e9fbf1-574c-4c86-b8ca-089eb1fe553c.jpg',
      dimensions: '360px * 200px',
    },
  },
};

export interface BannerInputProps {
  version: BannerVersion;
  kind: BannerKind;
  values: CompanyBannersDto;
  errors: FormikErrors<CompanyBannersDto>;
  setFieldValue: FormikHelpers<any>['setFieldValue'];
  onDelete: (kind: BannerKind, version: BannerVersion) => Promise<void>;
}

const bannerFieldName = (kind: BannerKind, version: BannerVersion) =>
  `${kind}Banner${capitalizeFirst(version)}ImageUrl` as keyof CompanyBannersDto;

export default function BannerInput({
  version,
  kind,
  errors,
  values,
  setFieldValue,
  onDelete,
}: BannerInputProps) {
  const fieldName = useMemo(
    () => bannerFieldName(kind, version),
    [kind, version],
  );
  return (
    <Grid container alignItems="center">
      <Grid item md={2} xs={12}>
        <Image
          src={specs[kind][version].explanationImage}
          style={{ maxHeight: 100 }}
          fit="contain"
          showLoading
        />
      </Grid>
      <Grid item md={9} xs={11}>
        <ImageUploadField
          label={t(`bannersPage.${version}Version`)}
          dimensions={specs[kind][version].dimensions}
          error={errors[fieldName]}
          value={values[fieldName] ?? ''}
          fieldName={fieldName}
          setFieldValue={setFieldValue}
        />
      </Grid>
      <Grid item xs={1}>
        <Fab
          onClick={() => {
            const deleteUserConfirmMessage = t(
              'bannersPage.deleteBannerConfirmMessage',
              {
                version: `$t(bannersPage.${version}Version)`,
                kind: `$t(bannersPage.${kind}Kind)`,
                interpolation: { skipOnVariables: false },
              },
            );
            if (window.confirm(deleteUserConfirmMessage)) {
              onDelete(kind, version);
              setFieldValue(fieldName, '');
            }
          }}
          color="secondary"
          size="small"
        >
          <Delete />
        </Fab>
      </Grid>
    </Grid>
  );
}
