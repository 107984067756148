import {
  Company,
  CompanyWithUsersCount,
} from '../../app/services/futbolProdeApi';

export function gameUrl(company?: Company | CompanyWithUsersCount) {
  return (
    company?.appCustomDomain ?? `juego.futbolprode.com/${company?.appPath}`
  );
}
