import { Skeleton, TableCell, TableCellProps } from '@mui/material';

export type SkeletonTableCellProps = TableCellProps & { isLoading: boolean };

export const SkeletonTableCell = ({
  isLoading,
  ...tableCellProps
}: SkeletonTableCellProps) => (
  <TableCell {...tableCellProps}>
    {isLoading ? <Skeleton width="100%" /> : tableCellProps.children}
  </TableCell>
);
