import { Stack, InputLabel, FormHelperText, Button } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { When } from 'react-if';
import Highlight from 'react-highlight';
import HtmlEditor from './HtmlEditor';
import { HtmlEditorProps } from './HtmlEditor';

export interface HtmlEditorInputProps extends HtmlEditorProps {
  label: string;
  errorLabel: string | undefined;
  isRequired?: boolean;
}

function dirtyFormatHtml(html: string) {
  const tab = '\t';
  var result = '';
  var indent = '';

  html.split(/>\s*</).forEach(function (element) {
    if (element.match(/^\/\w/)) {
      indent = indent.substring(tab.length);
    }

    result += indent + '<' + element + '>\r\n';

    if (element.match(/^<?\w[^>]*[^/]$/) && !element.startsWith('input')) {
      indent += tab;
    }
  });

  return result.substring(1, result.length - 3);
}

export function HtmlEditorInput({
  label,
  isRequired = false,
  errorLabel,
  ...editorProps
}: HtmlEditorInputProps) {
  const { t } = useTranslation();
  const [showHtml, setShowHtml] = useState(false);

  return (
    <Stack>
      <InputLabel>
        {t(label)}
        {isRequired && ' *'}
      </InputLabel>
      <HtmlEditor {...editorProps} />
      <Button onClick={() => setShowHtml(!showHtml)}>
        {t(
          showHtml
            ? 'common.htmlEditor.hideHtml'
            : 'common.htmlEditor.showHtml',
        )}
      </Button>
      <When condition={showHtml}>
        <Highlight className="html">
          {dirtyFormatHtml(editorProps.value)}
        </Highlight>
      </When>
      <FormHelperText error>{t(errorLabel!)}</FormHelperText>
    </Stack>
  );
}
