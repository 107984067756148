import { Stack } from '@mui/material';
import { Input } from '../../common/form/Input';
import ImageUploadField from '../../common/ImageUploadField';
import { FormikHandlers, FormikHelpers } from 'formik';
import { CreateAwardDto } from './AwardsPage';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { When } from 'react-if';

export default function AwardItemForm({
  values,
  custom = false,
  namePrefix,
  handleChange,
  setFieldValue,
}: {
  values: CreateAwardDto;
  custom?: boolean;
  namePrefix: string;
  handleChange: FormikHandlers['handleChange'];
  setFieldValue: FormikHelpers<any>['setFieldValue'];
}) {
  const { t } = useTranslation();
  const fieldName = useCallback(
    (name: string) => `${namePrefix}.${name}`,
    [namePrefix],
  );

  return (
    <Stack spacing={1}>
      <When condition={custom}>
        <Input
          value={values.customPosition}
          name={fieldName('customPosition')}
          label={'entity.award.customPosition'}
          onChange={handleChange}
          required
        />
      </When>
      <Input
        value={values.name}
        name={fieldName('name')}
        label={'entity.award.name'}
        onChange={handleChange}
        required
      />
      <Input
        value={values.description}
        name={fieldName('description')}
        label={'entity.award.description'}
        onChange={handleChange}
      />
      <ImageUploadField
        label={t('entity.award.imageUrl')}
        value={values.imageUrl ?? ''}
        error={undefined}
        fieldName={fieldName('imageUrl')}
        setFieldValue={setFieldValue}
        dimensions="200px * 200px"
      />
    </Stack>
  );
}
