import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { format } from 'date-fns';
import { SkeletonTableCell } from '../../common/SkeletonTableCell';
import { Unless, When } from 'react-if';
import { useTranslation } from 'react-i18next';
import {
  AreaRanking,
  Ranking,
  Tournament,
} from '../../app/services/futbolProdeApi';
import { useCallback, useMemo } from 'react';
import { useDreamFinalVisibilityQuery } from '@futbolprode/ui-common';
import { Nullable } from 'typescript-nullable';
import UserAvatar from '../../common/UserAvatar';
import { Stack, Typography } from '@mui/material';
import { RankingGrouping } from './useTournamentRankingQuery';

export interface RankingsTableProps {
  rankingType: 'round' | 'tournament' | 'general';
  grouping: RankingGrouping;
  data: (Ranking | AreaRanking)[];
  isFetching: boolean;
  tournament?: Nullable<Tournament>;
  hidePosition?: boolean;
  hideContainer?: boolean;
}

export default function RankingsTable({
  rankingType,
  tournament,
  data,
  isFetching,
  grouping,
  hidePosition = false,
  hideContainer = false,
}: RankingsTableProps) {
  const { t } = useTranslation();

  const {
    data: { showForGeneralRanking, showForTournament },
  } = useDreamFinalVisibilityQuery();

  const showDreamFinalColumns = useMemo(
    () =>
      grouping === 'user' &&
      ((rankingType === 'general' && showForGeneralRanking) ||
        (rankingType === 'tournament' && showForTournament(tournament!))),
    [
      rankingType,
      grouping,
      showForGeneralRanking,
      tournament,
      showForTournament,
    ],
  );

  const renderTable = useCallback(
    (children: JSX.Element) =>
      hideContainer ? (
        children
      ) : (
        <TableContainer component={Paper}>{children}</TableContainer>
      ),
    [hideContainer],
  );

  return renderTable(
    <Table sx={{ minWidth: 300 }}>
      <TableHead>
        <TableRow>
          <When condition={grouping === 'user'}>
            <TableCell></TableCell>
          </When>
          <Unless condition={hidePosition}>
            <TableCell>{t('rankings.position')}</TableCell>
          </Unless>
          <TableCell>{t(`rankings.${grouping}`)}</TableCell>
          <When condition={showDreamFinalColumns}>
            <TableCell align="center">{t('rankings.total')}</TableCell>
          </When>
          <TableCell align="center">{t('rankings.points')}</TableCell>
          <When condition={showDreamFinalColumns}>
            <TableCell align="center">
              {t('rankings.dreamFinalPoints')}
            </TableCell>
          </When>
          <TableCell align="center">{t('rankings.exactMatches')}</TableCell>
          <TableCell align="center">{t('rankings.predictionsCount')}</TableCell>
          <When condition={grouping === 'user'}>
            <TableCell>{t('rankings.activationDate')}</TableCell>
          </When>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((row, index) => (
          <TableRow
            key={index}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <When condition={grouping === 'user'}>
              <SkeletonTableCell isLoading={isFetching}>
                <UserAvatar user={(row as Ranking).user} size={50} />
              </SkeletonTableCell>
            </When>
            <Unless condition={hidePosition}>
              <SkeletonTableCell isLoading={isFetching}>
                {row.position}
              </SkeletonTableCell>
            </Unless>
            <SkeletonTableCell isLoading={isFetching}>
              <Stack direction="column">
                <Typography fontWeight="bold">
                  {'user' in row ? row.user?.name : row.areaName}
                </Typography>
                <When condition={grouping === 'user'}>
                  <Typography variant="subtitle1" color="textSecondary">
                    {(row as Ranking).user?.areaLevel1?.name}
                  </Typography>
                </When>
              </Stack>
            </SkeletonTableCell>
            <When condition={showDreamFinalColumns}>
              <SkeletonTableCell isLoading={isFetching} align="center">
                {row.points + ((row as Ranking).dreamFinalPoints ?? 0)}
              </SkeletonTableCell>
            </When>
            <SkeletonTableCell isLoading={isFetching} align="center">
              {row.points}
            </SkeletonTableCell>
            <When condition={showDreamFinalColumns}>
              <SkeletonTableCell isLoading={isFetching} align="center">
                {(row as Ranking).dreamFinalPoints ?? '-'}
              </SkeletonTableCell>
            </When>
            <SkeletonTableCell isLoading={isFetching} align="center">
              {row.exactMatches}
            </SkeletonTableCell>
            <SkeletonTableCell isLoading={isFetching} align="center">
              {row.predictionsCount}
            </SkeletonTableCell>
            <When condition={grouping === 'user'}>
              <SkeletonTableCell isLoading={isFetching}>
                {format(
                  new Date((row as Ranking).user?.createdAt || new Date()),
                  'dd/MM/yyyy HH:mm',
                )}
              </SkeletonTableCell>
            </When>
          </TableRow>
        ))}
      </TableBody>
    </Table>,
  );
}
