import { BannersForm } from './BannersForm';
import { UpdateCompanySettingsDashboard } from '../users/UpdateCompanySettingsDashboard';

export default function BannersPage() {
  return (
    <UpdateCompanySettingsDashboard
      Form={BannersForm}
      title="sidebar.banners"
    />
  );
}
