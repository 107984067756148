import { LoadingButton } from '@mui/lab';
import {
  LinearProgress,
  FormHelperText,
  Stack,
  InputLabel,
} from '@mui/material';
import { IsUrl, IsNotEmpty, IsNotEmptyObject } from 'class-validator';
import createValidator from 'class-validator-formik';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Else, If, Then, When } from 'react-if';
import { useNavigate } from 'react-router-dom';
import {
  Team,
  CreateTeamDto,
  UpdateTeamDto,
  useTeamsControllerCreateMutation,
  useTeamsControllerUpdateMutation,
} from '../../app/services/futbolProdeApi';
import type { Country } from '../../app/services/futbolProdeApi';
import { PageSection } from '../../common/PageSection';
import ImageUploadField from '../../common/ImageUploadField';
import { Routes, useCompanyRoutes } from '../../router';
import { Input } from '../../common/form/Input';
import { useMemo } from 'react';
import { isNil } from 'ramda';
import CountrySelect from './CountrySelect';

export class UpdateTeamDtoValidator {
  constructor() {
    this.name = '';
    this.logoUrl = '';
  }

  @IsNotEmpty({ message: 'validations.name.empty' })
  name: string;

  @IsUrl(undefined, { message: 'validations.logoUrl.notUrl' })
  @IsNotEmpty({ message: 'validations.logoUrl.empty' })
  logoUrl: string;
}

export class CreateTeamDtoValidator extends UpdateTeamDtoValidator {
  constructor() {
    super();
    this.country = undefined;
  }

  @IsNotEmpty({ message: 'validations.common.isNotEmpty' })
  @IsNotEmptyObject(
    { nullable: true },
    { message: 'validations.common.isNotEmpty' },
  )
  country?: Country;
}

export default function TeamForm({
  team,
  isLoading = false,
}: {
  team?: Team;
  isLoading?: boolean;
}) {
  const [createTeam, createResult] = useTeamsControllerCreateMutation();
  const [updateTeam, updateResult] = useTeamsControllerUpdateMutation();
  const isCreating = useMemo(() => isNil(team), [team]);
  const { t } = useTranslation();
  const { appRoute } = useCompanyRoutes();
  const navigate = useNavigate();
  const handleSubmit = async (values: UpdateTeamDto | CreateTeamDto) => {
    const action = isCreating
      ? createTeam(values as CreateTeamDto)
      : updateTeam({
          id: team!.id.toString(),
          updateTeamDto: values,
        });
    await action.unwrap();
    navigate(appRoute(Routes.TEAMS));
  };
  const teamDto = {
    name: team?.name,
    logoUrl: team?.logoUrl,
    country: { id: team?.country?.id },
  };
  return (
    <If condition={isLoading}>
      <Then>
        <LinearProgress />
      </Then>
      <Else>
        <PageSection title="">
          <Formik
            initialValues={teamDto}
            onSubmit={handleSubmit}
            validate={createValidator(
              isCreating ? CreateTeamDtoValidator : UpdateTeamDtoValidator,
            )}
            validateOnChange={false}
            handleSubmit
          >
            {({
              handleSubmit,
              handleChange,
              values,
              errors,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <Stack spacing={2}>
                  <Stack>
                    <Input
                      value={values.name || ''}
                      name={'name'}
                      label={`${t('teamsDashboard.name')} *`}
                      onChange={handleChange}
                      error={t(errors.name as string) ?? undefined}
                    />
                  </Stack>
                  <When condition={isCreating}>
                    <Stack>
                      <InputLabel>
                        {`${t('entity.country._name')} *`}
                      </InputLabel>
                      <CountrySelect
                        onValueUpdated={(country) =>
                          setFieldValue('country', country)
                        }
                      />
                      <FormHelperText error>
                        {t(errors.country as string)}
                      </FormHelperText>
                    </Stack>
                  </When>
                  <Stack>
                    <ImageUploadField
                      label={`${t('teamsDashboard.logoUrl')} *`}
                      error={t(errors.logoUrl as string) ?? undefined}
                      value={values.logoUrl || ''}
                      fieldName={'logoUrl'}
                      setFieldValue={setFieldValue}
                    />
                  </Stack>

                  <FormHelperText error>
                    {updateResult.error && t('teamsDashboard.error')}
                  </FormHelperText>

                  <LoadingButton
                    loading={updateResult.isLoading || createResult.isLoading}
                    fullWidth
                    type="submit"
                    variant="contained"
                  >
                    {t('save')}
                  </LoadingButton>
                </Stack>
              </form>
            )}
          </Formik>
        </PageSection>
      </Else>
    </If>
  );
}
