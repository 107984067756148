import { Grid, Box, LinearProgress } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useCompaniesControllerFindMeQuery } from '../../app/services/futbolProdeApi';
import LoginForm from './LoginForm';
import { useTranslation } from 'react-i18next';

export default function Login() {
  const { t } = useTranslation();
  const { data: company } = useCompaniesControllerFindMeQuery();
  if (!company) return <LinearProgress />;
  return (
    <Grid
      container
      justifyContent={{ lg: 'end', xs: 'center' }}
      alignItems="center"
    >
      <Grid item xs={12} sm={12} md={5} lg={3}>
        <Box mb={12} display={{ xs: 'block', md: 'none' }}></Box>
        <LoginForm />
      </Grid>
      <Grid
        item
        height="100vh"
        ml={6}
        md={6}
        sx={{
          display: { xs: 'none', sm: 'block' },
          backgroundImage: `url("${
            company?.loginImageUrl ||
            'https://statics.viralizalo.com/virs/2018/01/VIR_417098_31095_puedes_reconocer_estos_estadios_de_futbol_del_mundo.jpg?cb=2957035'
          }")`,
          backgroundSize: 'cover',
          position: 'relative',
        }}
      >
        <Box sx={{ position: 'absolute', top: '35%', left: '5%' }}>
          <Box>
            <img
              src={company?.logoUrl}
              alt="company-logo"
              style={{ maxHeight: 200, maxWidth: 250 }}
            />
          </Box>
          <Box>
            <Typography
              variant={'h4'}
              fontWeight={700}
              color="primary.contrastText"
            >
              {t('customization.loginWelcomeMessage', {
                ns: 'company',
                defaultValue: '',
              })}
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
