import { Search } from '@mui/icons-material';
import { InputAdornment, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { externalIdLabel } from './userHelpers';

export default function UserSearchInput({
  value,
  onChange,
}: {
  value: string;
  onChange: (newValue: string) => void;
}) {
  const { t } = useTranslation();
  return (
    <TextField
      value={value}
      onChange={(e) => onChange(e.target.value)}
      placeholder={
        t('invitationsPage.search', {
          externalIdLabel: externalIdLabel(t),
        }) as string
      }
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
      }}
    />
  );
}
