import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  Skeleton,
  Divider,
  FormControlLabel,
  Switch,
  Stack,
  InputLabel,
  SpeedDial,
  SpeedDialAction,
} from '@mui/material';
import { isNil, pick } from 'ramda';
import { useParams } from 'react-router-dom';
import { Routes, useCompanyRoutes } from '../../router';
import {
  Round,
  UpdateTournamentDto,
  useRoundsControllerFindMatchesQuery,
  useTournamentsControllerFindOneQuery,
  useTournamentsControllerUpdateMutation,
  useRankingsControllerForceCalculateDreamFinalTournamentRankingMutation,
} from '../../app/services/futbolProdeApi';
import Page from '../../common/Page';
import { useSkeletonReadyList } from '@futbolprode/ui-common';
import RoundSelect from '../rankings/RoundSelect';
import MatchDetails from './MatchDetails';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Else, If, Then, Unless } from 'react-if';
import { Input } from '../../common/form/Input';
import ImageUploadField from '../../common/ImageUploadField';
import { useTranslation } from 'react-i18next';
import { PageSection } from '../../common/PageSection';
import { formatISO, parseISO } from 'date-fns';
import TournamentFeatures from './TournamentFeatures';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import { DreamFinalIcon } from '../../common/hooks/useAppMenu';
import CloudOffIcon from '@mui/icons-material/CloudOff';

export default function TournamentDetails() {
  const { appRoute } = useCompanyRoutes();
  const { id } = useParams();
  const [round, setRound] = useState<Round | null>(null);

  const { data: tournament, isLoading: isTournamentLoading } =
    useTournamentsControllerFindOneQuery(id!);

  const [updateTournament] = useTournamentsControllerUpdateMutation();
  const [forceCalculateDreamFinal] =
    useRankingsControllerForceCalculateDreamFinalTournamentRankingMutation();

  const actions = [
    {
      icon: <DreamFinalIcon />,
      name: 'Forzar final soñada',
      onClick: () => {
        forceCalculateDreamFinal(tournament!.id);
      },
    },
    {
      icon: <CloudOffIcon />,
      name: 'Desvincular torneo',
      onClick: () => {
        updateTournament({
          id: tournament!.id.toString(),
          updateTournamentDto: { isActive: false, externalId: null },
        });
      },
    },
  ];

  const {
    data: matchesData,
    isUninitialized: areMatchesUninitialized,
    isLoading: areMatchesLoading,
  } = useRoundsControllerFindMatchesQuery(round?.id.toString() ?? skipToken, {
    pollingInterval: 60 * 1000,
  });

  const matches = useSkeletonReadyList(matchesData);
  const [isEditing, setIsEditing] = useState(false);
  const [tournamentEditDraft, setTournamentEditDraft] =
    useState<UpdateTournamentDto>({});

  useEffect(() => {
    if (isTournamentLoading) {
      return;
    }

    setTournamentEditDraft(
      pick(
        [
          'name',
          'logoUrl',
          'hasPenalties',
          'hasTwoWayMatches',
          'showNoMoreRoundsWarning',
          'isActive',
          'startDate',
          'endDate',
          'importNewMatchesWithPenalties',
        ],
        tournament!,
      ),
    );
  }, [isTournamentLoading, tournament]);

  useEffect(() => {
    if (!isNil(round) || isNil(tournament?.currentRound)) {
      return;
    }
    setRound(tournament!.currentRound);
  }, [round, tournament]);

  const handleSave = async () => {
    await updateTournament({
      id: tournament!.id.toString(),
      updateTournamentDto: tournamentEditDraft,
    }).unwrap();
    setIsEditing(false);
  };
  const { t } = useTranslation();

  const ViewSection = (
    <Stack spacing={2}>
      <TournamentFeatures tournament={tournament} />
      <RoundSelect
        rounds={tournament?.rounds ?? []}
        value={round}
        setValue={setRound}
        isLoading={isTournamentLoading}
        disableClearable
        onValueUpdated={(value) => setRound(value)}
      />
      <Unless condition={areMatchesUninitialized}>
        {matches.map((match, index) => (
          <Grid container>
            {areMatchesLoading ? (
              <Skeleton width="100%">
                <Typography variant="h3">.</Typography>
              </Skeleton>
            ) : (
              <>
                <MatchDetails match={match} key={index} />
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </>
            )}
          </Grid>
        ))}
      </Unless>
    </Stack>
  );

  const EditSection = (
    <Stack spacing={2}>
      <PageSection title="common.details">
        <Input
          label={`${t('common.name')} *`}
          name="name"
          value={tournamentEditDraft.name!}
          error={''}
          onChange={(e) =>
            setTournamentEditDraft({
              ...tournamentEditDraft,
              name: e.target.value,
            })
          }
        />
        <ImageUploadField
          label={`${t('common.logo')} *`}
          error={''}
          value={tournamentEditDraft.logoUrl!}
          fieldName={'logoUrl'}
          setFieldValue={(_field, value) =>
            setTournamentEditDraft({
              ...tournamentEditDraft,
              logoUrl: value,
            })
          }
        />
        <Stack spacing={1}>
          <InputLabel>{t('tournament.startDate')}</InputLabel>
          <DatePicker
            value={
              tournamentEditDraft.startDate &&
              parseISO(tournamentEditDraft.startDate)
            }
            onChange={(newValue) => {
              if (isNil(newValue)) {
                return;
              }

              setTournamentEditDraft({
                ...tournamentEditDraft,
                startDate: formatISO(newValue as Date, {
                  representation: 'date',
                }),
              });
            }}
          />
        </Stack>
        <Stack spacing={1}>
          <InputLabel>{t('tournament.endDate')}</InputLabel>
          <DatePicker
            value={
              tournamentEditDraft.endDate &&
              parseISO(tournamentEditDraft.endDate)
            }
            onChange={(newValue) => {
              if (isNil(newValue)) {
                return;
              }

              setTournamentEditDraft({
                ...tournamentEditDraft,
                endDate: formatISO(newValue as Date, {
                  representation: 'date',
                }),
              });
            }}
          />
        </Stack>
      </PageSection>
      <PageSection title="common.settings">
        <Stack direction="row" spacing={3}>
          <FormControlLabel
            control={
              <Switch
                id={'isActive'}
                checked={tournamentEditDraft.isActive}
                onChange={(e) =>
                  setTournamentEditDraft({
                    ...tournamentEditDraft,
                    isActive: e.target.checked,
                  })
                }
                name={'isActive'}
              />
            }
            label={t('tournament.isActive')}
          />
          <FormControlLabel
            control={
              <Switch
                id={'showNoMoreRoundsWarning'}
                checked={tournamentEditDraft.showNoMoreRoundsWarning}
                onChange={(e) =>
                  setTournamentEditDraft({
                    ...tournamentEditDraft,
                    showNoMoreRoundsWarning: e.target.checked,
                  })
                }
                name={'showNoMoreRoundsWarning'}
              />
            }
            label={t('tournament.showNoMoreRoundsWarning')}
          />
        </Stack>
      </PageSection>
      <PageSection title="sidebar.gameRules">
        <Stack direction="row" spacing={3}>
          <FormControlLabel
            control={
              <Switch
                id={'hasPenalties'}
                checked={tournamentEditDraft.hasPenalties}
                onChange={(e) =>
                  setTournamentEditDraft({
                    ...tournamentEditDraft,
                    hasPenalties: e.target.checked,
                  })
                }
                name={'hasPenalties'}
              />
            }
            label={t('tournament.hasPenaltiesFeature')}
          />
          <FormControlLabel
            control={
              <Switch
                id={'hasTwoWayMatches'}
                checked={tournamentEditDraft.hasTwoWayMatches}
                onChange={(e) =>
                  setTournamentEditDraft({
                    ...tournamentEditDraft,
                    hasTwoWayMatches: e.target.checked,
                  })
                }
                name={'hasTwoWayMatches'}
              />
            }
            label={t('tournament.hasTwoWayMatches')}
          />
        </Stack>
      </PageSection>
      <PageSection title="tournament.importSettings">
        <Stack>
          <FormControlLabel
            control={
              <Switch
                id={'importNewMatchesWithPenalties'}
                checked={tournamentEditDraft.importNewMatchesWithPenalties}
                onChange={(e) =>
                  setTournamentEditDraft({
                    ...tournamentEditDraft,
                    importNewMatchesWithPenalties: e.target.checked,
                  })
                }
                name={'importNewMatchesWithPenalties'}
              />
            }
            label={t('tournament.importNewMatchesWithPenalties')}
          />
        </Stack>
      </PageSection>
    </Stack>
  );

  return (
    <Page
      breadcrumbs={[
        { label: 'sidebar.game' },
        { label: 'sidebar.tournament', url: appRoute(Routes.TOURNAMENTS) },
      ]}
      title={tournament?.name}
      actions={
        isTournamentLoading ? (
          <></>
        ) : (
          <Stack alignItems="flex-end">
            <SpeedDial
              ariaLabel="Actions"
              icon={isEditing ? <SaveIcon /> : <EditIcon />}
              direction="down"
              onClick={() => {
                if (isEditing) {
                  handleSave();
                }
                setIsEditing(!isEditing);
              }}
              sx={{ position: 'absolute' }}
            >
              {(isEditing ? [] : actions).map((action) => (
                <SpeedDialAction
                  key={action.name}
                  icon={action.icon}
                  tooltipTitle={action.name}
                  onClick={(event) => {
                    event.stopPropagation();
                    action.onClick();
                  }}
                />
              ))}
            </SpeedDial>
          </Stack>
        )
      }
    >
      <If condition={isEditing && !!tournament}>
        <Then>{EditSection}</Then>
        <Else>{ViewSection}</Else>
      </If>
    </Page>
  );
}
