import {
  Fab,
  FabProps,
  useTheme,
  useMediaQuery,
  CircularProgress,
} from '@mui/material';
import { omit } from 'ramda';

type FloatingButtonProps = FabProps & { isLoading?: boolean };

export default function FloatingButton(props: FloatingButtonProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Fab
      {...omit(['isLoading'], props)}
      disabled={props.isLoading}
      sx={
        isMobile ? { position: 'fixed', bottom: 0, right: 0, mb: 5, mr: 3 } : {}
      }
    >
      {props.isLoading ? (
        <CircularProgress color="inherit" size={20} />
      ) : (
        props.children
      )}
    </Fab>
  );
}
