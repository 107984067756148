import { UseTranslationResponse } from 'react-i18next';
import { DefaultNamespace } from 'react-i18next/TransWithoutContext';

export const externalIdLabel = (
  t: UseTranslationResponse<DefaultNamespace>['t'],
) =>
  t('user.identifier', {
    ns: 'company',
    defaultValue: t('activeUsersPage.externalId'),
  }) as string;
