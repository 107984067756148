import {
  Role,
  selectImpersonatedCompany,
  useAuth,
} from '@futbolprode/ui-common';
import {
  Company,
  Tournament,
  User,
  useTournamentsControllerFindAllByCompanyQuery,
} from '../../app/services/futbolProdeApi';
import { useAppSelector } from '../../app/hooks';
import { parseISO } from 'date-fns';
import { isNil } from 'ramda';

function tournamentsNotStarted(tournaments?: Tournament[]) {
  return (
    !isNil(tournaments) &&
    tournaments.every(
      (t) => !isNil(t.startDate) && parseISO(t.startDate) > new Date(),
    )
  );
}

export function useUserPermissions() {
  const { user } = useAuth();
  const { data: tournaments } = useTournamentsControllerFindAllByCompanyQuery();
  const appUser = user as unknown as User;
  const impersonatedCompany = useAppSelector(
    selectImpersonatedCompany,
  ) as Company | null;
  const isAdmin = appUser.role === Role.ADMIN;
  const isReseller = appUser.role === Role.RESELLER;
  const isAdminOrReseller = isAdmin || isReseller;
  const hasManagerialRole = appUser.role !== Role.PLAYER;
  const isImpersonating = isAdminOrReseller && !!impersonatedCompany;
  const canDeleteUsers =
    isAdmin || (hasManagerialRole && tournamentsNotStarted(tournaments));

  return {
    isImpersonating,
    isAdminOrReseller,
    user,
    impersonatedCompany,
    isReseller,
    isAdmin,
    canDeleteUsers,
  };
}
