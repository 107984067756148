import { Link } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Page from '../../common/Page';
import FloatingButton from '../../common/FloatingButton';
import {
  useTriviasControllerGetCompanyTriviasQuery,
  useTriviasControllerGetTriviaTemplatesQuery,
} from '../../app/services/futbolProdeApi';
import { createPath, useCompanyRoutes } from '../../router';
import { TriviasSection } from './TriviasSection';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { When } from 'react-if';
import { TriviaKindProps } from '../../router';

export type TriviaKind = 'template' | 'company';

export interface TriviasPageProps {
  kind: TriviaKind;
}

export default function TriviasPage({ kind }: TriviasPageProps) {
  const { appRoute } = useCompanyRoutes();
  const { data: triviaTemplates, isLoading: templatesLoading } =
    useTriviasControllerGetTriviaTemplatesQuery();
  const { data: trivias, isLoading: triviasLoading } =
    useTriviasControllerGetCompanyTriviasQuery(
      // @ts-ignore Al aplanar automáticamente el tipo del parámetro, no contempla que puede ser undefined
      kind === 'template' ? skipToken : undefined,
    );

  const triviaKindProps = TriviaKindProps[kind];

  return (
    <Page
      breadcrumbs={[triviaKindProps.baseBreadcrumb]}
      title={'sidebar.trivias'}
      actions={
        <Link
          href={appRoute(createPath(triviaKindProps.route))}
          sx={{ textDecoration: 'none', color: 'inherit' }}
        >
          <FloatingButton color="primary">
            <AddIcon />
          </FloatingButton>
        </Link>
      }
    >
      <When condition={kind === 'company'}>
        <TriviasSection
          data={trivias}
          isLoading={triviasLoading}
          kind="company"
          showStatus
        />
      </When>
      <TriviasSection
        data={triviaTemplates}
        isLoading={templatesLoading}
        kind="template"
        showStatus={kind === 'template'}
      />
    </Page>
  );
}
