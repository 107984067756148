import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useCountriesControllerFindAllQuery } from '../../app/services/futbolProdeApi';
import { useMemo } from 'react';
import { compose, prop, reject, isNil, uniqBy } from 'ramda';
import { useTranslation } from 'react-i18next';
import { useI18nHelpers } from '@futbolprode/ui-common';
import { Country } from '../../app/services/futbolProdeApi';

type CountryFilter = (arg: Country[]) => Country[];
const omitCountriesWithoutCode: CountryFilter = reject(
  compose(isNil, prop('alpha2Code')),
);
const uniqByCode: CountryFilter = uniqBy(prop('alpha2Code'));
const keepValidCountries = compose(uniqByCode, omitCountriesWithoutCode);

interface CountrySelectProps {
  onValueUpdated: (arg: Country) => void;
}

export default function CountrySelect({ onValueUpdated }: CountrySelectProps) {
  const { data } = useCountriesControllerFindAllQuery();
  const countries = useMemo(() => keepValidCountries(data?.data ?? []), [data]);

  const { t } = useTranslation();
  const { formatCountryName } = useI18nHelpers();

  return (
    <Autocomplete
      onChange={(_event, newValue: Country | null) => {
        if (newValue) {
          onValueUpdated(newValue);
        }
      }}
      options={countries}
      autoHighlight
      getOptionLabel={(option) => formatCountryName(option)}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          <img
            loading="lazy"
            width="20"
            src={`https://flagcdn.com/w20/${option.alpha2Code?.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${option.alpha2Code?.toLowerCase()}.png 2x`}
            alt=""
          />
          {formatCountryName(option)}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={t('countries.chooseOne') as string}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'off',
          }}
        />
      )}
    />
  );
}
