import { Typography } from '@mui/material';
import { HistoryToggleOff } from '@mui/icons-material';
import { ImportUsersResultsRow } from './ImportUsersResultsRow';
import { Stack } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { ImportReport } from '../../app/services/futbolProdeApi';

export default function ImportResultDelayed(_props: { value: ImportReport }) {
  const { t } = useTranslation();
  return (
    <Stack spacing={1}>
      <Typography variant="h6">{t('importUsersDashboard.results')}</Typography>
      <ImportUsersResultsRow
        Icon={HistoryToggleOff}
        text={t('importUsersDashboard.importDelayed')}
      />
    </Stack>
  );
}
