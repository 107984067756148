import Page from '../../common/Page';
import {
  Company,
  useAreasControllerFindAllQuery,
  useCompaniesControllerFindMeQuery,
  useCompaniesControllerUpdateMutation,
} from '../../app/services/futbolProdeApi';
import AreaDetails from './AreaDetails';
import { useSkeletonReadyList } from '@futbolprode/ui-common';
import { LinearProgress, Skeleton, Stack } from '@mui/material';
import { PageSection } from '../../common/PageSection';
import { Input } from '../../common/form/Input';
import { LoadingButton } from '@mui/lab';
import { Formik } from 'formik';
import { t } from 'i18next';
import { If, Then, Else } from 'react-if';

export default function AreasPage() {
  const { data: company, isLoading: companyLoading } =
    useCompaniesControllerFindMeQuery();
  const { data, isLoading } = useAreasControllerFindAllQuery(1);
  const [updateCompany, updateResult] = useCompaniesControllerUpdateMutation();
  const areas = useSkeletonReadyList(data);

  const companyDto = company;

  const handleSubmit = async (formValues: Company) => {
    updateCompany({
      id: company!.id.toString(),
      updateCompanyDto: {
        minUsersForAreaRanking: formValues.minUsersForAreaRanking,
      },
    });
  };

  return (
    <Page breadcrumbs={[{ label: 'sidebar.users' }]} title={'sidebar.areas'}>
      <If condition={companyLoading}>
        <Then>
          <LinearProgress />
        </Then>
        <Else>
          <Stack spacing={2}>
            <PageSection title="sidebar.rankings">
              <Formik
                initialValues={companyDto!}
                onSubmit={handleSubmit}
                validateOnChange={false}
                handleSubmit
              >
                {({ handleSubmit, handleChange, values }) => (
                  <form onSubmit={handleSubmit}>
                    <Stack spacing={2}>
                      <Input
                        value={values.minUsersForAreaRanking}
                        name={`minUsersForAreaRanking`}
                        label={'entity.company.minUsersForAreaRanking'}
                        type="number"
                        helperText={
                          'entity.company.minUsersForAreaRankingExplain'
                        }
                        onChange={handleChange}
                        required
                      />
                      <LoadingButton
                        loading={updateResult.isLoading}
                        fullWidth
                        type="submit"
                        variant="contained"
                      >
                        {t('save')}
                      </LoadingButton>
                    </Stack>
                  </form>
                )}
              </Formik>
            </PageSection>
            <PageSection title="sidebar.areas">
              {areas.map((area, index) =>
                isLoading ? (
                  <Skeleton width="100%" height={100} key={index} />
                ) : (
                  <AreaDetails key={area.id} area={area} level={1} />
                ),
              )}
            </PageSection>
          </Stack>
        </Else>
      </If>
    </Page>
  );
}
