import { isRejectedWithValue, isFulfilled, Action } from '@reduxjs/toolkit';
import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit';
import { enqueueSnackbar } from 'notistack';
import { getI18n } from 'react-i18next';
import { pathEq } from 'ramda';
import { logout } from '@futbolprode/ui-common';

const isRtkQueryMutation = (action: Action) =>
  action.type.startsWith('api/executeMutation');

const isUploadFileMutation: (action: Action) => boolean = pathEq(
  ['meta', 'arg', 'endpointName'],
  'uploadFile',
);

const isLoginMutation: (action: Action) => boolean = pathEq(
  ['meta', 'arg', 'endpointName'],
  'authControllerLogin',
);

export const mutationResultLoggerMiddleware: Middleware =
  (_api: MiddlewareAPI) => (next) => (action: Action) => {
    if (
      !isRtkQueryMutation(action) ||
      isUploadFileMutation(action) ||
      isLoginMutation(action)
    ) {
      return next(action);
    }

    const { t } = getI18n();

    if (isRejectedWithValue(action)) {
      enqueueSnackbar(t('toast.genericError'), { variant: 'error' });
    }

    if (isFulfilled(action)) {
      enqueueSnackbar(t('toast.changesSaved'), { variant: 'success' });
    }

    return next(action);
  };

export const forceLogoutIfForbiddenMiddleware: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action) && action.payload?.status === 403) {
      api.dispatch(logout());
      return;
    }

    return next(action);
  };
