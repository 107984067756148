import { useNewsControllerFindOneQuery } from '../../app/services/futbolProdeApi';
import Page from '../../common/Page';
import { useParams } from 'react-router-dom';
import { NewsForm } from './NewsForm';
import { Routes, useCompanyRoutes } from '../../router';
import { Unless } from 'react-if';

export function CustomNewsEdit() {
  const { id } = useParams();
  const { data, isLoading } = useNewsControllerFindOneQuery(id!);
  const { appRoute } = useCompanyRoutes();

  return (
    <Page
      breadcrumbs={[
        { label: 'sidebar.news' },
        {
          label: 'sidebar.customNews',
          url: appRoute(Routes.CUSTOM_NEWS),
        },
      ]}
      title="newsDashboard.editNews"
    >
      <Unless condition={isLoading}>
        <NewsForm news={data} />
      </Unless>
    </Page>
  );
}
