import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useTranslation } from 'react-i18next';
import { useCallback, useState } from 'react';

interface FontSelectProps {
  onValueUpdated: (arg: string | null) => void;
  fonts: string[];
  fullWidth?: boolean;
  autoSelectSingleElement?: boolean;
  initialValue?: string;
}

export default function FontSelect({
  onValueUpdated,
  fonts,
  fullWidth = false,
  initialValue,
}: FontSelectProps) {
  const { t } = useTranslation();
  const [value, setValue] = useState<string | null>(initialValue ?? null);

  const onValueChanged = useCallback(
    (newValue: string | null) => {
      setValue(newValue);
      onValueUpdated(newValue);
    },
    [onValueUpdated],
  );

  return (
    <Autocomplete
      onChange={(_event, newValue) => onValueChanged(newValue)}
      value={value}
      fullWidth={fullWidth}
      noOptionsText={t('autocomplete.noOptions')}
      options={fonts}
      autoHighlight
      getOptionLabel={(option) => option}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          {option}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={t('autocomplete.chooseOne') as string}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'off',
          }}
        />
      )}
    />
  );
}
