import { FormHelperText, InputLabel, TextField } from '@mui/material';
import { Fragment } from 'react';
import { InputProps } from './InputProps';
import { useTranslation } from 'react-i18next';

export function Input({
  label,
  name,
  value,
  onChange,
  error,
  multiline,
  min,
  ...props
}: InputProps) {
  const { t } = useTranslation();
  return (
    <Fragment>
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <TextField
        id={name}
        fullWidth
        value={value}
        onChange={onChange}
        name={name}
        multiline={multiline}
        {...props}
        inputProps={{
          ...(props.type === 'number' ? { min } : {}),
        }}
      />
      <FormHelperText error>{t(error as string)}</FormHelperText>
    </Fragment>
  );
}
