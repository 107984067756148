import { CardContent, Stack, Skeleton, Link, Button } from '@mui/material';
import { useRankingsControllerGlobalRankingQuery } from '../../app/services/futbolProdeApi';
import RankingsTable from '../rankings/RankingsTable';
import { RoundedCard } from '../../common/MuiExtensions';
import { HomeTitles } from './HomePage';
import { Routes, useCompanyRoutes } from '../../router';

export function RankingCard() {
  const { data, isLoading } = useRankingsControllerGlobalRankingQuery({
    take: 5,
  });
  const { appRoute } = useCompanyRoutes();

  return (
    <RoundedCard>
      <CardContent>
        <Stack spacing={2}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <HomeTitles
              titleKey="entity.ranking.global"
              subtitleKey="homePage.rankingSubtitle"
            />
            <Link href={appRoute(Routes.RANKINGS)}>
              <Button variant="contained" color="primary">
                Ver todo el ranking
              </Button>
            </Link>
          </Stack>
          {isLoading ? (
            <Skeleton />
          ) : (
            <RankingsTable
              rankingType="general"
              data={data!.data!}
              isFetching={isLoading}
              grouping="user"
              hidePosition
              hideContainer
            />
          )}
        </Stack>
      </CardContent>
    </RoundedCard>
  );
}
