import { toSeconds } from 'duration-fns';

export const durationToSeverityColor = (value: Duration) => {
  const seconds = toSeconds(value);

  if (seconds <= 60) {
    return 'success.main';
  }

  if (seconds <= 60 * 5) {
    return 'warning.main';
  }

  return 'error.main';
};
