import { useState, useEffect, useMemo } from 'react';
import { useDebounce } from 'use-debounce';

const defaultPageSize = 100;

export function usePaginationSearchParams() {
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [search, setSearch] = useState('');
  const [debouncedSearchTerm] = useDebounce(search, 500);
  const [page, setPage] = useState(0);

  // Luego de que cambie el search term, hay que volver a la página cero
  // (que probablemente sea la única con resultados).
  useEffect(() => {
    setPage(0);
  }, [debouncedSearchTerm, setPage]);

  const params = useMemo(
    () => ({
      search: debouncedSearchTerm,
      take: pageSize,
      skip: page * pageSize,
    }),
    [debouncedSearchTerm, page, pageSize],
  );

  return {
    params,
    pageSize,
    page,
    search,
    setPage,
    setSearch,
    setPageSize,
  };
}
