import AddIcon from '@mui/icons-material/Add';
import Page from '../../common/Page';
import FloatingButton from '../../common/FloatingButton';
import { useNavigate } from 'react-router-dom';
import { Alert, AlertTitle, Grid, Link } from '@mui/material';
import {
  News,
  useNewsControllerFindAllQuery,
} from '../../app/services/futbolProdeApi';
import { useSkeletonReadyList } from '@futbolprode/ui-common';
import NewsCard from './NewsCard';
import { isEmpty } from 'ramda';
import { Else, If, Then } from 'react-if';
import { Trans, useTranslation } from 'react-i18next';

export function CustomNewsDashboard() {
  const navigate = useNavigate();
  const { data, isFetching } = useNewsControllerFindAllQuery();
  const newsList = useSkeletonReadyList(data, isFetching, 5);
  const { t } = useTranslation();

  return (
    <Page
      breadcrumbs={[{ label: 'sidebar.news' }]}
      title="sidebar.customNews"
      actions={
        <FloatingButton color="primary" onClick={() => navigate('new')}>
          <AddIcon />
        </FloatingButton>
      }
    >
      <If condition={isEmpty(newsList)}>
        <Then>
          <Alert severity="info" variant="outlined">
            <AlertTitle>{t('customNews.noCustomNews')}</AlertTitle>
            <Trans i18nKey="customNews.createFirstOne">
              Creá la primera haciendo <Link href="new">clic aquí</Link>.
            </Trans>
          </Alert>
        </Then>
        <Else>
          <Grid
            container
            columnSpacing={{ xs: 0, md: 2 }}
            rowSpacing={{ xs: 2, md: 0 }}
          >
            {newsList.map((news: News) => (
              <Grid item xs={12} md={4}>
                <NewsCard isLoading={isFetching} news={news} />
              </Grid>
            ))}
          </Grid>
        </Else>
      </If>
    </Page>
  );
}
