import { LoadingButton } from '@mui/lab';
import { LinearProgress, FormHelperText, Stack } from '@mui/material';
import createValidator from 'class-validator-formik';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Else, If, Then } from 'react-if';
import {
  CreateTermsAndConditionsDto,
  useGameRulesControllerTermsAndConditionsQuery,
  useGameRulesControllerUpsertTermsAndConditionsMutation,
} from '../../app/services/futbolProdeApi';
import { PageSection } from '../../common/PageSection';
import Page from '../../common/Page';
import { HtmlEditorInput } from '../../common/HtmlEditorInput';

export class TermsAndConditionsDtoValidator {
  constructor() {
    this.termsAndConditions = '';
  }

  termsAndConditions: string;
}

export function TermsAndConditionsDashboard() {
  const { data: termsAndConditions, isLoading } =
    useGameRulesControllerTermsAndConditionsQuery();
  const [upsertTermsAndConditions, updateResult] =
    useGameRulesControllerUpsertTermsAndConditionsMutation();
  const { t } = useTranslation();
  const handleSubmit = (values: CreateTermsAndConditionsDto) => {
    upsertTermsAndConditions(values);
  };
  const termsAndConditionsDto = {
    termsAndConditions: termsAndConditions?.termsAndConditions,
  } as CreateTermsAndConditionsDto;

  return (
    <Page
      breadcrumbs={[{ label: 'sidebar.termsAndConditions' }]}
      title="sidebar.termsAndConditions"
    >
      <If condition={isLoading}>
        <Then>
          <LinearProgress />
        </Then>
        <Else>
          <PageSection title="">
            <Formik
              initialValues={termsAndConditionsDto}
              onSubmit={handleSubmit}
              validate={createValidator(TermsAndConditionsDtoValidator)}
              validateOnChange={false}
              handleSubmit
            >
              {({ handleSubmit, values, errors, setFieldValue }) => (
                <form onSubmit={handleSubmit}>
                  <Stack spacing={2}>
                    <HtmlEditorInput
                      isRequired={true}
                      value={values.termsAndConditions}
                      label="sidebar.termsAndConditions"
                      errorLabel={errors.termsAndConditions}
                      setValue={(value) => {
                        setFieldValue('termsAndConditions', value);
                      }}
                    />
                    <FormHelperText error>
                      {updateResult.error && t('error')}
                    </FormHelperText>
                    <LoadingButton
                      loading={updateResult.isLoading}
                      fullWidth
                      type="submit"
                      variant="contained"
                    >
                      {t('save')}
                    </LoadingButton>
                  </Stack>
                </form>
              )}
            </Formik>
          </PageSection>
        </Else>
      </If>
    </Page>
  );
}
