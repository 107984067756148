import { IconButton, Stack, Typography, TypographyProps } from '@mui/material';
import { Area } from '../../app/services/futbolProdeApi';
import { Edit } from '@mui/icons-material';
import AreaFormDialog from './AreaFormDialog';
import { useState } from 'react';

export function AreaItem({
  area,
  typography,
}: {
  area: Area;
  typography: TypographyProps['variant'];
}) {
  const [formOpen, setFormOpen] = useState(false);
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <AreaFormDialog area={area} open={formOpen} setOpen={setFormOpen} />
      <Typography variant={typography}>{area.name}</Typography>
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          setFormOpen(true);
        }}
      >
        <Edit />
      </IconButton>
    </Stack>
  );
}
