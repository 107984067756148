import { ImportableTournament } from '../../app/services/futbolProdeApi';

export function hasPoorCoverage(tournament: ImportableTournament) {
  return !tournament.coverage.standings || !tournament.coverage.fixtures.events;
}

export function poorCoverageDetails(tournament: ImportableTournament) {
  const {
    standings,
    fixtures: { events },
  } = tournament.coverage;
  console.log(tournament.name, { standings, events });
  if (!standings && !events) {
    return 'both';
  }
  if (!standings) {
    return 'standings';
  }
  if (!events) {
    return 'events';
  }
  return 'none';
}
