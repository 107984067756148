import { IconButton, Typography } from '@mui/material';
import { selectImpersonatedCompany } from '@futbolprode/ui-common';
import { isNil } from 'ramda';
import { useState } from 'react';
import { useAppSelector } from '../../app/hooks';
import ImpersonatedCompanyModal from './ImpersonatedCompanyModal';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { Stack } from '@mui/system';
import { Company } from '../../app/services/futbolProdeApi';
import { useTranslation } from 'react-i18next';
import { If, Then, Else } from 'react-if';
import CompanyHeader from '../../common/CompanyHeader';

export default function ImpersonationAlert() {
  const impersonatedCompany = useAppSelector(
    selectImpersonatedCompany,
  ) as Company | null;
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <>
      <ImpersonatedCompanyModal open={isModalOpen} handleClose={closeModal} />
      <Stack direction="row" alignItems="center" spacing={1}>
        <If condition={isNil(impersonatedCompany)}>
          <Then>
            <Typography variant="button" textTransform="uppercase">
              {t('impersonation.chooseCompany')}
            </Typography>
          </Then>
          <Else>
            <CompanyHeader company={impersonatedCompany} />
          </Else>
        </If>
        <IconButton color="primary" onClick={openModal}>
          <ManageAccountsIcon />
        </IconButton>
      </Stack>
    </>
  );
}
