import { List, Divider, Alert, AlertTitle, Link } from '@mui/material';
import { PageSection } from '../../common/PageSection';
import {
  Trivia,
  useTriviasControllerRemoveMutation,
} from '../../app/services/futbolProdeApi';
import { Else, If, Then, Unless } from 'react-if';
import { isLastIndex } from '../../common/helpers/arrayHelpers';
import { TriviaListItem } from './TriviaListItem';
import { TriviaKind } from './TriviasPage';
import { TriviaKindProps } from '../../router';
import { useSkeletonReadyList } from '@futbolprode/ui-common';
import { isEmpty, isNil, sortBy } from 'ramda';
import { Trans, useTranslation } from 'react-i18next';
import { createPath, useCompanyRoutes } from '../../router';
import ConfirmationModal from '../../common/ConfirmationModal';
import { useState } from 'react';

const sortingOrder: Record<Trivia['status'], number> = {
  published: 0,
  paused: 1,
  draft: 2,
};

export function TriviasSection({
  kind,
  data,
  showStatus,
  isLoading,
}: {
  kind: TriviaKind;
  data: Trivia[] | undefined;
  showStatus: boolean;
  isLoading: boolean;
}) {
  const [showConfirmationModal, setShowDeleteConfirmation] = useState(false);
  const [triviaToDelete, setTriviaToDelete] = useState<Trivia | undefined>(
    undefined,
  );
  const { appRoute } = useCompanyRoutes();
  const { t } = useTranslation();
  const trivias = useSkeletonReadyList(data);
  const triviaKindProps = TriviaKindProps[kind];
  const [triggerDelete, deleteResult] = useTriviasControllerRemoveMutation();

  const handleTriviaDelete = (trivia: Trivia) => {
    setShowDeleteConfirmation(true);
    setTriviaToDelete(trivia);
  };

  const handleConfirmTriviaDelete = async () => {
    if (isNil(triviaToDelete)) {
      return;
    }

    await triggerDelete(triviaToDelete.id).unwrap();
    setTriviaToDelete(undefined);
    setShowDeleteConfirmation(false);
  };

  return (
    <PageSection
      title={`triviasPage.${kind}Kind`}
      subtitle={`triviasPage.${kind}KindSubtitle`}
    >
      <If condition={isEmpty(trivias)}>
        <Then>
          <Alert severity="info" variant="outlined">
            <AlertTitle>{t('triviasPage.noTrivias')}</AlertTitle>
            <Trans i18nKey="triviasPage.createFirstOne">
              Creá la primera haciendo{' '}
              <Link href={appRoute(createPath(triviaKindProps.route))}>
                clic aquí
              </Link>
              .
            </Trans>
          </Alert>
        </Then>
        <Else>
          <ConfirmationModal
            titleKey="triviasPage.confirmTriviaDeleteTitle"
            descriptionKey={'triviasPage.confirmTriviaDeleteDescription'}
            descriptionValues={{
              trivia: triviaToDelete?.title,
            }}
            open={showConfirmationModal}
            onAccept={handleConfirmTriviaDelete}
            onCancel={() => setShowDeleteConfirmation(false)}
            isSubmitting={deleteResult.isLoading}
            isDelete
          />
          <List sx={{ width: '100%' }}>
            {sortBy((trivia) => sortingOrder[trivia.status], trivias).map(
              (trivia, index) => (
                <>
                  <TriviaListItem
                    kind={kind}
                    value={trivia}
                    isLoading={isLoading}
                    showStatus={showStatus}
                    onDelete={handleTriviaDelete}
                  />
                  <Unless condition={isLastIndex(trivias, index)}>
                    <Divider variant="inset" component="li" />
                  </Unless>
                </>
              ),
            )}
          </List>
        </Else>
      </If>
    </PageSection>
  );
}
