import {
  Button,
  Fab,
  LinearProgress,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import Page from '../../common/Page';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  useCompaniesControllerFindAvailableFontsQuery,
  useCompaniesControllerFindMeQuery,
  useCompaniesControllerUpdateMeMutation,
} from '../../app/services/futbolProdeApi';
import { PageSection, PageSectionSubtitle } from '../../common/PageSection';
import ImageUploadField from '../../common/ImageUploadField';
import { useMakeAppTheme } from '@futbolprode/ui-common';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { LoadingButton } from '@mui/lab';
import { Else, If, Then } from 'react-if';
import FontSelect from './FontSelect';
import { appThemeOptions } from '../../common/configuration/appThemeOptions';
import { deleteEmptyKeys } from '../../common/helpers/objectHelpers';
import { HomePreviewSvg } from './HomePreviewSvg';
import { LoginPreviewSvg } from './LoginPreviewSvg';
import ColorInput from '../../common/form/ColorInput';
import { useCallback } from 'react';
import { mergeDeepRight } from 'ramda';
import { Input } from '../../common/form/Input';
import createValidator from 'class-validator-formik';
import {
  LocalizedIsNotEmpty,
  LocalizedIsUrl,
} from '../../common/helpers/validatorHelpers';

export class AppearanceDto {
  @LocalizedIsNotEmpty()
  name!: string;

  @LocalizedIsNotEmpty()
  primaryColor!: string;

  @LocalizedIsNotEmpty()
  secondaryColor!: string;

  @LocalizedIsNotEmpty()
  tertiaryColor!: string;

  @LocalizedIsUrl()
  loginImageUrl!: string;

  @LocalizedIsUrl()
  logoUrl!: string;

  @LocalizedIsUrl()
  isologoUrl!: string;

  customColors?: {
    navigationBarBackground?: string;
    loginLabelsColor?: string;
    loginWelcomeMessageColor?: string;
  };
  customFontFamily?: string;
}

const saveTheme = async (updateCompany: Function, updateCompanyDto: any) => {
  deleteEmptyKeys(updateCompanyDto);
  const { theme, ...rest } = updateCompanyDto;

  await updateCompany({
    ...rest,
    customTheme: JSON.stringify(theme || {}),
  }).unwrap();
};

export function AppearanceDashboard() {
  const { t } = useTranslation();
  const { theme } = useMakeAppTheme({ appThemeOptions });
  const { theme: defaultTheme } = useMakeAppTheme({
    appThemeOptions,
    ignoreCompanyTheme: true,
  });
  const { data: company } = useCompaniesControllerFindMeQuery();
  const { data: fonts } = useCompaniesControllerFindAvailableFontsQuery();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [updateCompany, result] = useCompaniesControllerUpdateMeMutation();
  const companyTheme = theme.palette;
  const appearanceDto: AppearanceDto = {
    primaryColor: companyTheme.primary.main,
    secondaryColor: companyTheme.secondary.main,
    tertiaryColor: companyTheme.tertiary.main,
    ...mergeDeepRight(
      {
        customFontFamily: '',
        loginImageUrl: '',
        logoUrl: '',
        isologoUrl: '',
        name: '',
      },
      company ?? {},
    ),
  };

  const handleReset = useCallback(
    async (setFieldValue: FormikHelpers<AppearanceDto>['setFieldValue']) => {
      setFieldValue('primaryColor', defaultTheme.palette.primary.main);
      setFieldValue('secondaryColor', defaultTheme.palette.secondary.main);
      setFieldValue('tertiaryColor', defaultTheme.palette.tertiary.main);
      setFieldValue('customColors', undefined);
    },
    [defaultTheme],
  );

  const BasicInfoSection = useCallback(
    ({ values, errors, handleChange }: FormikProps<AppearanceDto>) => (
      <PageSection title={t('companyForm.basicData')}>
        <Input
          value={values.name}
          name={'name'}
          label={'companiesDashboard.name'}
          onChange={handleChange}
          error={errors.name}
          required
        />
      </PageSection>
    ),
    [t],
  );

  const ColorsSection = useCallback(
    ({ values, errors, setFieldValue }: FormikProps<AppearanceDto>) => (
      <PageSection
        title={t('appearanceDashboard.colors')}
        actions={
          isMobile ? (
            <Fab
              onClick={() => handleReset(setFieldValue)}
              color="secondary"
              size="small"
            >
              <RefreshIcon />
            </Fab>
          ) : (
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => handleReset(setFieldValue)}
              startIcon={<RefreshIcon />}
            >
              {t('appearanceDashboard.restoreDefaults')}
            </Button>
          )
        }
      >
        <PageSectionSubtitle>
          {t('appearanceDashboard.mainColors')}
        </PageSectionSubtitle>
        <ColorInput<AppearanceDto>
          name="primaryColor"
          label="appearanceDashboard.primaryColor"
          values={values}
          errors={errors}
          setFieldValue={setFieldValue}
        />
        <ColorInput<AppearanceDto>
          name="secondaryColor"
          label="appearanceDashboard.secondaryColor"
          values={values}
          errors={errors}
          setFieldValue={setFieldValue}
        />
        <ColorInput<AppearanceDto>
          name="tertiaryColor"
          label="appearanceDashboard.tertiaryColor"
          values={values}
          errors={errors}
          setFieldValue={setFieldValue}
        />
        <PageSectionSubtitle>
          {t('appearanceDashboard.customColors')}
        </PageSectionSubtitle>
        <ColorInput<AppearanceDto>
          name="customColors.navigationBarBackground"
          label="appearanceDashboard.navigationBarBackground"
          helperText="appearanceDashboard.primaryColorByDefault"
          values={values}
          errors={errors}
          setFieldValue={setFieldValue}
        />
        <ColorInput<AppearanceDto>
          name="customColors.loginLabelsColor"
          label="appearanceDashboard.loginLabelsColor"
          helperText="appearanceDashboard.primaryColorByDefault"
          values={values}
          errors={errors}
          setFieldValue={setFieldValue}
        />
        <ColorInput<AppearanceDto>
          name="customColors.loginWelcomeMessageColor"
          label="appearanceDashboard.loginWelcomeMessageColor"
          helperText="appearanceDashboard.whiteByDefault"
          values={values}
          errors={errors}
          setFieldValue={setFieldValue}
        />
        <PageSectionSubtitle>
          {t('appearanceDashboard.preview')}
        </PageSectionSubtitle>
        <Stack direction="row" spacing={2}>
          <LoginPreviewSvg appearance={values} />
          <HomePreviewSvg appearance={values} />
        </Stack>
      </PageSection>
    ),
    [t, handleReset, isMobile],
  );

  const ImagesSection = useCallback(
    ({ values, errors, setFieldValue }: FormikProps<AppearanceDto>) => (
      <PageSection title={t('appearanceDashboard.images')}>
        <ImageUploadField
          label={t('companyForm.logo')}
          error={errors.logoUrl}
          value={values.logoUrl}
          fieldName={'logoUrl'}
          dimensions="700px * 200px / 90px * 90px"
          setFieldValue={setFieldValue}
        />
        <ImageUploadField
          label={t('companyForm.isologo')}
          error={errors.isologoUrl}
          value={values.isologoUrl}
          fieldName={'isologoUrl'}
          dimensions="300px * 300px"
          setFieldValue={setFieldValue}
        />
        <ImageUploadField
          label={`${t('appearanceDashboard.loginImageUrl')}`}
          dimensions="1800px * 1800px"
          error={errors.loginImageUrl}
          value={values.loginImageUrl}
          fieldName={'loginImageUrl'}
          setFieldValue={setFieldValue}
        />
      </PageSection>
    ),
    [t],
  );

  const FontsSection = useCallback(
    ({ values, setFieldValue }: FormikProps<AppearanceDto>) => (
      <PageSection title="appearanceDashboard.typography">
        <Typography color="text.secondary">
          <Trans i18nKey="appearanceDashboard.typographyHint">
            Te recomendamos elegir la fuente en{' '}
            <a href="https://fonts.google.com" target="_blank" rel="noreferrer">
              Google Fonts
            </a>{' '}
            y luego buscarla aquí por su nombre.
          </Trans>
        </Typography>
        <FontSelect
          fonts={fonts ?? []}
          initialValue={values.customFontFamily}
          onValueUpdated={(value) => {
            setFieldValue('customFontFamily', value);
          }}
        />
      </PageSection>
    ),
    [fonts],
  );

  const handleSubmit = ({
    primaryColor,
    secondaryColor,
    tertiaryColor,
    ...rest
  }: any) => {
    saveTheme(updateCompany, {
      ...rest,
      theme: {
        palette: {
          primary: { main: primaryColor },
          secondary: { main: secondaryColor },
          tertiary: { main: tertiaryColor },
        },
      },
    });
  };
  return (
    <Page
      breadcrumbs={[{ label: 'sidebar.customization' }]}
      title="sidebar.appearance"
    >
      <If condition={!company || !theme}>
        <Then>
          <LinearProgress />
        </Then>
        <Else>
          <Formik
            initialValues={appearanceDto}
            onSubmit={handleSubmit}
            validate={createValidator(AppearanceDto)}
            validateOnChange={false}
            handleSubmit
          >
            {(formikProps) => {
              return (
                <form onSubmit={formikProps.handleSubmit}>
                  <Stack spacing={2}>
                    <BasicInfoSection {...formikProps} />
                    <ColorsSection {...formikProps} />
                    <ImagesSection {...formikProps} />
                    <FontsSection {...formikProps} />
                  </Stack>
                  <Stack spacing={2}>
                    <LoadingButton
                      loading={result.isLoading}
                      fullWidth
                      type="submit"
                      variant="contained"
                      sx={{ mt: 2 }}
                    >
                      {t('save')}
                    </LoadingButton>
                  </Stack>
                </form>
              );
            }}
          </Formik>
        </Else>
      </If>
    </Page>
  );
}
