import { Avatar, Link, Skeleton, Stack, Typography } from '@mui/material';
import {
  JobLog,
  Match,
  Team,
  useMatchesControllerFindOneQuery,
  useRoundsControllerFindOneQuery,
  useTournamentsControllerFindOneQuery,
} from '../../app/services/futbolProdeApi';
import { Routes, useCompanyRoutes } from '../../router';
import { useTranslation } from 'react-i18next';
import { When } from 'react-if';
import { useCompanyTranslations } from '@futbolprode/ui-common';
import JSONPretty from 'react-json-pretty';

function MatchData({ value }: { value: Match }) {
  const TeamLogo = (team: Team) => (
    <Avatar
      src={team.logoUrl}
      alt={team.name}
      sx={{ width: 25, height: 25 }}
    ></Avatar>
  );

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      {TeamLogo(value.homeTeam)}
      <Typography variant="body2">
        {value.homeTeam.name} - {value.awayTeam.name}
      </Typography>
      {TeamLogo(value.awayTeam)}
    </Stack>
  );
}

export function MatchImportResultMetadata({ metadata }: { metadata: any }) {
  const { data, isLoading } = useMatchesControllerFindOneQuery(metadata.match);

  return isLoading ? <Skeleton /> : <MatchData value={data!} />;
}

export function RankingGenerateMetadata({ metadata }: { metadata: any }) {
  const { data: round, isLoading: isRoundLoading } =
    useRoundsControllerFindOneQuery(metadata.round);
  const { data: tournament, isLoading: isTournamentLoading } =
    useTournamentsControllerFindOneQuery(metadata.tournament);
  const { data: match, isLoading: isMatchLoading } =
    useMatchesControllerFindOneQuery(metadata.match);

  const { t } = useTranslation();
  const { roundName, tournamentName } = useCompanyTranslations();

  return isRoundLoading || isTournamentLoading || isMatchLoading ? (
    <Skeleton />
  ) : (
    <Stack>
      <MatchData value={match!} />
      <Typography variant="body2" color="text.secondary">
        {roundName(round!)} ({tournamentName(tournament!)})
      </Typography>
      <When condition={metadata.calculateDreamFinalPoints}>
        <Typography variant="body2" color="info.main">
          {t('jobLogMetadata.includesDreamFinalPoints')}
        </Typography>
      </When>
    </Stack>
  );
}

export function TournamentImportMetadata({ metadata }: { metadata: any }) {
  const { data, isLoading } = useTournamentsControllerFindOneQuery(
    metadata.tournament,
  );
  const { appRoute } = useCompanyRoutes();
  const { tournamentName } = useCompanyTranslations();

  return isLoading ? (
    <Skeleton />
  ) : (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Avatar
        src={data!.logoUrl}
        alt={data!.name}
        sx={{ width: 25, height: 25 }}
      ></Avatar>
      <Link href={appRoute(`${Routes.TOURNAMENTS}/${data!.id}`)}>
        {tournamentName(data!)}
      </Link>
    </Stack>
  );
}

const jobTypesToElement: Record<string, (metadata: any) => JSX.Element> = {
  'match:import-result': (metadata) => (
    <MatchImportResultMetadata metadata={metadata} />
  ),
  'ranking:generate': (metadata) => (
    <RankingGenerateMetadata metadata={metadata} />
  ),
  'tournament:import': (metadata) => (
    <TournamentImportMetadata metadata={metadata} />
  ),
};

export function JobLogMetadata({ value }: { value: JobLog }) {
  const metadataComponent =
    jobTypesToElement[value.type] ||
    ((metadata) => <JSONPretty data={metadata} />);
  return metadataComponent(JSON.parse(value.metadata!));
}
