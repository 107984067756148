import {
  IsEmail,
  isEmpty,
  IsFQDN,
  IsNotEmpty,
  ValidateIf,
} from 'class-validator';
import { isNilOrEmpty } from '../../common/helpers/ramdaExtension';

export class UpdateCompanyDtoValidator {
  constructor() {
    this.name = '';
    this.logoUrl = '';
    this.appPath = '';
    this.appCustomDomain = '';
  }

  @IsNotEmpty({ message: 'validations.name.empty' })
  name: string;

  @IsNotEmpty({ message: 'validations.logoUrl.empty' })
  logoUrl: string;

  @ValidateIf((o: UpdateCompanyDtoValidator) => isEmpty(o.appCustomDomain))
  @IsNotEmpty({ message: 'validations.appPath.empty' })
  appPath: string;

  @ValidateIf((o: UpdateCompanyDtoValidator) => isEmpty(o.appPath))
  @IsNotEmpty({ message: 'validations.appCustomDomain.empty' })
  @IsFQDN(undefined, { message: 'validations.appCustomDomain.notFqdn' })
  appCustomDomain: string;
}
export class CreateCompanyDtoValidator extends UpdateCompanyDtoValidator {
  constructor() {
    super();
    this.managerUserName = '';
    this.managerUserEmail = '';
  }

  managerUserName: string;

  @ValidateIf((o) => !isNilOrEmpty(o.managerUserEmail))
  @IsEmail(undefined, { message: 'validations.email.notEmail' })
  managerUserEmail: string;
}

export class InviteManagerDtoValidator {
  constructor() {
    this.managerUserName = '';
    this.managerUserEmail = '';
  }

  @IsNotEmpty({ message: 'validations.name.empty' })
  managerUserName: string;

  @IsEmail(undefined, { message: 'validations.email.notEmail' })
  @IsNotEmpty({ message: 'validations.email.empty' })
  managerUserEmail: string;
}
