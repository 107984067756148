import { Box, Breadcrumbs, Link, Stack, Typography } from '@mui/material';
import { isNil } from 'ramda';
import { useTranslation } from 'react-i18next';
import { Unless } from 'react-if';

export interface Breadcrumb {
  label: string;
  url?: string;
}

export interface PageProps {
  children: JSX.Element | JSX.Element[];
  breadcrumbs?: Breadcrumb[];
  title?: string;
  actions?: JSX.Element;
}

export default function Page({
  children,
  title,
  breadcrumbs = [],
  actions,
}: PageProps) {
  const { t } = useTranslation();

  return (
    <Stack spacing={2}>
      <Breadcrumbs>
        {breadcrumbs.map((it, i) =>
          it.url ? (
            <Link key={i} underline="hover" color="inherit" href={it.url}>
              {t(it.label)}
            </Link>
          ) : (
            <Typography key={i}>{t(it.label)}</Typography>
          ),
        )}
        <Typography color="text.primary">{t(title ?? '')}</Typography>
      </Breadcrumbs>
      <Unless condition={isNil(title)}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h3" display={{ xs: 'none', sm: 'block' }}>
            {t(title!)}
          </Typography>
          {actions}
        </Box>
      </Unless>
      {children}
    </Stack>
  );
}
