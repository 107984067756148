import { Switch, SwitchProps } from '@mui/material';
import InputWrapper, { InputWrapperProps } from './InputWrapper';

export default function SwitchInput({
  value,
  name,
  label,
  helperText,
  required,
  ...props
}: SwitchProps & Omit<InputWrapperProps, 'children'> & { value: boolean }) {
  return (
    <InputWrapper
      name={name}
      label={label}
      helperText={helperText}
      required={required}
    >
      <Switch id={name} checked={value} name={name} {...props} />
    </InputWrapper>
  );
}
