import { Stack, Typography } from '@mui/material';
import { User } from '../../app/services/futbolProdeApi';
import { useTranslation } from 'react-i18next';
import { useAuth } from '@futbolprode/ui-common';
import UserAvatar from '../../common/UserAvatar';
import { useMemo } from 'react';

export function WelcomeMessage() {
  const { user } = useAuth();
  const { t } = useTranslation();

  // TODO: guardar nombre y apellido por separado
  const firstName = useMemo(() => (user?.name ?? '').split(' ').at(0), [user]);

  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <UserAvatar user={user! as User} size={50} />
      <Typography variant="h4">
        {t('homePage.niceToSeeYouAgain', { user: firstName })}
      </Typography>
    </Stack>
  );
}
