import { impersonateCompany, Role, useAuth } from '@futbolprode/ui-common';
import {
  Button,
  Modal,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../app/hooks';
import { Company } from '../../app/services/futbolProdeApi';
import ImpersonatedCompanySelector from './ImpersonatedCompanySelector';
import { useTranslation } from 'react-i18next';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

interface ImpersonatedCompanyModalProps {
  handleClose?: () => void;
  open: boolean;
}

export default function ImpersonatedCompanyModal({
  handleClose = () => {},
  open,
}: ImpersonatedCompanyModalProps) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [company, setCompany] = useState<Company | null>(null);

  const onModalClose = useCallback(() => {
    handleClose();
  }, [handleClose]);

  const setImpersonatedCompany = useCallback(
    (aCompany: Company | null) => {
      dispatch(impersonateCompany(aCompany! as any));
      navigate(0); // Refresh the page to reload data properly
    },
    [dispatch, navigate],
  );
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { user } = useAuth();
  const { t } = useTranslation();
  return (
    <Modal
      open={open}
      onClose={onModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack spacing={2} sx={{ ...style, width: isMobile ? 400 : 500 }}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {t('impersonation.selectCompany')}
        </Typography>
        <ImpersonatedCompanySelector handleChange={setCompany} />
        <Button
          variant="contained"
          onClick={() => setImpersonatedCompany(company)}
        >
          {t('impersonation.manage')}
        </Button>

        <Button variant="outlined" onClick={() => setImpersonatedCompany(null)}>
          {t(
            user?.role === Role.ADMIN
              ? 'impersonation.backToGeneralMode'
              : 'impersonation.backToResellerMode',
          )}
        </Button>
      </Stack>
    </Modal>
  );
}
