import {
  Avatar,
  Button,
  ButtonProps,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Skeleton,
  Stack,
  Switch,
} from '@mui/material';
import {
  NewsSource,
  Country,
  useCompaniesControllerFindMeQuery,
  useCompaniesControllerUpdateMutation,
  useNewsControllerAvailableRssFeedsQuery,
} from '../../app/services/futbolProdeApi';
import SaveIcon from '@mui/icons-material/Save';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useI18nHelpers, useSkeletonReadyList } from '@futbolprode/ui-common';
import Page from '../../common/Page';
import FloatingButton from '../../common/FloatingButton';
import { Unless } from 'react-if';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useTranslation } from 'react-i18next';
import { equals, isEmpty, isNil, join, reject } from 'ramda';

function ExternalLinkButton({ children, href }: ButtonProps) {
  return (
    <Button
      endIcon={<OpenInNewIcon />}
      href={href!}
      target="_blank"
      rel="noreferrer"
    >
      {children}
    </Button>
  );
}

function RssFeedItem({
  value,
  isLoading,
  isSelected,
  onChange,
}: {
  value: NewsSource;
  isLoading: boolean;
  isSelected: boolean;
  onChange: (feed: NewsSource) => void;
}) {
  const { t } = useTranslation();
  const { formatCountryName } = useI18nHelpers();

  const secondaryText = useMemo(() => {
    const base = formatCountryName({ alpha2Code: value.country } as Country);
    const languages = isNil(value.languages)
      ? null
      : t('common.language') +
        ': ' +
        value.languages.map((it) => t(`common.languages.${it}`)).join(', ');
    const categories = isEmpty(value.excludeCategories)
      ? null
      : t('rssNews.excludeCategories', {
          categories: value.excludeCategories?.join(', '),
        });
    return join(' - ', reject(isNil, [base, languages, categories]));
  }, [value, t, formatCountryName]);

  return (
    <ListItem key={value.id}>
      <Grid container alignItems="center">
        <Grid item xs={12} md={10}>
          <Stack direction="row" alignItems="center">
            <ListItemAvatar>
              {isLoading ? (
                <Skeleton>
                  <Avatar />
                </Skeleton>
              ) : (
                <Avatar alt={value.name} src={value.image} />
              )}
            </ListItemAvatar>
            {isLoading ? (
              <Skeleton width="50%"></Skeleton>
            ) : (
              <ListItemText primary={value.name} secondary={secondaryText} />
            )}
          </Stack>
        </Grid>
        <Unless condition={isLoading}>
          <Grid container item xs={12} md={2} justifyContent="flex-end">
            <ExternalLinkButton href={value.link}>
              {t('newsDashboard.goToSite')}
            </ExternalLinkButton>
            <Switch
              edge="end"
              onChange={() => onChange(value)}
              checked={isSelected}
            />
          </Grid>
        </Unless>
      </Grid>
    </ListItem>
  );
}

export function RssNewsDashboard() {
  const { data: company, isLoading: companyIsLoading } =
    useCompaniesControllerFindMeQuery();
  const { data, isLoading: availabeFeedsAreLoading } =
    useNewsControllerAvailableRssFeedsQuery();
  const [updateCompany, { isLoading: isUpdating }] =
    useCompaniesControllerUpdateMutation();
  const availableFeeds = data || [];
  const [selectedFeeds, setSelectedFeeds] = useState([] as string[]);
  const isFeedSelected = useCallback(
    (feed: NewsSource) => selectedFeeds?.includes(feed.id),
    [selectedFeeds],
  );
  const toggleFeed = useCallback(
    (feed: NewsSource) => {
      const shouldRemove = isFeedSelected(feed);
      setSelectedFeeds(
        shouldRemove
          ? reject(equals(feed.id), selectedFeeds)
          : selectedFeeds.concat([feed.id]),
      );
    },
    [isFeedSelected, selectedFeeds],
  );

  useEffect(() => {
    if (company) {
      setSelectedFeeds(company!.rssFeeds || []);
    }
  }, [company]);

  const handleSave = useCallback(
    () =>
      updateCompany({
        id: company!.id.toString(),
        updateCompanyDto: { rssFeeds: selectedFeeds },
      }),
    [updateCompany, company, selectedFeeds],
  );

  const isLoading = useMemo(
    () => companyIsLoading || availabeFeedsAreLoading,
    [companyIsLoading, availabeFeedsAreLoading],
  );
  const feeds = useSkeletonReadyList(availableFeeds, isLoading, 5);

  return (
    <Page
      breadcrumbs={[{ label: 'sidebar.news' }]}
      title="sidebar.rssNews"
      actions={
        isLoading ? (
          <></>
        ) : (
          <FloatingButton
            color="primary"
            onClick={() => handleSave()}
            isLoading={isUpdating}
          >
            <SaveIcon />
          </FloatingButton>
        )
      }
    >
      <List>
        {feeds.map((availableFeed: NewsSource, index: number) => (
          <>
            <RssFeedItem
              value={availableFeed}
              isLoading={isLoading}
              isSelected={isFeedSelected(availableFeed)}
              onChange={() => toggleFeed(availableFeed)}
            />
            <Unless condition={index === feeds.length - 1}>
              <Divider />
            </Unless>
          </>
        ))}
      </List>
    </Page>
  );
}
