import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
  Area,
  useAreasControllerUpdateMutation,
} from '../../app/services/futbolProdeApi';
import { LoadingButton } from '@mui/lab';
import { Trans, useTranslation } from 'react-i18next';

export default function AreaFormDialog({
  area,
  open,
  setOpen,
}: {
  area: Area;
  open: boolean;
  setOpen: (open: boolean) => void;
}) {
  const { t } = useTranslation();
  const handleClose = () => {
    setOpen(false);
  };

  const [update, updateResult] = useAreasControllerUpdateMutation();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: 'form',
        // @ts-ignore
        onSubmit: async (event: React.FormEvent<HTMLFormElement>) => {
          event.preventDefault();
          const formData = new FormData(event.currentTarget);
          const formJson = Object.fromEntries((formData as any).entries());
          await update({
            id: area.id,
            updateAreaDto: { name: formJson.name as string },
          }).unwrap();
          handleClose();
        },
      }}
    >
      <DialogTitle>{t('areasPage.rename')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Trans
            i18nKey="areasPage.renameDescription"
            values={{ name: area.name }}
          >
            Ingresá el nuevo nombre para <b>{area.name}</b>.
          </Trans>
        </DialogContentText>
        <TextField
          autoFocus
          required
          margin="dense"
          id="name"
          name="name"
          fullWidth
          variant="standard"
          defaultValue={area.name}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t('common.cancel')}</Button>
        <LoadingButton
          loading={updateResult.isLoading}
          variant="contained"
          type="submit"
        >
          {t('common.save')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
