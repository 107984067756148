import Page from '../../common/Page';
import { Autocomplete, Pagination, TextField, Alert } from '@mui/material';
import { useState } from 'react';
import { useDebounce } from 'use-debounce';
import { useJobLogsControllerFindAllQuery } from '../../app/services/futbolProdeApi';
import JobLogsTable from './JobLogsTable';
import { useTranslation } from 'react-i18next';
import { JobType, JobTypes } from '../../common/helpers/jobLogHelpers';
import useTranslationHelpers from '../../common/hooks/useTranslationHelpers';
import { If, Then, Else } from 'react-if';

export default function JobLogsPage() {
  const [search, setSearch] = useState<JobType | null>();
  const [debouncedSearchTerm] = useDebounce(search, 500);

  const PAGE_SIZE = 50;
  const [page, setPage] = useState(0);

  const { t } = useTranslation();
  const { formatJobType } = useTranslationHelpers();

  const { data, isFetching } = useJobLogsControllerFindAllQuery({
    type: debouncedSearchTerm ?? undefined,
    take: PAGE_SIZE,
    skip: page * PAGE_SIZE,
  });

  return (
    <Page
      breadcrumbs={[{ label: 'sidebar.globalSettings' }]}
      title="sidebar.jobLogs"
    >
      <Autocomplete
        id="rounds-autocomplete"
        options={JobTypes}
        value={search}
        onChange={(_event, value) => {
          setSearch(value);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={t('jobLog.filterByType') as string}
          />
        )}
        getOptionLabel={(type) => formatJobType(type)}
      />
      <If condition={data?.pagination?.count === 0}>
        <Then>
          <Alert severity="warning">{t('common.noResults')}</Alert>
        </Then>
        <Else>
          <Pagination
            showLastButton
            variant="outlined"
            disabled={isFetching}
            count={Math.ceil((data?.pagination?.count ?? 0) / PAGE_SIZE)}
            key={page}
            page={page + 1}
            onChange={(_event: React.ChangeEvent<unknown>, value: number) =>
              setPage(value - 1)
            }
            sx={{
              alignSelf: 'flex-end',
              '& .MuiPaginationItem-page': {
                border: 'none',
                backgroundColor: 'transparent',
              },
            }}
          />
          <JobLogsTable isFetching={isFetching} logs={data?.data} />
        </Else>
      </If>
    </Page>
  );
}
