import {
  Alert,
  AlertTitle,
  Box,
  Grid,
  LinearProgress,
  Link,
  Typography,
} from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { useInvitationsControllerImportFromExcelMutation } from '../../app/services/futbolProdeApi';
import { Close } from '@mui/icons-material';
import { useUploadFileMutation } from '@futbolprode/ui-common';
import { Case, Else, If, Switch, Then, Unless } from 'react-if';
import { ImportUsersResultsTitle } from './ImportUsersResultsTitle';
import { ImportUsersResultsRow } from './ImportUsersResultsRow';
import UploadFileZone from '../../common/UploadFileZone';
import Page from '../../common/Page';
import { isNil } from 'ramda';
import { Stack } from '@mui/system';
import ImportResultSuccess from './ImportResultSuccess';
import ImportResultDelayed from './ImportResultDelayed';

export default function ImportUsersDashboard() {
  const { t } = useTranslation();
  const [uploadFile, uploadFileResult] = useUploadFileMutation();
  const [importExcel, importExcelResult] =
    useInvitationsControllerImportFromExcelMutation();

  const onDrop = async (files: File[]) => {
    const formData = new FormData();
    formData.append('file', files[0]);
    const { cdnUrl } = await uploadFile(formData).unwrap();
    importExcel({
      importFromExcelBody: { url: cdnUrl },
      dryRun: 'false',
    });
  };

  return (
    <Page
      breadcrumbs={[{ label: 'sidebar.users' }]}
      title="sidebar.importUsers"
    >
      <Box>
        <Trans i18nKey="importUsersDashboard.downloadSampleFile">
          Descargá el Excel de ejemplo desde{' '}
          <Link href="https://d1g4ax6chcx3tu.cloudfront.net/internal/Users.xlsx">
            este enlace
          </Link>{' '}
          y completalo con los usuarios de tu empresa, teniendo en cuenta lo
          siguiente:
        </Trans>
      </Box>

      <Alert severity="info">
        <AlertTitle>{t('importUsersDashboard.adviseTitle')}</AlertTitle>
        <ul>
          {t<string, string[]>('importUsersDashboard.advises', {
            returnObjects: true,
          }).map((it) => (
            <li>{it}</li>
          ))}
        </ul>
      </Alert>

      <Box>{t('importUsersDashboard.uploadFile')}</Box>

      <If condition={uploadFileResult.isLoading || importExcelResult.isLoading}>
        <Then>
          <Stack
            direction="column"
            spacing={2}
            alignItems="center"
            justifyContent="center"
          >
            <LinearProgress sx={{ width: '100%' }} color="primary" />
            <Typography variant="body2">
              {t('importUsersDashboard.importing')}
            </Typography>
          </Stack>
        </Then>
        <Else>
          <UploadFileZone
            onDrop={onDrop}
            acceptedFiles={{
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                [],
            }}
          />
        </Else>
      </If>

      <Unless condition={isNil(uploadFileResult?.error)}>
        <Alert severity="error">
          {t('importUsersDashboard.uploadErrorMessage')}
        </Alert>
      </Unless>

      <Switch>
        <Case condition={!!importExcelResult?.error}>
          <Grid container p={1}>
            <ImportUsersResultsTitle
              title={t('importUsersDashboard.results')}
            />

            <ImportUsersResultsRow
              Icon={Close}
              text={t('importUsersDashboard.importErrorMessage')}
            />
          </Grid>
        </Case>
        <Case condition={importExcelResult?.data?.status === 'success'}>
          <ImportResultSuccess value={importExcelResult.data!} />
        </Case>
        <Case condition={importExcelResult?.data?.status === 'delayed'}>
          <ImportResultDelayed value={importExcelResult.data!} />
        </Case>
      </Switch>
    </Page>
  );
}
