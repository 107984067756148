import { Accept, useDropzone } from 'react-dropzone';
import { CSSProperties, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, useTheme, Stack } from '@mui/material';
import { Unless } from 'react-if';
import { isNil } from 'ramda';

interface UploadFileZoneProps {
  onDrop: (files: File[]) => Promise<void>;
  acceptedFiles: Accept;
  label?: string;
  disabled?: boolean;
}

export default function UploadFileZone({
  onDrop,
  acceptedFiles,
  label,
  disabled = false,
}: UploadFileZoneProps) {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept: acceptedFiles,
      onDrop,
    });

  const style = useMemo(() => {
    const baseStyle: CSSProperties = {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '20px',
      borderWidth: 2,
      borderRadius: 2,
      borderColor: palette.divider,
      borderStyle: 'dashed',
      color: palette.text.disabled,
      outline: 'none',
      transition: 'border .24s ease-in-out',
    };

    return {
      ...baseStyle,
      ...(isFocused ? { borderColor: palette.info.light } : {}),
      ...(isDragAccept
        ? {
            borderColor: palette.success.light,
          }
        : {}),
      ...(isDragReject
        ? {
            borderColor: palette.error.main,
          }
        : {}),
    };
  }, [isFocused, isDragAccept, isDragReject, palette]);

  return (
    <Box>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} disabled={disabled} />
        <Stack spacing={2}>
          <Typography variant="body1" fontWeight="bold" textAlign="center">
            {t('uploadFileZone.dropFileHere')}
          </Typography>
          <Unless condition={isNil(label)}>
            <Typography variant="body2" textAlign="center">
              {label}
            </Typography>
          </Unless>
        </Stack>
      </div>
    </Box>
  );
}
