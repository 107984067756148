import { useTranslation } from 'react-i18next';
import { JobType } from '../helpers/jobLogHelpers';
import { parseValidationError } from '../helpers/validatorHelpers';
import { Nullable } from 'typescript-nullable';
import { ReactNode } from 'react';

export default function useTranslationHelpers() {
  const { t } = useTranslation();
  const formatJobType = (type: JobType) =>
    t(`jobLog.types.${type.replaceAll(':', '.')}`, { defaultValue: type });

  const formatValidationError = (error: string | undefined) => {
    const parsed = parseValidationError(error);
    return Nullable.map((it) => t(it.key, it.params), parsed) as ReactNode;
  };

  return { formatJobType, formatValidationError };
}
