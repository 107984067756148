import { useState } from 'react';

export function useDrawer(isOpen = false) {
  const [open, setOpen] = useState(isOpen);

  const toggleDrawerState = () => {
    setOpen((prevState) => !prevState);
  };

  return {
    open,
    toggleDrawerState,
  };
}
