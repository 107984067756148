import { Stack, Typography, IconButton, Link } from '@mui/material';
import { Company } from '../app/services/futbolProdeApi';
import { Nullable } from 'typescript-nullable';
import EditIcon from '@mui/icons-material/Edit';
import { Routes, useCompanyRoutes } from '../router';
import { useUserPermissions } from './hooks/useUserPermissions';
import { When } from 'react-if';

export default function CompanyHeader({
  company,
}: {
  company: Nullable<Company>;
}) {
  const { appRoute } = useCompanyRoutes();
  const { isAdmin } = useUserPermissions();
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <img src={company?.isologoUrl} alt={company?.name} height={42} />
      <Stack direction="row" alignItems="flex-start">
        <Typography variant="h5" textTransform="uppercase">
          {company?.name}
        </Typography>
        <When condition={isAdmin}>
          <Link href={appRoute(`${Routes.COMPANIES}/${company?.id}`)}>
            <IconButton color="secondary" size="small">
              <EditIcon fontSize="inherit" />
            </IconButton>
          </Link>
        </When>
      </Stack>
    </Stack>
  );
}
