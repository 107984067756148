import Page from '../../common/Page';
import TranslationTabs from '../translations/TranslationsTabs';
import { Formik } from 'formik';
import {
  UpdateCompanyDto,
  useCompaniesControllerFindMeQuery,
  useCompaniesControllerUpdateMeMutation,
} from '../../app/services/futbolProdeApi';
import { mergeDeepRight } from 'ramda';
import { useMemo } from 'react';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { LinearProgress, Stack } from '@mui/material';
import { If, Then, Else } from 'react-if';
import ComingSoonModeForm from './ComingSoonModeForm';
import {
  TranslationsDto,
  defaultTranslations,
  useTranslationsPerLocaleQuery,
  useCreateTranslationsPerLocaleMutation,
} from '../translations/translationHelpers';

class ComingSoonModeTranslations {
  comingSoonMode!: {
    title: string;
    subtitle: string;
  };
}

export interface ComingSoonModeDto {
  translations: TranslationsDto<ComingSoonModeTranslations>;
  company: UpdateCompanyDto;
}

export default function ComingSoonModePage() {
  const { t } = useTranslation();
  const { data: company, isLoading: companyLoading } =
    useCompaniesControllerFindMeQuery();
  const { data: translationsPerLocale, isLoading: translationsLoading } =
    useTranslationsPerLocaleQuery<ComingSoonModeTranslations>('company');

  const { createTranslations, result: translationsResult } =
    useCreateTranslationsPerLocaleMutation('company');
  const [updateCompany, companyResult] =
    useCompaniesControllerUpdateMeMutation();

  const dto: ComingSoonModeDto = useMemo(
    () =>
      mergeDeepRight(
        {
          translations: defaultTranslations({
            comingSoonMode: {
              title: '',
              subtitle: '',
            },
          }),
          company: {
            comingSoonModeIsEnabled: false,
            comingSoonModeBackgroundImageUrl: '',
            comingSoonModeTextColor: '#ffffff',
          },
        },
        {
          translations: translationsPerLocale ?? {},
          company: (company as UpdateCompanyDto) ?? {},
        },
      ),
    [translationsPerLocale, company],
  );

  const handleSubmit = async (values: ComingSoonModeDto) => {
    createTranslations(values.translations);
    updateCompany(values.company);
  };

  return (
    <Page
      breadcrumbs={[{ label: 'sidebar.settings' }]}
      title="sidebar.comingSoon"
    >
      <If condition={companyLoading || translationsLoading}>
        <Then>
          <LinearProgress /> :
        </Then>
        <Else>
          <Formik
            initialValues={dto}
            onSubmit={handleSubmit}
            validateOnChange={false}
            handleSubmit
          >
            {({
              handleSubmit,
              handleChange,
              values,
              errors,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <Stack spacing={2}>
                  <TranslationTabs>
                    {(locale) =>
                      ComingSoonModeForm({
                        locale,
                        handleChange,
                        values,
                        setFieldValue,
                        errors,
                      })
                    }
                  </TranslationTabs>
                  <LoadingButton
                    loading={
                      translationsResult.isLoading || companyResult.isLoading
                    }
                    fullWidth
                    type="submit"
                    variant="contained"
                  >
                    {t('save')}
                  </LoadingButton>
                </Stack>
              </form>
            )}
          </Formik>
        </Else>
      </If>
    </Page>
  );
}
