import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Area } from '../../app/services/futbolProdeApi';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';

interface AreaSelectProps {
  onValueUpdated: (arg: Area | null) => void;
  areas: Area[];
  fullWidth?: boolean;
  autoSelectSingleElement?: boolean;
  initialValue?: Area;
  placeholderLabel: string;
}

export default function AreaSelect({
  onValueUpdated,
  areas,
  autoSelectSingleElement = false,
  fullWidth = false,
  initialValue,
  placeholderLabel,
}: AreaSelectProps) {
  const { t } = useTranslation();
  const [value, setValue] = useState<Area | null>(initialValue ?? null);

  const onValueChanged = useCallback(
    (newValue: Area | null) => {
      setValue(newValue);
      onValueUpdated(newValue);
    },
    [onValueUpdated],
  );

  useEffect(() => {
    if (autoSelectSingleElement && areas.length === 1) {
      onValueChanged(areas[0]);
    }
  }, [autoSelectSingleElement, areas, onValueChanged]);

  return (
    <Autocomplete
      onChange={(_event, newValue) => onValueChanged(newValue)}
      value={value}
      fullWidth={fullWidth}
      noOptionsText={t('autocomplete.noOptions')}
      options={areas}
      autoHighlight
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          {option.name}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={t('autocomplete.chooseOne') as string}
          label={placeholderLabel}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'off',
          }}
        />
      )}
    />
  );
}
