import Page from '../../common/Page';
import TeamForm from './TeamForm';
import { Routes, useCompanyRoutes } from '../../router';

export default function TeamCreatePage() {
  const { appRoute } = useCompanyRoutes();
  return (
    <Page
      breadcrumbs={[{ label: 'sidebar.teams', url: appRoute(Routes.TEAMS) }]}
      title="common.new"
    >
      <TeamForm />
    </Page>
  );
}
