import { baseApi } from '@futbolprode/ui-common';
import { futbolProdeApi } from './futbolProdeApi';

export function enhanceEndpoints() {
  futbolProdeApi.enhanceEndpoints({
    endpoints: {
      tournamentImporterControllerImportMatchResultManual: {
        invalidatesTags: ['rounds'],
      },
      tournamentImporterControllerImportMatchResult: {
        invalidatesTags: ['rounds'],
      },
      usersControllerRemove: {
        invalidatesTags: ['companies', 'users'],
      },
      invitationsControllerRemove: {
        invalidatesTags: ['companies', 'users', 'invitations'],
      },
      usersControllerRemoveMulti: {
        invalidatesTags: ['companies', 'users'],
      },
      invitationsControllerRemoveMulti: {
        invalidatesTags: ['companies', 'users', 'invitations'],
      },
    },
  });
}

export { baseApi };
