import { Type } from 'class-transformer';
import { IsArray, ValidateNested } from 'class-validator';
import {
  LocalizedArrayMaxSize,
  LocalizedArrayMinSize,
  LocalizedIsDateString,
  LocalizedIsNotEmpty,
  LocalizedMin,
} from '../../common/helpers/validatorHelpers';

export class CreateTriviaQuestionChoiceDto {
  @LocalizedIsNotEmpty()
  readonly option!: string;

  @LocalizedIsNotEmpty()
  readonly isCorrect!: boolean;
}

export class CreateTriviaQuestionDto {
  @LocalizedIsNotEmpty()
  readonly question!: string;

  @LocalizedIsNotEmpty()
  @IsArray()
  @LocalizedArrayMinSize(2)
  @LocalizedArrayMaxSize(5)
  @ValidateNested({ each: true })
  @Type(() => CreateTriviaQuestionChoiceDto)
  // @ContainsExactlyOne<CreateTriviaQuestionChoiceDto>('isCorrect', true)
  choices!: CreateTriviaQuestionChoiceDto[];
}

export class CreateTriviaDto {
  @LocalizedIsNotEmpty()
  readonly title!: string;

  readonly subtitle!: string;

  @LocalizedIsNotEmpty()
  readonly coverImageUrl!: string;

  @LocalizedIsNotEmpty()
  readonly pointsByCorrectAnswer!: number;

  @LocalizedIsNotEmpty()
  @LocalizedMin(3)
  //   @MaxLengthOfArray('questions')
  readonly randomSelectionCount!: number;

  @LocalizedIsNotEmpty()
  @LocalizedArrayMinSize(5)
  @ValidateNested({ each: true })
  @Type(() => CreateTriviaQuestionDto)
  questions!: CreateTriviaQuestionDto[];

  @LocalizedIsNotEmpty()
  @LocalizedIsDateString()
  readonly startsAt!: string;

  @LocalizedIsNotEmpty()
  @LocalizedIsDateString()
  readonly finishesAt!: string;

  @LocalizedIsNotEmpty()
  readonly maxTimePerQuestion!: number;
}
