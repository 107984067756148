import {
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Input } from '../../common/form/Input';
import { Unless, When } from 'react-if';
import {
  ANNOUNCEMENT_COLOR_OPTIONS,
  Announcement,
} from '@futbolprode/ui-common';
import { MuiColorInput } from 'mui-color-input';
import { useState } from 'react';
import { FormikHandlers, FormikHelpers } from 'formik';
import { isNil } from 'ramda';

const CUSTOM_SEVERITY = 'custom';

function isCustomSeverity(value: string) {
  return !isNil(value) && !ANNOUNCEMENT_COLOR_OPTIONS.includes(value);
}

export function AnnouncementStyleSelector({
  handleChange,
  values,
  name,
  disabled,
  setFieldValue,
}: {
  handleChange: FormikHandlers['handleChange'];
  values: any;
  name: string;
  disabled?: boolean;
  setFieldValue: FormikHelpers<any>['setFieldValue'];
}) {
  const { t } = useTranslation();
  const severityField = `${name}AnnouncementSeverity`;
  const [customColor, setCustomColor] = useState(
    values.company[severityField] ?? '#000000',
  );
  const [enableCustomColor, setEnableCustomColor] = useState(
    isCustomSeverity(values.company[severityField]),
  );

  const handleSelectChange = (event: SelectChangeEvent<any>) => {
    if (event.target.value === CUSTOM_SEVERITY) {
      setEnableCustomColor(true);
      setFieldValue(`company.${severityField}`, customColor);
    } else {
      setEnableCustomColor(false);
      handleChange(event);
    }
  };

  const handleCustomColorChange = (color: string) => {
    setCustomColor(color);
    setFieldValue(`company.${severityField}`, color);
  };

  return (
    <>
      <InputLabel htmlFor={name}>{t('common.announcementSeverity')}</InputLabel>
      <Select
        name={`company.${severityField}`}
        id={severityField}
        value={values.company[severityField]}
        label={t('common.announcementSeverity')}
        onChange={handleSelectChange}
        disabled={disabled}
        renderValue={(value) =>
          isCustomSeverity(value)
            ? t('common.severity.custom')
            : t(`common.severity.${value}`)
        }
      >
        {ANNOUNCEMENT_COLOR_OPTIONS.concat(CUSTOM_SEVERITY).map((severity) => (
          <MenuItem key={severity} value={severity}>
            {t(`common.severity.${severity}`)}
          </MenuItem>
        ))}
      </Select>

      <When condition={enableCustomColor}>
        <MuiColorInput value={customColor} onChange={handleCustomColorChange} />
      </When>
    </>
  );
}

export default function AnnouncementInput({
  handleChange,
  locale,
  values,
  name,
  setFieldValue,
  disableStyleSelector = false,
}: {
  handleChange: FormikHandlers['handleChange'];
  locale: string;
  values: any;
  name: string;
  setFieldValue: FormikHelpers<any>['setFieldValue'];
  disableStyleSelector?: boolean;
}) {
  const { t } = useTranslation();
  const isEnabledField = `${name}AnnouncementIsEnabled`;

  return (
    <>
      <FormControlLabel
        control={
          <Switch
            id={isEnabledField}
            checked={values.company[isEnabledField]}
            onChange={handleChange}
            name={`company.${isEnabledField}`}
          />
        }
        label={t('common.isActive')}
      />
      <Input
        value={values.translations[locale].announcements[name]}
        multiline
        name={`translations.${locale}.announcements.${name}`}
        label={'common.message'}
        onChange={handleChange}
        disabled={!values.company[isEnabledField]}
      />
      <Unless condition={disableStyleSelector}>
        <AnnouncementStyleSelector
          handleChange={handleChange}
          values={values}
          name={name}
          disabled={!values.company[isEnabledField]}
          setFieldValue={setFieldValue}
        />
        <InputLabel>{t('common.preview')}</InputLabel>
        <Announcement
          messageKey={values.translations[locale].announcements[name]}
          isEnabled={true}
          severity={values.company[`${name}AnnouncementSeverity`]}
        />
      </Unless>
    </>
  );
}
