import { InputLabel, Grid, FormHelperText } from '@mui/material';
import { isNil } from 'ramda';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export interface InputWrapperProps {
  name: string;
  children: JSX.Element;
  label?: string;
  helperText?: string;
  required?: boolean;
}

export default function InputWrapper({
  name,
  children,
  label,
  helperText,
  required = false,
}: InputWrapperProps) {
  const { t } = useTranslation();
  const i18nLabel = useMemo(() => t(label ?? ''), [t, label]);
  const i18nHelperText = useMemo(() => t(helperText ?? ''), [t, helperText]);
  const hasLabel = useMemo(() => !isNil(label), [label]);
  const mdWidth = useMemo(() => (hasLabel ? 6 : 12), [hasLabel]);

  return (
    <Grid container alignItems="center">
      <Grid item xs={12} md={mdWidth}>
        <InputLabel htmlFor={name} required={required}>
          {i18nLabel}
        </InputLabel>
        <FormHelperText>{i18nHelperText}</FormHelperText>
      </Grid>
      <Grid item xs={12} md={mdWidth}>
        {children}
      </Grid>
    </Grid>
  );
}
