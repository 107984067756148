import { ActiveSectionsForm } from './ActiveSectionsForm';
import { UpdateCompanySettingsDashboard } from './UpdateCompanySettingsDashboard';

export function ActiveSectionsDashboard() {
  return (
    <UpdateCompanySettingsDashboard
      Form={ActiveSectionsForm}
      title="sidebar.activeSections"
    />
  );
}
