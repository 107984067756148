import { Download } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import {
  useInvitationsControllerFindAllQuery,
  useInvitationsControllerRemoveAllMutation,
} from '../../app/services/futbolProdeApi';
import Page from '../../common/Page';
import { useDownloadFile } from './authUtils';
import { UsersTable } from './UsersTable';
import { useState } from 'react';
import { Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import ConfirmationModal from '../../common/ConfirmationModal';
import FloatingButton from '../../common/FloatingButton';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { usePaginationSearchParams } from './usePaginationSearchParams';

export function InvitationsPage() {
  const [showConfirmationModal, setShowDeleteConfirmation] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { params, pageSize, page, setPage, ...rest } =
    usePaginationSearchParams();
  const { data, isLoading } = useInvitationsControllerFindAllQuery(params);
  const [deleteAll, deleteAllResult] =
    useInvitationsControllerRemoveAllMutation();
  const invitations = data?.data ?? [];
  const { downloadFile, isLoading: isDownloading } = useDownloadFile({
    url: 'invitations/download/csv',
    method: 'POST',
  });

  return (
    <Page
      breadcrumbs={[{ label: 'sidebar.users' }]}
      title="sidebar.pendingInvitations"
      actions={
        <Stack direction="row" spacing={2}>
          <LoadingButton
            color="error"
            variant="contained"
            onClick={() => setShowDeleteConfirmation(true)}
            loading={deleteAllResult.isLoading}
          >
            {t('invitationsPage.deleteAll')}
          </LoadingButton>
          <FloatingButton color="primary" onClick={() => navigate('new')}>
            <AddIcon />
          </FloatingButton>
          <FloatingButton
            color="primary"
            onClick={() => downloadFile()}
            isLoading={isDownloading}
          >
            <Download />
          </FloatingButton>
        </Stack>
      }
    >
      <UsersTable
        kind="invitation"
        isLoading={isLoading}
        noElementsText={t('invitationsPage.noPendingUsers')}
        users={invitations}
        pageSize={pageSize}
        page={page}
        setPage={setPage}
        count={data?.pagination?.count ?? 0}
        {...rest}
      />
      <ConfirmationModal
        descriptionKey={'invitationsPage.confirmDeleteAll'}
        open={showConfirmationModal}
        onAccept={() => {
          deleteAll();
          setShowDeleteConfirmation(false);
        }}
        onCancel={() => setShowDeleteConfirmation(false)}
        isSubmitting={deleteAllResult.isLoading}
        isDelete
      />
    </Page>
  );
}
