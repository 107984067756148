import Page from '../../common/Page';
import { CommonTranslationsEdit } from './CommonTranslationsEdit';
import TranslationTabs from './TranslationsTabs';

export function CommonTranslationsDashboard() {
  return (
    <Page
      breadcrumbs={[{ label: 'sidebar.customization' }]}
      title="sidebar.translations"
    >
      <TranslationTabs>
        {(locale) => <CommonTranslationsEdit locale={locale} />}
      </TranslationTabs>
    </Page>
  );
}
