import { AlertProps, Chip } from '@mui/material';
import { Trivia } from '../../app/services/futbolProdeApi';
import { Circle } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

export const triviaStatusToColor: Record<
  Trivia['status'],
  AlertProps['color'] | 'default'
> = {
  draft: 'default',
  published: 'success',
  paused: 'warning',
};

export const TriviaStatusChip = ({ value }: { value: Trivia['status'] }) => {
  const { t } = useTranslation();
  return (
    <Chip
      label={t(`entity.trivia.status_${value}`)}
      size="small"
      variant="outlined"
      color={triviaStatusToColor[value]}
      sx={{ '& .MuiChip-icon': { fontSize: 10, ml: 0.5 } }}
      icon={<Circle />}
    />
  );
};
