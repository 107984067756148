import { FormHelperText, Stack } from '@mui/material';
import {
  Company,
  useCompaniesControllerUpdateMutation,
} from '../../app/services/futbolProdeApi';
import { LoadingButton } from '@mui/lab';
import { Formik } from 'formik';
import { t } from 'i18next';
import BannerForm, {
  BannerVersion,
  BannerKind,
  CompanyBannersDto,
} from './BannerForm';
import { capitalizeFirst } from '../../common/helpers/stringHelpers';
import createValidator from 'class-validator-formik';

export function BannersForm({ company }: { company?: Company }) {
  const [updateCompany, updateResult] = useCompaniesControllerUpdateMutation();
  const handleSubmit = async (formValues: CompanyBannersDto) => {
    if (company) {
      await updateCompany({
        id: company!.id.toString(),
        updateCompanyDto: formValues,
      }).unwrap();
    }
  };

  const deleteBanner = async (kind: BannerKind, version: BannerVersion) => {
    await handleSubmit({
      [`${kind}Banner${capitalizeFirst(version)}ImageUrl`]: '',
    });
  };

  const companyDto: CompanyBannersDto = {
    superiorBannerDesktopImageUrl:
      company?.superiorBanner?.desktopImageUrl || '',
    superiorBannerMobileImageUrl: company?.superiorBanner?.mobileImageUrl || '',
    superiorBannerLink: company?.superiorBanner?.link,
    middleBannerDesktopImageUrl: company?.middleBanner?.desktopImageUrl || '',
    middleBannerMobileImageUrl: company?.middleBanner?.mobileImageUrl || '',
    middleBannerLink: company?.middleBanner?.link,
    sideBannerDesktopImageUrl: company?.sideBanner?.desktopImageUrl || '',
    sideBannerMobileImageUrl: company?.sideBanner?.mobileImageUrl || '',
    sideBannerLink: company?.sideBanner?.link,
  };

  return (
    <Formik
      initialValues={companyDto}
      onSubmit={handleSubmit}
      validateOnChange={false}
      validate={createValidator(CompanyBannersDto)}
      handleSubmit
    >
      {({ handleSubmit, handleChange, values, errors, setFieldValue }) => (
        <form onSubmit={handleSubmit}>
          <Stack spacing={2}>
            <BannerForm
              kind="superior"
              values={values}
              errors={errors}
              setFieldValue={setFieldValue}
              handleChange={handleChange}
              onDelete={deleteBanner}
            />
            <BannerForm
              kind="middle"
              values={values}
              errors={errors}
              setFieldValue={setFieldValue}
              handleChange={handleChange}
              onDelete={deleteBanner}
            />
            <BannerForm
              kind="side"
              values={values}
              errors={errors}
              setFieldValue={setFieldValue}
              handleChange={handleChange}
              onDelete={deleteBanner}
            />
            <FormHelperText error>
              {updateResult.error && t('companiesDashboard.error')}
            </FormHelperText>
            <LoadingButton
              loading={updateResult.isLoading}
              fullWidth
              type="submit"
              variant="contained"
            >
              {t('save')}
            </LoadingButton>
          </Stack>
        </form>
      )}
    </Formik>
  );
}
