import { Stack, Skeleton, Typography } from '@mui/material';
import { TournamentListDetail } from './TournamentListDetail';
import AddIcon from '@mui/icons-material/Add';
import {
  compose,
  descend,
  groupBy,
  identity,
  keys,
  partition,
  sort,
  sortBy,
} from 'ramda';
import { useMemo, useState } from 'react';
import { useI18nHelpers } from '@futbolprode/ui-common';
import ImportTournamentModal from './ImportTournamentModal';
import { useTournamentsControllerFindAllQuery } from '../../app/services/futbolProdeApi';
import Page from '../../common/Page';
import { useSkeletonReadyList } from '@futbolprode/ui-common';
import FloatingButton from '../../common/FloatingButton';
import { PageSection } from '../../common/PageSection';
import { useTranslation } from 'react-i18next';

const sortedCountries = compose(sortBy(identity), keys);

export default function TournamentsDashboard() {
  const { t } = useTranslation();
  const { data, isLoading } = useTournamentsControllerFindAllQuery();
  const { formatCountryName } = useI18nHelpers();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [current, archived] = partition(
    (it) => !!it.isActive,
    data?.data || [],
  );

  const tournamentsByCountry = useMemo(
    () => groupBy((it) => formatCountryName(it.country), current),
    [current, formatCountryName],
  );

  const countries = useSkeletonReadyList(sortedCountries(tournamentsByCountry));

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <Page
      breadcrumbs={[{ label: 'sidebar.globalSettings' }]}
      title="sidebar.tournament"
      actions={
        <FloatingButton color="primary" onClick={openModal}>
          <AddIcon />
        </FloatingButton>
      }
    >
      <ImportTournamentModal
        open={isModalOpen}
        handleClose={closeModal}
      ></ImportTournamentModal>
      <Stack spacing={2}>
        <Typography variant="h4">{t('tournamentsPage.active')}</Typography>
        {countries.map((country, index) =>
          isLoading ? (
            <Skeleton width="100%">
              <Typography variant="h3">.</Typography>
            </Skeleton>
          ) : (
            <PageSection key={index} title={country}>
              <TournamentListDetail
                tournamentsList={tournamentsByCountry[country]}
              />
            </PageSection>
          ),
        )}
        <Typography variant="h4">{t('tournamentsPage.inactive')}</Typography>
        <PageSection>
          <TournamentListDetail
            tournamentsList={sort(
              descend((it) => it.season ?? ''),
              archived,
            )}
            displayCountry
          />
        </PageSection>
      </Stack>
    </Page>
  );
}
