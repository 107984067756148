import { useDreamFinalSettingsControllerMeQuery } from '../../app/services/futbolProdeApi';
import Page from '../../common/Page';
import { Grid, Alert, AlertTitle } from '@mui/material';
import DreamFinalCard from './DreamFinalCard';
import { useTranslation } from 'react-i18next';
import { useSkeletonReadyList } from '@futbolprode/ui-common';

export function DreamFinalDashboard() {
  const { data, isLoading } = useDreamFinalSettingsControllerMeQuery();
  const { t } = useTranslation();
  const settings = useSkeletonReadyList(data, isLoading, 2);

  return (
    <Page breadcrumbs={[{ label: 'sidebar.game' }]} title="sidebar.dreamFinal">
      <Alert severity="info">
        <AlertTitle>{t('dreamFinalDashboard.alert.important')}</AlertTitle>
        <ul>
          {t<string, string[]>('dreamFinalDashboard.alert.advises', {
            returnObjects: true,
          }).map((it) => (
            <li>{it}</li>
          ))}
        </ul>
      </Alert>
      <Grid container justifyContent="space-between" rowSpacing={3}>
        {settings.map((it) => (
          <Grid item md={5.8} xs={12}>
            <DreamFinalCard settings={it} isLoading={isLoading} />
          </Grid>
        ))}
      </Grid>
    </Page>
  );
}
