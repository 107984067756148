import { Grid, Stack, Divider, Typography } from '@mui/material';
import { Input } from '../../common/form/Input';
import { FormikErrors, FormikHandlers, FormikHelpers } from 'formik';
import { PageSection } from '../../common/PageSection';
import { useMemo } from 'react';
import { IsOptional } from 'class-validator';
import { LocalizedIsUrl } from '../../common/helpers/validatorHelpers';
import BannerInput from './BannerInput';

export type BannerKind = 'superior' | 'middle' | 'side';
export type BannerVersion = 'desktop' | 'mobile';

export interface BannerFormProps {
  values: CompanyBannersDto;
  errors: FormikErrors<CompanyBannersDto>;
  kind: BannerKind;
  setFieldValue: FormikHelpers<any>['setFieldValue'];
  handleChange: FormikHandlers['handleChange'];
  onDelete: (kind: BannerKind, version: BannerVersion) => Promise<void>;
}

export class CompanyBannersDto {
  @LocalizedIsUrl()
  @IsOptional()
  superiorBannerLink?: string;
  superiorBannerDesktopImageUrl?: string;
  superiorBannerMobileImageUrl?: string;

  @LocalizedIsUrl()
  @IsOptional()
  middleBannerLink?: string;
  middleBannerDesktopImageUrl?: string;
  middleBannerMobileImageUrl?: string;

  @LocalizedIsUrl()
  @IsOptional()
  sideBannerLink?: string;
  sideBannerDesktopImageUrl?: string;
  sideBannerMobileImageUrl?: string;
}

export default function BannerForm({
  kind,
  values,
  errors,
  setFieldValue,
  handleChange,
  onDelete,
}: BannerFormProps) {
  const linkFieldName = useMemo(
    () => `${kind}BannerLink` as keyof CompanyBannersDto,
    [kind],
  );

  return (
    <PageSection title={`bannersPage.${kind}Kind`}>
      <Stack spacing={2}>
        <BannerInput
          version="desktop"
          kind={kind}
          setFieldValue={setFieldValue}
          values={values}
          errors={errors}
          onDelete={onDelete}
        />
        <Divider />
        <BannerInput
          version="mobile"
          kind={kind}
          setFieldValue={setFieldValue}
          values={values}
          errors={errors}
          onDelete={onDelete}
        />
        <Divider />
        <Grid container>
          <Grid item md={6} xs={12}>
            <Typography variant="h6">Link en la imagen</Typography>
            <Typography variant="subtitle1">
              URL externa opcional en ambas imágenes
            </Typography>
          </Grid>
          <Grid item md={6} xs={12}>
            <Input
              value={values[linkFieldName]}
              error={errors[linkFieldName]}
              name={linkFieldName}
              onChange={handleChange}
              InputProps={{ placeholder: 'https://example.com' }}
            />
          </Grid>
        </Grid>
      </Stack>
    </PageSection>
  );
}
