import { IsNotEmpty, IsNumber, IsOptional, IsUrl } from 'class-validator';
import {
  Award,
  useAwardsControllerFindAllQuery,
  useAwardsControllerUpsertMutation,
} from '../../app/services/futbolProdeApi';
import Page from '../../common/Page';
import { PageSection } from '../../common/PageSection';
import { Formik } from 'formik';
import {
  LinearProgress,
  Stack,
  IconButton,
  IconButtonProps,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@mui/material';
import AwardItemForm from './AwardItemForm';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import FloatingButton from '../../common/FloatingButton';
import AddIcon from '@mui/icons-material/Add';
import { isNil } from 'ramda';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const emptyAward = { name: '', imageUrl: '' };

const awardWithPosition = (position: number, awards: Award[]): CreateAwardDto =>
  awards.find((it) => it.position === position) ?? {
    position,
    ...emptyAward,
  };

const DeleteButton = (props: IconButtonProps) => (
  <IconButton {...props}>
    <RemoveCircleIcon />
  </IconButton>
);

export class CreateAwardDto {
  @IsNotEmpty()
  name!: string;

  @IsUrl()
  imageUrl!: string;

  description?: string;

  // Solo para premios predeterminados
  @IsNumber()
  @IsOptional()
  position?: number;

  // Solo para premios personalizados
  customPosition?: string;
}

export default function AwardsPage() {
  const { data: awards, isLoading } = useAwardsControllerFindAllQuery();
  const [upsert, result] = useAwardsControllerUpsertMutation();
  const { t } = useTranslation();

  const awardsDto = {
    default: [
      awardWithPosition(1, awards ?? []),
      awardWithPosition(2, awards ?? []),
      awardWithPosition(3, awards ?? []),
    ],
    custom: awards?.filter(
      (it) => !isNil(it.customPosition),
    ) as CreateAwardDto[],
  };

  const handleSubmit = async (values: {
    default: CreateAwardDto[];
    custom: CreateAwardDto[];
  }) => {
    upsert(values.default.concat(values.custom));
  };

  return (
    <Page breadcrumbs={[{ label: 'sidebar.game' }]} title="sidebar.awards">
      {isLoading ? (
        <LinearProgress />
      ) : (
        <Formik
          initialValues={awardsDto}
          onSubmit={handleSubmit}
          validateOnChange={false}
          handleSubmit
        >
          {({ handleSubmit, handleChange, values, setFieldValue }) => {
            const deleteCustomAward = (awardIndex: number) => {
              setFieldValue(
                `custom`,
                values.custom?.filter((_award, index) => index !== awardIndex),
              );
            };

            const clearDefaultAward = (awardIndex: number) => {
              setFieldValue(`default[${awardIndex}]`, {
                ...values.default[awardIndex],
                ...emptyAward,
              });
            };

            return (
              <form onSubmit={handleSubmit}>
                <Stack spacing={2}>
                  <PageSection title="awardsPage.defaultAwards">
                    {values.default.map((it, index) => (
                      <Accordion sx={{ width: '100%' }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Stack
                            direction="row"
                            spacing={3}
                            alignItems="center"
                          >
                            <DeleteButton
                              onClick={() => clearDefaultAward(index)}
                            />
                            <Typography variant="h6">
                              {t(`awardsPage.defaultPosition_${it.position}`)}
                            </Typography>
                          </Stack>
                        </AccordionSummary>
                        <AccordionDetails>
                          <AwardItemForm
                            values={it}
                            namePrefix={`default[${index}]`}
                            handleChange={handleChange}
                            setFieldValue={setFieldValue}
                          />
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </PageSection>
                  <PageSection
                    title="awardsPage.customAwards"
                    subtitle="awardsPage.customAwardsHint"
                    actions={
                      <FloatingButton
                        color="primary"
                        onClick={() =>
                          setFieldValue(
                            'custom',
                            (values.custom ?? []).concat(emptyAward),
                          )
                        }
                      >
                        <AddIcon />
                      </FloatingButton>
                    }
                  >
                    {values.custom.map((it, index) => (
                      <Accordion sx={{ width: '100%' }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Stack
                            direction="row"
                            spacing={3}
                            alignItems="center"
                          >
                            <DeleteButton
                              onClick={() => deleteCustomAward(index)}
                            />
                            <Typography variant="h6">
                              {it.customPosition ??
                                t('awardsPage.customPositionPlaceholder')}
                            </Typography>
                          </Stack>
                        </AccordionSummary>
                        <AccordionDetails>
                          <AwardItemForm
                            values={it}
                            namePrefix={`custom[${index}]`}
                            handleChange={handleChange}
                            setFieldValue={setFieldValue}
                            custom
                          />
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </PageSection>
                  <LoadingButton
                    loading={result.isLoading}
                    fullWidth
                    type="submit"
                    variant="contained"
                  >
                    {t('save')}
                  </LoadingButton>
                </Stack>
              </form>
            );
          }}
        </Formik>
      )}
    </Page>
  );
}
