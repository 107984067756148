import { Avatar } from '@mui/material';
import { User } from '../app/services/futbolProdeApi';
import { compose, head, join, map, split } from 'ramda';

export interface UserAvatarProps {
  user?: User;
  size?: number;
}

const initials = compose(join(''), map<string, string>(head), split(' '));

export default function UserAvatar({ user, size = 95 }: UserAvatarProps) {
  return (
    <Avatar
      src={user?.profilePictureUrl}
      alt={user?.name ?? ''}
      sx={{ width: size, height: size }}
    >
      {initials(user?.name ?? '')}
    </Avatar>
  );
}
