import { MenuItem, Select, SelectProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { VALID_LOCALES } from '../../features/translations/TranslationsTabs';
import InputWrapper, { InputWrapperProps } from './InputWrapper';

export function LanguageSelect({
  value,
  name,
  label,
  onChange,
  ...wrapperProps
}: Omit<InputWrapperProps, 'children'> & {
  value: string;
  name: string;
  label?: string;
  onChange: SelectProps<string>['onChange'];
}) {
  const { t } = useTranslation();
  return (
    <InputWrapper
      name={name}
      label={label ?? 'common.language'}
      {...wrapperProps}
    >
      <Select
        labelId={name}
        name={name}
        value={value}
        onChange={onChange}
        fullWidth
      >
        {VALID_LOCALES.map((lang) => (
          <MenuItem key={lang} value={lang}>
            {t(`common.languages.${lang}`)}
          </MenuItem>
        ))}
      </Select>
    </InputWrapper>
  );
}
