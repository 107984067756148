import { TournamentDetails } from '../../app/services/futbolProdeApi';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import WarningIcon from '@mui/icons-material/Warning';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import { Link, Stack, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { useI18nHelpers } from '@futbolprode/ui-common';
import { When } from 'react-if';
import { CloudOff, CloudSync } from '@mui/icons-material';
import { isNil } from 'ramda';

function TournamentFeature({
  label,
  icon,
}: {
  label: string | JSX.Element | null;
  icon: JSX.Element;
}) {
  return (
    <Stack direction="row" spacing={1}>
      {icon}{' '}
      <Typography variant="subtitle1" color="textSecondary">
        {label}.
      </Typography>
    </Stack>
  );
}

const apiFootballFixtureEndpoint =
  'https://rapidapi.com/api-sports/api/api-football/playground/apiendpoint_09fdde85-c8a8-42cc-a492-816f46336dcf';

export default function TournamentFeatures({
  tournament,
}: {
  tournament?: TournamentDetails;
}) {
  const { t } = useTranslation();
  const { formatDate } = useI18nHelpers();
  const isApiFootballSynced = !isNil(tournament?.externalId);

  return (
    <Stack>
      <TournamentFeature
        icon={isApiFootballSynced ? <CloudSync /> : <CloudOff />}
        label={
          isApiFootballSynced ? (
            <Trans
              i18nKey="tournament.syncedWithApiFootball"
              values={{
                externalId: tournament?.externalId,
                season: tournament?.season,
              }}
            >
              Sincronizado con{' '}
              <Link
                href={apiFootballFixtureEndpoint}
                target="_blank"
                rel="noreferrer"
              >
                API Football
              </Link>{' '}
              - league: externalId, season: season
            </Trans>
          ) : (
            t('tournament.notSynced')
          )
        }
      />
      <TournamentFeature
        icon={<CalendarMonthIcon />}
        label={t('tournament.startsAt', {
          date: formatDate('PPPP', new Date(tournament?.startDate ?? 0)),
        })}
      />
      <TournamentFeature
        icon={<CalendarTodayIcon />}
        label={t('tournament.endsAt', {
          date: formatDate('PPPP', new Date(tournament?.endDate ?? 0)),
        })}
      />
      <When condition={tournament?.hasPenalties}>
        <TournamentFeature
          icon={<SportsSoccerIcon />}
          label={t('tournament.hasPenaltiesFeature')}
        />
      </When>
      <When condition={tournament?.hasTwoWayMatches}>
        <TournamentFeature
          icon={<SyncAltIcon />}
          label={t('tournament.hasTwoWayMatches')}
        />
      </When>
      <When condition={tournament?.showNoMoreRoundsWarning}>
        <TournamentFeature
          icon={<WarningIcon />}
          label={t('tournament.showNoMoreRoundsWarningFeature')}
        />
      </When>
      <When condition={tournament?.importNewMatchesWithPenalties}>
        <TournamentFeature
          icon={<ImportExportIcon />}
          label={t('tournament.importNewMatchesWithPenaltiesFeature')}
        />
      </When>
    </Stack>
  );
}
