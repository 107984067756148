import {
  either,
  isEmpty,
  isNil,
  is,
  curry,
  compose,
  map,
  omit,
  cond,
  T,
  identity,
  reject,
} from 'ramda';

export const compact = reject(isNil);

export const onlyIf = <T>(condition: boolean, item: T) =>
  condition ? item : undefined;

export const isNilOrEmpty = either(isNil, isEmpty);
export const isNilOrZero = either(isNil, (it) => it === 0);

interface OmitDeepFn {
  (names: string[]): (obj: Record<string, any>) => any;
  (names: string[], obj: Record<string, any>): any;
}

export const omitDeep: OmitDeepFn = curry((names, obj) =>
  cond([
    [is(Array), map(omitDeep(names))],
    [is(Object), compose(omit(names), map(omitDeep(names)))],
    [T, identity],
  ])(obj),
);

interface DeepOmitEmptyValuesFn {
  (obj: Record<string, any>): any;
}

const omitEmptyValues = reject(isNilOrEmpty);

export const deepOmitEmptyValues: DeepOmitEmptyValuesFn = (obj) =>
  cond([
    [is(Array), map(deepOmitEmptyValues)],
    [is(Object), compose(omitEmptyValues, map(deepOmitEmptyValues))],
    [T, identity],
    // @ts-expect-error no sé por qué espera que sea un array
  ])(obj);
