import {
  AreaRanking,
  Ranking,
  Round,
  Tournament,
  useTournamentsControllerFindAllByCompanyQuery,
  useTournamentsControllerFindOneQuery,
} from '../../app/services/futbolProdeApi';
import { useSkeletonReadyList } from '@futbolprode/ui-common';
import {
  Alert,
  FormLabel,
  InputAdornment,
  Pagination,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/system';
import TournamentSelect from './TournamentSelect';
import { useMemo, useState } from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { isEmpty, isNil } from 'ramda';
import { Switch, Case } from 'react-if';
import RoundSelect from './RoundSelect';
import Page from '../../common/Page';
import { Search } from '@mui/icons-material';
import { useDebounce } from 'use-debounce';
import RankingsTable from './RankingsTable';
import {
  RankingGrouping,
  useTournamentRankingQuery,
} from './useTournamentRankingQuery';

export const PAGE_SIZE = 1000;

export default function RankingsDashboard() {
  const { t } = useTranslation();
  const [tournament, setTournament] = useState<Tournament | null>(null);
  const [round, setRound] = useState<Round | null>(null);
  const [search, setSearch] = useState('');
  const [debouncedSearchTerm] = useDebounce(search, 500);
  const { data: tournaments } = useTournamentsControllerFindAllByCompanyQuery();

  const [grouping, setGrouping] = useState<RankingGrouping>('user');

  const handleGroupingChange = (
    _event: React.MouseEvent<HTMLElement>,
    newValue: string,
  ) => {
    setGrouping(newValue as RankingGrouping);
  };

  const rankingType = useMemo(() => {
    if (isNil(tournament)) {
      return 'general';
    }

    if (isNil(round)) {
      return 'tournament';
    }

    return 'round';
  }, [tournament, round]);

  const {
    query: {
      data: rankingData,
      isFetching: isRankingFetching,
      isUninitialized,
    },
    page,
    setPage,
  } = useTournamentRankingQuery({
    tournament,
    round,
    search: debouncedSearchTerm,
    grouping,
  });
  const rankingList = useSkeletonReadyList<Ranking | AreaRanking>(
    rankingData?.data,
    isRankingFetching,
  );

  const { data: tournamentData, isLoading: isTournamentLoading } =
    useTournamentsControllerFindOneQuery(
      tournament?.id.toString() ?? skipToken,
    );

  return (
    <Page breadcrumbs={[{ label: 'sidebar.game' }]} title="sidebar.rankings">
      <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }}>
        <TournamentSelect
          fullWidth
          tournaments={tournaments ?? []}
          onValueUpdated={(value) => setTournament(value)}
          placeholderLabel={t('tournament.filterBy')}
        />
        <RoundSelect
          fullWidth
          disabled={isNil(tournamentData)}
          rounds={tournamentData?.rounds ?? []}
          isLoading={isTournamentLoading}
          onValueUpdated={(value) => setRound(value)}
        />
      </Stack>
      <Stack spacing={3} direction={{ xs: 'column', sm: 'row' }}>
        <TextField
          value={search}
          fullWidth
          placeholder={
            t('rankingsDashboard.searchBy', {
              grouping: t(`rankings.${grouping}`),
            }) as string
          }
          onChange={(e) => setSearch(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
        <Stack direction="row" spacing={1} alignItems="center">
          <FormLabel sx={{ whiteSpace: 'nowrap' }}>Tipo de ranking:</FormLabel>
          <ToggleButtonGroup
            color="primary"
            value={grouping}
            exclusive
            onChange={handleGroupingChange}
          >
            <ToggleButton value="user">
              {t('rankingsDashboard.grouping.user')}
            </ToggleButton>
            <ToggleButton value="area">
              {t('rankingsDashboard.grouping.area')}
            </ToggleButton>
          </ToggleButtonGroup>
        </Stack>
      </Stack>
      <Switch>
        <Case condition={isEmpty(rankingList)}>
          <Alert severity="warning">
            {t('rankingsDashboard.noRankingsFound')}
          </Alert>
        </Case>
        <Case condition={!isUninitialized}>
          <Pagination
            showLastButton
            variant="outlined"
            disabled={
              isUninitialized || isNil(rankingList) || isEmpty(rankingList)
            }
            count={Math.ceil((rankingData?.pagination?.count ?? 0) / PAGE_SIZE)}
            key={page}
            page={page + 1}
            onChange={(_event: React.ChangeEvent<unknown>, value: number) =>
              setPage(value - 1)
            }
            sx={{
              alignSelf: 'flex-end',
              '& .MuiPaginationItem-page': {
                border: 'none',
                backgroundColor: 'transparent',
              },
            }}
          />
          <RankingsTable
            rankingType={rankingType}
            tournament={tournament}
            isFetching={isRankingFetching}
            data={rankingList}
            grouping={grouping}
          />
        </Case>
      </Switch>
    </Page>
  );
}
