import { Trivia } from '../../app/services/futbolProdeApi';
import { Alert, AlertProps, AlertTitle, useTheme } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { Circle } from '@mui/icons-material';

export function TriviaStatusAlert({
  color,
  state,
  isActionLoading,
  isTriviaLoading,
  onClick,
}: {
  color: AlertProps['color'] | 'default';
  state: 'template' | Trivia['status'];
  isActionLoading: boolean;
  isTriviaLoading: boolean;
  onClick: () => Promise<void> | void;
}) {
  const { t } = useTranslation();
  const translate = (kind: string) => t(`triviasPage.states.${state}.${kind}`);
  const theme = useTheme();
  const sx =
    color === 'default'
      ? {
          backgroundColor: theme.palette.grey[200],
          '& .MuiAlert-icon': { color: theme.palette.grey[600] },
        }
      : {};

  return (
    <Alert
      severity="info"
      color={color === 'default' ? undefined : color}
      icon={<Circle />}
      action={
        <LoadingButton
          variant="contained"
          color="primary"
          loading={isActionLoading}
          disabled={isTriviaLoading}
          onClick={onClick}
        >
          {translate('action')}
        </LoadingButton>
      }
      sx={sx}
    >
      <AlertTitle>{translate('title')}</AlertTitle>
      {translate('description')}
    </Alert>
  );
}
