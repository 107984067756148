import { Grid, Typography } from '@mui/material';

export function ImportUsersResultsTitle({ title }: { title: string }) {
  return (
    <Grid item xs={12} p={1} bgcolor={'#F5F5F5'}>
      <Typography
        variant="subtitle1"
        textTransform={'uppercase'}
        color="textSecondary"
      >
        {title}
      </Typography>
    </Grid>
  );
}
