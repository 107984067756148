import { useParams } from 'react-router-dom';
import { useTeamsControllerFindOneQuery } from '../../app/services/futbolProdeApi';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import Page from '../../common/Page';
import TeamForm from './TeamForm';
import { Routes, useCompanyRoutes } from '../../router';

export default function TeamEditPage() {
  const { appRoute } = useCompanyRoutes();
  const { id } = useParams();
  const { data, isLoading } = useTeamsControllerFindOneQuery(
    id ? id : skipToken,
  );
  return (
    <Page
      breadcrumbs={[{ label: 'sidebar.teams', url: appRoute(Routes.TEAMS) }]}
      title={data?.name}
    >
      <TeamForm team={data} isLoading={isLoading} />
    </Page>
  );
}
