import { UpdateCompanySettingsDashboard } from './UpdateCompanySettingsDashboard';
import { UserEditPermissionsForm } from './UserEditPermissionsForm';

export function UserEditPermissionsDashboard() {
  return (
    <UpdateCompanySettingsDashboard
      Form={UserEditPermissionsForm}
      title="sidebar.userPermissions"
    />
  );
}
