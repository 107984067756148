import { UpdateTriviaDto } from '../../app/services/futbolProdeApi';
import { FormikErrors, FormikHandlers, FormikHelpers } from 'formik';
import { PageSection } from '../../common/PageSection';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FormHelperText,
  Grid,
  IconButton,
  IconButtonProps,
  Radio,
  RadioGroup,
  Stack,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useState } from 'react';
import { Input } from '../../common/form/Input';
import { Unless } from 'react-if';
import { useTranslation } from 'react-i18next';
import { makeDefaultQuestion, makeDefaultQuestionChoice } from './TriviaForm';
import { isNil } from 'ramda';
import useTranslationHelpers from '../../common/hooks/useTranslationHelpers';

const DeleteButton = (props: IconButtonProps) => (
  <IconButton {...props}>
    <RemoveCircleIcon />
  </IconButton>
);

export default function TriviaFormQuestions({
  values,
  errors,
  handleChange,
  setFieldValue,
  disabled,
}: {
  values: UpdateTriviaDto;
  handleChange: FormikHandlers['handleChange'];
  errors: FormikErrors<UpdateTriviaDto>;
  setFieldValue: FormikHelpers<UpdateTriviaDto>['setFieldValue'];
  disabled: boolean;
}) {
  const { t } = useTranslation();
  const { formatValidationError } = useTranslationHelpers();
  const [expandedQuestion, setExpandedQuestion] = useState<
    number | undefined
  >();

  const handleExpandedQuestionChange =
    (panel: number) => (_event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpandedQuestion(isExpanded ? panel : undefined);
    };

  const selectCorrectChoice = (questionIndex: number, choiceIndex: number) => {
    values.questions?.[questionIndex].choices.forEach((_choice, index) => {
      setFieldValue(
        `questions[${questionIndex}].choices[${index}].isCorrect`,
        index === choiceIndex,
      );
    });
  };

  const deleteQuestion = (questionIndex: number) => {
    setFieldValue(
      `questions`,
      values.questions?.filter((_question, index) => index !== questionIndex),
    );
  };

  const deleteQuestionChoice = (choiceIndex: number) => {
    if (isNil(expandedQuestion)) {
      return;
    }

    setFieldValue(
      `questions[${expandedQuestion}].choices`,
      values.questions?.[expandedQuestion].choices?.filter(
        (_choice, index) => index !== choiceIndex,
      ),
    );
  };

  const addQuestion = () => {
    setFieldValue(
      'questions',
      (values.questions ?? []).concat(
        makeDefaultQuestion(values.questions?.length ?? 0),
      ),
    );
  };

  const addQuestionChoice = () => {
    if (isNil(expandedQuestion)) {
      return;
    }

    const choices = values.questions?.[expandedQuestion].choices ?? [];
    setFieldValue(
      `questions[${expandedQuestion}].choices`,
      choices.concat(makeDefaultQuestionChoice(choices.length)),
    );
  };

  return (
    <PageSection
      title="triviasPage.questions"
      actions={
        disabled ? undefined : (
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => addQuestion()}
            startIcon={<AddCircleIcon />}
          >
            {t('triviasPage.addQuestion')}
          </Button>
        )
      }
    >
      <Stack>
        <FormHelperText error>
          {formatValidationError(errors.questions)}
        </FormHelperText>
        {(values.questions ?? []).map((question, questionIndex) => (
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            key={questionIndex}
          >
            <Unless condition={disabled || expandedQuestion === questionIndex}>
              <DeleteButton onClick={() => deleteQuestion(questionIndex)} />
            </Unless>
            <Accordion
              sx={{ width: '100%' }}
              expanded={expandedQuestion === questionIndex}
              onChange={handleExpandedQuestionChange(questionIndex)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`question-${questionIndex}-content`}
                id={`question-${questionIndex}-header`}
              >
                <Grid container>
                  <Grid item xs={11.8}>
                    <Input
                      value={question.question}
                      error={
                        // @ts-ignore no está bien definido el tipo en Formik
                        errors.questions?.[questionIndex]?.question ??
                        // @ts-ignore no está bien definido el tipo en Formik
                        errors.questions?.[questionIndex]?.choices
                      }
                      name={`questions[${questionIndex}].question`}
                      onChange={handleChange}
                      disabled={disabled}
                    />
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Stack spacing={3}>
                  <RadioGroup>
                    {question.choices.map((choice, choiceIndex) => (
                      <Grid
                        container
                        key={choiceIndex}
                        alignItems="center"
                        columnSpacing={1}
                      >
                        <Grid item container xs={0.5}>
                          <Radio
                            checked={choice.isCorrect}
                            disabled={disabled}
                            onChange={(e) => {
                              if (e.target.checked) {
                                selectCorrectChoice(questionIndex, choiceIndex);
                              }
                            }}
                            name={`questions[${questionIndex}].choices[${choiceIndex}].isCorrect`}
                          />
                        </Grid>
                        <Grid item xs={11}>
                          <Input
                            value={choice.option}
                            error={
                              // @ts-ignore no está bien definido el tipo en Formik
                              errors.questions?.[questionIndex]?.choices?.[
                                choiceIndex
                              ]?.option
                            }
                            name={`questions[${questionIndex}].choices[${choiceIndex}].option`}
                            onChange={handleChange}
                            disabled={disabled}
                          />
                        </Grid>
                        <Grid item xs={0.5}>
                          <Unless condition={disabled}>
                            <DeleteButton
                              onClick={() => deleteQuestionChoice(choiceIndex)}
                            />
                          </Unless>
                        </Grid>
                      </Grid>
                    ))}
                  </RadioGroup>
                  <Unless condition={disabled}>
                    <Box display="flex" justifyContent="flex-end">
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => addQuestionChoice()}
                        startIcon={<AddCircleIcon />}
                      >
                        {t('triviasPage.addQuestionChoice')}
                      </Button>
                    </Box>
                  </Unless>
                </Stack>
              </AccordionDetails>
            </Accordion>
          </Stack>
        ))}
      </Stack>
    </PageSection>
  );
}
