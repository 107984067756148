import {
  Alert,
  Fab,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Pagination,
  Select,
  MenuItem,
  SelectChangeEvent,
  InputLabel,
  Button,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Else, If, Then, When } from 'react-if';
import { Stack } from '@mui/system';
import { is, isEmpty, isNil, sortBy } from 'ramda';
import { Edit } from '@mui/icons-material';
import { format } from 'date-fns';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserPermissions } from '../../common/hooks/useUserPermissions';
import {
  Invitation,
  useCompaniesControllerFindMeQuery,
  useInvitationsControllerRemoveMultiMutation,
  User,
  useUsersControllerRemoveMultiMutation,
  useUsersControllerUpdateMultiMutation,
} from '../../app/services/futbolProdeApi';
import { LoginStrategy } from './LoginStrategy';
import UserSearchInput from './UserSearchInput';
import { useAreasForUserQuery } from './useAreasForUserQuery';
import { isNilOrEmpty } from '../../common/helpers/ramdaExtension';
import ConfirmationModal from '../../common/ConfirmationModal';

const extraFieldValue = (user: any, field: any) => {
  const value = user.extraFields.find(
    (it: any) => it.name === field.name,
  )?.value;
  return is(Boolean, value) ? (value ? 'Sí' : 'No') : value;
};

export function UsersTable({
  isLoading,
  noElementsText,
  users,
  kind,
  page,
  count,
  setPage,
  search,
  setSearch,
  pageSize,
  setPageSize,
}: {
  isLoading: boolean;
  noElementsText: string;
  users: any[];
  kind: 'user' | 'invitation';
  page: number;
  count: number;
  setPage: (it: number) => void;
  search: string;
  setSearch: (it: string) => void;
  pageSize: number;
  setPageSize: (it: number) => void;
}) {
  const { canDeleteUsers } = useUserPermissions();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: company } = useCompaniesControllerFindMeQuery();
  const showUserActions = kind === 'user';
  const showDeleteAction = canDeleteUsers || kind === 'invitation';
  const showExternalId = company?.loginStrategy === LoginStrategy.byExternalId;
  const { isLoading: isLoadingAreas, areaString } = useAreasForUserQuery();
  const [showConfirmationKind, setShowConfirmationKind] = useState<
    'delete' | 'disable' | 'recover' | undefined
  >(undefined);
  const [selectedUsers, setSelectedUsers] = useState<(User | Invitation)[]>([]);
  const [deleteUsers, deleteUsersResult] =
    useUsersControllerRemoveMultiMutation();
  const [deleteInvitations, deleteInvitationsResult] =
    useInvitationsControllerRemoveMultiMutation();
  const [updateUsers, updateUsersResult] =
    useUsersControllerUpdateMultiMutation();
  const resetSelectedState = () => {
    setSelectedUsers([]);
    setShowConfirmationKind(undefined);
  };

  const handleBulkAction =
    (action: (ids: string) => Promise<any>) => async () => {
      const ids = selectedUsers.map((it) => it.id).join(',');
      await action(ids);
      resetSelectedState();
    };

  const bulkActions = {
    delete: handleBulkAction(async (ids) => {
      if (kind === 'invitation') {
        await deleteInvitations(ids).unwrap();
      } else {
        await deleteUsers({ ids, soft: false }).unwrap();
      }
    }),
    disable: handleBulkAction(async (ids) => {
      await deleteUsers({ ids, soft: true }).unwrap();
    }),
    recover: handleBulkAction(async (ids) => {
      await updateUsers({
        ids,
        updateUserDto: { requiresAccountRecovery: true },
      }).unwrap();
    }),
  };

  const handleEnableUsers = handleBulkAction(async (ids) => {
    await updateUsers({
      ids,
      updateUserDto: { deletedAt: null },
    }).unwrap();
  });

  const toggleSelectedUsers = (
    user: User | Invitation,
    isSelected: boolean,
  ) => {
    const newSelectedUsers = isSelected
      ? selectedUsers.concat(user)
      : selectedUsers.filter((it) => it.id !== user.id);
    setSelectedUsers(newSelectedUsers);
  };

  const toggleSelectAll = () => {
    if (selectedUsers.length === users.length) {
      setSelectedUsers([]);
    } else {
      setSelectedUsers(users);
    }
  };

  const ExtraFieldsHeaders = useMemo(
    () =>
      kind === 'invitation' ? (
        <></>
      ) : (
        (company?.extraFields ?? []).map((field) => (
          <TableCell sx={{ minWidth: 150 }}>{field.name}</TableCell>
        ))
      ),
    [company?.extraFields, kind],
  );

  const ExtraFieldsColumns = useCallback(
    (user: any) =>
      kind === 'invitation' ? (
        <></>
      ) : (
        (company?.extraFields ?? []).map((field) => (
          <TableCell align="left">
            {extraFieldValue(user, field) ?? '-'}
          </TableCell>
        ))
      ),
    [company?.extraFields, kind],
  );

  const handleChange = (event: SelectChangeEvent) => {
    setPageSize(parseInt(event.target.value));
  };

  const { isAdminOrReseller, isImpersonating } = useUserPermissions();
  const showCompanyColumn = isAdminOrReseller && !isImpersonating;
  return (
    <If condition={isLoading || isLoadingAreas}>
      <Then>
        <Stack
          direction="column"
          spacing={2}
          alignItems="center"
          justifyContent="center"
        >
          <LinearProgress sx={{ width: '100%' }} color="primary" />
        </Stack>
      </Then>
      <Else>
        <If condition={isNilOrEmpty(search) && isEmpty(users)}>
          <Then>
            <Alert severity="info">{noElementsText}</Alert>
          </Then>
          <Else>
            <>
              <UserSearchInput value={search} onChange={setSearch} />
              <Stack direction="row" justifyContent="space-between">
                <Stack direction="row" spacing={1} alignItems="center">
                  <When condition={selectedUsers.length > 0}>
                    <Typography color="textSecondary" variant="body1">
                      Acciones masivas:
                    </Typography>
                    <When condition={showUserActions}>
                      {company?.loginStrategy ===
                        LoginStrategy.byExternalId && (
                        <Button
                          variant="outlined"
                          onClick={() => setShowConfirmationKind('recover')}
                        >
                          {t('activeUsersPage.triggerAccountRecovery', {
                            count: selectedUsers.length,
                          })}
                        </Button>
                      )}
                      {selectedUsers.every(
                        (it) => 'deletedAt' in it && isNil(it.deletedAt),
                      ) && (
                        <Button
                          variant="outlined"
                          onClick={() => setShowConfirmationKind('disable')}
                        >
                          {t('activeUsersPage.disableUsers', {
                            count: selectedUsers.length,
                          })}
                        </Button>
                      )}
                      {selectedUsers.every(
                        (it) => 'deletedAt' in it && !isNil(it.deletedAt),
                      ) && (
                        <Button variant="outlined" onClick={handleEnableUsers}>
                          {t('activeUsersPage.enableUsers', {
                            count: selectedUsers.length,
                          })}
                        </Button>
                      )}
                    </When>
                    {showDeleteAction && (
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => setShowConfirmationKind('delete')}
                      >
                        {t('activeUsersPage.deleteUsers', {
                          count: selectedUsers.length,
                        })}
                      </Button>
                    )}
                  </When>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={2}
                >
                  <Stack direction="row" spacing={1} alignItems="center">
                    <InputLabel>{t('common.table.rowsPerPage')}</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={pageSize.toString()}
                      onChange={handleChange}
                      size="small"
                    >
                      <MenuItem value={100}>100</MenuItem>
                      <MenuItem value={500}>500</MenuItem>
                      <MenuItem value={1000}>1000</MenuItem>
                    </Select>
                  </Stack>
                  <Pagination
                    showLastButton
                    variant="outlined"
                    disabled={isLoading || isNil(users) || isEmpty(users)}
                    count={Math.ceil(count / pageSize)}
                    key={page}
                    page={page + 1}
                    onChange={(
                      _event: React.ChangeEvent<unknown>,
                      value: number,
                    ) => setPage(value - 1)}
                    sx={{
                      '& .MuiPaginationItem-page': {
                        border: 'none',
                        backgroundColor: 'transparent',
                      },
                    }}
                  />
                </Stack>
              </Stack>

              <When condition={selectedUsers.length > 0}>
                <Alert
                  severity="info"
                  icon={false}
                  sx={{
                    '& .MuiAlert-message': {
                      width: '100%',
                    },
                  }}
                >
                  <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    width="100%"
                    justifyContent="center"
                  >
                    <span>
                      {t('activeUsersPage.selectedUsers', {
                        count: selectedUsers.length,
                      })}
                    </span>
                    <Button
                      color="inherit"
                      size="small"
                      onClick={resetSelectedState}
                    >
                      {t('activeUsersPage.clearSelection')}
                    </Button>
                  </Stack>
                </Alert>
              </When>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 300 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">
                        <Checkbox
                          checked={selectedUsers.length === users.length}
                          indeterminate={
                            selectedUsers.length > 0 &&
                            selectedUsers.length < users.length
                          }
                          onChange={toggleSelectAll}
                        ></Checkbox>
                      </TableCell>
                      <TableCell width={100}>
                        {t('activeUsersPage.name')}
                      </TableCell>
                      {showExternalId && (
                        <TableCell>
                          {t('user.identifier', {
                            ns: 'company',
                            defaultValue: t('activeUsersPage.externalId'),
                          })}
                        </TableCell>
                      )}
                      <TableCell>{t('activeUsersPage.email')}</TableCell>
                      {showCompanyColumn ? (
                        <TableCell>{t('activeUsersPage.company')}</TableCell>
                      ) : (
                        <></>
                      )}
                      <TableCell>{t('activeUsersPage.areas')}</TableCell>
                      {kind === 'user' && (
                        <>
                          <TableCell>
                            {t('activeUsersPage.activationDate')}
                          </TableCell>
                          <TableCell>
                            {t('activeUsersPage.loginCount')}
                          </TableCell>
                        </>
                      )}
                      {ExtraFieldsHeaders}
                      <TableCell> </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <When condition={isEmpty(users)}>
                      <TableRow>
                        <TableCell colSpan={8} align="center">
                          {t('common.noResults')}
                        </TableCell>
                      </TableRow>
                    </When>
                    {sortBy((it) => it.deletedAt ?? '', users ?? []).map(
                      (user) => (
                        <TableRow
                          key={user.id}
                          sx={isNil(user.deletedAt) ? {} : { opacity: 0.4 }}
                        >
                          <TableCell align="left">
                            <Checkbox
                              checked={selectedUsers.some(
                                (it) => user.id === it.id,
                              )}
                              onChange={(e) =>
                                toggleSelectedUsers(user, e.target.checked)
                              }
                            />
                          </TableCell>
                          <TableCell align="left" sx={{ minWidth: 200 }}>
                            {user.name || '-'}
                          </TableCell>
                          {showExternalId && (
                            <TableCell component="th" scope="row">
                              {user.externalId}
                            </TableCell>
                          )}
                          <TableCell align="left">
                            {user.email || '-'}
                          </TableCell>
                          {showCompanyColumn ? (
                            <TableCell align="left">
                              {user.company?.name || '-'}
                            </TableCell>
                          ) : (
                            <></>
                          )}
                          <TableCell align="left">
                            {areaString(user) ?? '-'}
                          </TableCell>
                          {kind === 'invitation' ? (
                            <></>
                          ) : (
                            <>
                              <TableCell align="left">
                                {format(
                                  new Date(user.createdAt || new Date()),
                                  'dd/MM/yyyy HH:mm',
                                )?.toString() || '-'}
                              </TableCell>
                              <TableCell align="left">
                                {user.loginCount}
                              </TableCell>
                            </>
                          )}
                          {ExtraFieldsColumns(user)}
                          <TableCell align="left">
                            <Fab
                              onClick={() => navigate(user.id.toString())}
                              color="secondary"
                              size="small"
                              sx={{ alignSelf: 'center' }}
                            >
                              <Edit />
                            </Fab>
                          </TableCell>
                        </TableRow>
                      ),
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <ConfirmationModal
                descriptionKey={`activeUsersPage.${showConfirmationKind}UserConfirmMessage`}
                open={!isNil(showConfirmationKind)}
                onAccept={bulkActions[showConfirmationKind ?? 'disable']}
                onCancel={() => setShowConfirmationKind(undefined)}
                isSubmitting={
                  deleteInvitationsResult.isLoading ||
                  deleteUsersResult.isLoading ||
                  updateUsersResult.isLoading
                }
                isDelete={showConfirmationKind === 'delete'}
              />
            </>
          </Else>
        </If>
      </Else>
    </If>
  );
}
