import { IsNotEmpty, IsOptional } from 'class-validator';

export class CreateNewsDtoValidator {
  constructor() {
    this.title = '';
    this.description = '';
    this.imageUrl = '';
  }

  @IsNotEmpty({ message: 'validations.title.empty' })
  title: string;

  @IsNotEmpty({ message: 'validations.description.empty' })
  description: string;

  @IsOptional()
  link?: string;

  @IsNotEmpty({ message: 'validations.news.imageUrl.empty' })
  imageUrl: string;
}
