import {
  Accordion,
  AccordionProps,
  Card,
  CardProps,
  styled,
} from '@mui/material';

export const RoundedCard = styled(Card)<CardProps>(({ theme }) => ({
  borderRadius: theme.shape.borderRadius * 4,
}));

export const RoundedAccordion = styled(Accordion)<AccordionProps>(
  ({ theme }) => ({
    borderRadius: theme.shape.borderRadius * 4,
  }),
);
