import {
  Avatar,
  Checkbox,
  Grid,
  Typography,
  Stack,
  TextField,
  MenuItem,
  Select,
  IconButton,
  FormControl,
  CircularProgress,
  FormControlLabel,
  useTheme,
  alpha,
} from '@mui/material';
import { ApiSportsFixtureStatus, useI18nHelpers } from '@futbolprode/ui-common';
import {
  Match,
  MatchResultBody,
  useTournamentImporterControllerImportMatchResultManualMutation,
  useTournamentImporterControllerImportMatchResultMutation,
} from '../../app/services/futbolProdeApi';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  MatchStatusChip,
  isInStatus,
  MatchPlayingStatuses,
} from '@futbolprode/ui-common';
import { useTranslation } from 'react-i18next';
import { Else, If, Then, When } from 'react-if';
import { Edit, Done, Close } from '@mui/icons-material';
import LoadingIconButton from '../../common/LoadingIconButton';
import SyncIcon from '@mui/icons-material/Sync';
import { isNil } from 'ramda';

interface MatchDetailsProps {
  match: Match;
  key: number;
}
const FORCEABLE_STATUS = ['TBD', 'NS', 'HT', 'FT', 'CANC'];

function EditGoalsField({
  value,
  onChange,
}: {
  value?: number;
  onChange: (value: number) => void;
}) {
  const removeArrowStyle = `
  .editGoalsField::-webkit-inner-spin-button,
  .editGoalsField::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }`;
  return (
    <>
      <style>{removeArrowStyle}</style>
      <TextField
        value={value}
        size="small"
        type="number"
        variant="outlined"
        sx={{ width: 50 }}
        inputProps={{
          className: 'editGoalsField',
          style: { textAlign: 'center' },
        }}
        onChange={(e) => onChange(parseInt(e.target.value))}
      />
    </>
  );
}

export default function MatchDetails({ match, key }: MatchDetailsProps) {
  const theme = useTheme();
  const {
    id,
    awayTeam,
    homeTeam,
    date,
    homeTeamGoals,
    awayTeamGoals,
    externalId,
    status,
    hasPenalties,
    isTwoWaysSecondMatch,
    suspendedDate,
    wasRescheduled,
  } = match;

  const wasSuspended = !isNil(suspendedDate);

  const { t } = useTranslation();
  const { formatDate } = useI18nHelpers();

  const [isEditingResult, setIsEditingResult] = useState(false);
  const [editedMatchResult, setEditedMatchResult] = useState({
    homeTeamGoals,
    awayTeamGoals,
    status,
    hasPenalties,
    isTwoWaysSecondMatch,
    wasRescheduled,
  });
  const [updateMatchResult, { isLoading: isUpdating }] =
    useTournamentImporterControllerImportMatchResultManualMutation();

  const [reimport, { isLoading: isReimporting }] =
    useTournamentImporterControllerImportMatchResultMutation();

  const resetEditedMatch = useCallback(() => {
    setEditedMatchResult({
      homeTeamGoals,
      awayTeamGoals,
      status,
      hasPenalties,
      isTwoWaysSecondMatch,
      wasRescheduled,
    });
  }, [
    homeTeamGoals,
    awayTeamGoals,
    status,
    hasPenalties,
    isTwoWaysSecondMatch,
    wasRescheduled,
  ]);

  useEffect(() => {
    resetEditedMatch();
  }, [resetEditedMatch]);

  const isMatchPlaying = useMemo(
    () => isInStatus(MatchPlayingStatuses, match.status),
    [match],
  );

  const matchTouched = useMemo(
    () =>
      homeTeamGoals !== editedMatchResult.homeTeamGoals ||
      awayTeamGoals !== editedMatchResult.awayTeamGoals ||
      status !== editedMatchResult.status ||
      hasPenalties !== editedMatchResult.hasPenalties ||
      wasRescheduled !== editedMatchResult.wasRescheduled ||
      isTwoWaysSecondMatch !== editedMatchResult.isTwoWaysSecondMatch,
    [
      awayTeamGoals,
      editedMatchResult.awayTeamGoals,
      editedMatchResult.homeTeamGoals,
      editedMatchResult.status,
      editedMatchResult.hasPenalties,
      editedMatchResult.isTwoWaysSecondMatch,
      editedMatchResult.wasRescheduled,
      homeTeamGoals,
      status,
      hasPenalties,
      isTwoWaysSecondMatch,
      wasRescheduled,
    ],
  );

  const handleSave = async () => {
    await updateMatchResult({
      matchId: id.toString(),
      matchResultBody: {
        homeTeamGoals: editedMatchResult.homeTeamGoals!,
        awayTeamGoals: editedMatchResult.awayTeamGoals!,
        status: editedMatchResult.status as ApiSportsFixtureStatus,
        hasPenalties: editedMatchResult.hasPenalties,
        wasRescheduled: editedMatchResult.wasRescheduled,
        isTwoWaysSecondMatch: editedMatchResult.isTwoWaysSecondMatch!,
      },
    }).unwrap();
    setIsEditingResult(false);
  };

  const partialSetResult = (partial: Partial<MatchResultBody>) =>
    setEditedMatchResult({
      ...editedMatchResult,
      ...partial,
    });

  const suspendedColor = alpha(theme.palette.warning.main, 0.2);

  return (
    <Grid
      container
      spacing={1}
      key={key}
      alignItems="center"
      bgcolor={wasSuspended ? suspendedColor : undefined}
    >
      <Grid item xs={12} md={3}>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="space-around"
        >
          <Avatar src={homeTeam.logoUrl} alt="Home Team Logo" />
          <If condition={isEditingResult}>
            <Then>
              <EditGoalsField
                value={editedMatchResult.homeTeamGoals}
                onChange={(homeTeamGoals) =>
                  partialSetResult({ homeTeamGoals })
                }
              />
              -
              <EditGoalsField
                value={editedMatchResult.awayTeamGoals}
                onChange={(awayTeamGoals) =>
                  partialSetResult({ awayTeamGoals })
                }
              />
            </Then>
            <Else>
              <Typography color={isMatchPlaying ? 'success.light' : 'inherit'}>
                {homeTeamGoals} - {awayTeamGoals}
              </Typography>
            </Else>
          </If>
          <Avatar src={awayTeam.logoUrl} alt="Away Team Logo" />
        </Stack>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography>
          {homeTeam.name} VS {awayTeam.name}{' '}
          <Typography color="textSecondary" variant="caption">
            #{externalId}
          </Typography>
          <When condition={wasSuspended}>
            <Typography color="error" variant="caption">
              {' '}
              {t('match.suspendedAt', {
                date: formatDate('Pp', new Date(suspendedDate!)),
              })}
            </Typography>
          </When>
        </Typography>
        <Typography
          color={wasRescheduled ? 'error' : 'textSecondary'}
          fontWeight={400}
        >
          {status === 'TBD'
            ? t('match.dateToBeConfirmed')
            : formatDate('PPPPp', new Date(date))}
          <When condition={wasRescheduled}>
            <Typography variant="caption">
              {' '}
              ({t('match.wasRescheduled')})
            </Typography>
          </When>
        </Typography>

        <If condition={isEditingResult}>
          <Then>
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    id={'hasPenalties'}
                    checked={editedMatchResult.hasPenalties}
                    onChange={(e) =>
                      partialSetResult({
                        hasPenalties: e.target.checked,
                      })
                    }
                    name={'hasPenalties'}
                  />
                }
                label={t('match.hasPenalties')}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    id={'isTwoWaysSecondMatch'}
                    checked={editedMatchResult.isTwoWaysSecondMatch}
                    onChange={(e) =>
                      partialSetResult({
                        isTwoWaysSecondMatch: e.target.checked,
                      })
                    }
                    name={'isTwoWaysSecondMatch'}
                  />
                }
                label={t('match.isTwoWaysSecondMatch')}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    id={'wasRescheduled'}
                    checked={editedMatchResult.wasRescheduled}
                    onChange={(e) =>
                      partialSetResult({
                        wasRescheduled: e.target.checked,
                      })
                    }
                    name={'wasRescheduled'}
                  />
                }
                label={t('match.wasRescheduled')}
              />
            </>
          </Then>
          <Else>
            <Typography color="textSecondary" fontWeight={400}>
              {isTwoWaysSecondMatch
                ? t('match.isTwoWaysSecondMatch')
                : hasPenalties
                ? t('match.hasPenalties')
                : ''}
            </Typography>
          </Else>
        </If>
      </Grid>
      <Grid item xs={12} md={2}>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent={{ xs: 'flex-end' }}
        >
          <If condition={isEditingResult}>
            <Then>
              <FormControl variant="standard" size="small">
                <Select
                  name="loginStrategy"
                  id="login-strategy-select"
                  value={editedMatchResult.status}
                  label={t('companiesDashboard.loginStrategy')}
                  fullWidth
                  onChange={(e) =>
                    partialSetResult({
                      status: e.target.value as ApiSportsFixtureStatus,
                    })
                  }
                >
                  {FORCEABLE_STATUS.map((aStatus) => (
                    <MenuItem key={aStatus} value={aStatus}>
                      {t(`match.statusSummary.${aStatus}`)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <IconButton
                color="success"
                onClick={handleSave}
                disabled={!matchTouched || isUpdating}
              >
                {isUpdating ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  <Done />
                )}
              </IconButton>
              <IconButton
                color="error"
                onClick={() => {
                  setIsEditingResult(false);
                  resetEditedMatch();
                }}
              >
                <Close />
              </IconButton>
            </Then>
            <Else>
              <MatchStatusChip match={match} />
              <IconButton
                color="primary"
                onClick={() => setIsEditingResult(true)}
              >
                <Edit />
              </IconButton>
              <LoadingIconButton
                color="success"
                onClick={() => reimport(match.id.toString())}
                loading={isReimporting}
              >
                <SyncIcon />
              </LoadingIconButton>
            </Else>
          </If>
        </Stack>
      </Grid>
    </Grid>
  );
}
