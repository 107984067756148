import Page from '../../common/Page';
import {
  useDomainsControllerAcmCurrentCertificateQuery,
  useDomainsControllerCloudFlareStatusQuery,
  useDomainsControllerCloudfrontDistributionsQuery,
  useDomainsControllerConfigureMissingDomainsMutation,
  useDomainsControllerCreateMissingSesIdentitiesMutation,
  useDomainsControllerMigrateToCloudFlareMutation,
  useDomainsControllerMissingDomainsQuery,
  useDomainsControllerSesIdentitiesQuery,
  useDomainsControllerSyncCloudfrontDistributionsMutation,
} from '../../app/services/futbolProdeApi';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  Paper,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {
  Check,
  Close,
  CloudCircle,
  HourglassBottom,
  LockOutlined,
  MailOutline,
  Public,
  Refresh,
  Warning,
} from '@mui/icons-material';
import { format } from 'date-fns';
import { all, any, isEmpty } from 'ramda';
import { PageSection } from '../../common/PageSection';
import { useState } from 'react';

export default function DomainsDashboard() {
  const { data: certificateResponse } =
    useDomainsControllerAcmCurrentCertificateQuery();
  const { data: sesIdentitiesResponse } =
    useDomainsControllerSesIdentitiesQuery();

  const { data: cloudfrontDistributionsResponse } =
    useDomainsControllerCloudfrontDistributionsQuery();

  const { data: missingDomainsResponse } =
    useDomainsControllerMissingDomainsQuery();

  const { data: cloudFlareStatusResponse } =
    useDomainsControllerCloudFlareStatusQuery();

  const [configureMissingDomains, { error, isSuccess }] =
    useDomainsControllerConfigureMissingDomainsMutation();

  const [syncCloudfrontDistributions, { error: cloudfrontError }] =
    useDomainsControllerSyncCloudfrontDistributionsMutation();

  const [createMissingSesIdentites] =
    useDomainsControllerCreateMissingSesIdentitiesMutation();

  const [migrateToCloudFlare] =
    useDomainsControllerMigrateToCloudFlareMutation();

  const certificate = certificateResponse as any;
  const sesIdentities = (sesIdentitiesResponse as any) || [];
  const cloudfrontDistributions =
    (cloudfrontDistributionsResponse as any) || [];
  const missingDomains = (missingDomainsResponse as any) || [];
  const cloudFlareStatus = (cloudFlareStatusResponse as any) || [];
  const hasMissingDomains = !isEmpty(missingDomains);
  if (!certificate) return <>Loading...</>;
  const certificateStatusIcons: any = {
    ISSUED: <Check color="primary" />,
    PENDING_VALIDATION: <Warning color="primary" />,
  };
  const distributionByPrefix = (prefix: string) =>
    cloudfrontDistributions.find((it: any) =>
      any((it: any) => it.includes(prefix), it.Aliases?.Items),
    );
  const gameDistribution = distributionByPrefix('juego');
  const adminDistribution = distributionByPrefix('admin');
  const certificateIsPending = certificate.Status === 'PENDING_VALIDATION';
  const onConfigureDomainsClick = () => {
    if (
      window.confirm(
        `¿Deseas configurar los siguientes dominios?\n${missingDomains.join(
          ', ',
        )}`,
      )
    ) {
      configureMissingDomains();
    }
  };
  const onSyncCloudfrontDistributionsClick = () => {
    if (
      window.confirm(`¿Deseas sincronizar los sitios con el nuevo certificado?`)
    ) {
      syncCloudfrontDistributions({
        certificateARN: certificate.CertificateArn,
      });
    }
  };
  const onCreateMissingSesIdentitiesClick = () => {
    if (
      window.confirm(
        `¿Deseas configurar los mails para los dominios faltantes?`,
      )
    ) {
      createMissingSesIdentites();
    }
  };

  const onMigrateToCloudFlareClick = (domain: string) => {
    if (window.confirm(`¿Deseas migrar ${domain} a CloudFlare?`)) {
      migrateToCloudFlare({ name: domain });
    }
  };

  const distributionIsUpdated = (cloudfrontDistribution: any) =>
    cloudfrontDistribution.ViewerCertificate?.Certificate ===
    certificate.CertificateArn;
  const distributionIsDeployed = (cloudfrontDistribution: any) =>
    cloudfrontDistribution.Status === 'Deployed';

  const allDistributionsAreUpdated = all(
    distributionIsUpdated,
    cloudfrontDistributions,
  );
  const allDistributionsAreDeployed = all(
    distributionIsDeployed,
    cloudfrontDistributions,
  );

  return (
    <Page
      breadcrumbs={[{ label: 'sidebar.globalSettings' }]}
      title="sidebar.domains"
    >
      <PageSection title="domains.certificate">
        <Paper>
          <Box p={2}>
            <List>
              <ListItem>
                <Typography>
                  <strong>Certificado:</strong> {certificate.CertificateArn}
                </Typography>
              </ListItem>
              <ListItem>
                <Typography>
                  <strong>Fecha creación: </strong>
                  {format(new Date(certificate.CreatedAt), 'dd/MM/yyyy HH:mm')}
                </Typography>
              </ListItem>
              <ListItem>
                <Typography>
                  <strong>Status: </strong>
                  {certificate.Status}
                </Typography>
                {certificateStatusIcons[certificate.Status]}
              </ListItem>
            </List>
          </Box>
        </Paper>
      </PageSection>

      <PageSection title="domains.domains">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 300 }}>
            <TableHead>
              <TableRow>
                <TableCell>Dominio</TableCell>
                <TableCell>
                  <LockOutlined /> ACM
                </TableCell>
                <TableCell>
                  <MailOutline /> SES
                  <IconButton onClick={onCreateMissingSesIdentitiesClick}>
                    <Refresh />
                  </IconButton>
                </TableCell>
                <TableCell>
                  <Public />
                  Juego
                </TableCell>
                <TableCell>
                  <Public /> Admin
                </TableCell>
                <TableCell>
                  <CloudCircle /> CloudFlare
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {certificate?.SubjectAlternativeNameSummaries?.map(
                (wildcardDomain: string) => {
                  const cleanDomain = wildcardDomain.replace('*.', '');
                  const sesIdentity = sesIdentities.find(
                    (identity: any) => identity.domain === cleanDomain,
                  );
                  const sesVerified = sesIdentity?.status === 'Success';

                  const distributionIsConfigured = (
                    distribution: any,
                    prefix: string,
                  ) =>
                    distribution?.Aliases?.Items?.includes(
                      `${prefix}.${cleanDomain}`,
                    );
                  const gameIsConfigured = distributionIsConfigured(
                    gameDistribution,
                    'juego',
                  );
                  const adminIsConfigured = distributionIsConfigured(
                    adminDistribution,
                    'admin',
                  );

                  const acmChallenge =
                    certificate.certificateDescription?.Certificate?.DomainValidationOptions?.find(
                      (it: any) => it.DomainName === wildcardDomain,
                    );
                  const acmVerified =
                    acmChallenge?.ValidationStatus === 'SUCCESS';
                  const isUsingCloudFlareNameServers = cloudFlareStatus.find(
                    (it: any) => it.name === cleanDomain,
                  )?.isUsingCloudFlareNameServers;
                  return (
                    <TableRow key={wildcardDomain}>
                      <TableCell>{wildcardDomain}</TableCell>
                      <TableCell>
                        <DNSChallengePopover
                          icon={acmVerified ? <Check /> : <Warning />}
                          records={[
                            {
                              name: acmChallenge?.ResourceRecord?.Name.replace(
                                `.${cleanDomain}.`,
                                '',
                              ),
                              value: acmChallenge?.ResourceRecord?.Value,
                            },
                          ]}
                        />
                      </TableCell>
                      <TableCell>
                        {sesIdentity ? (
                          <DNSChallengePopover
                            icon={sesVerified ? <Check /> : <Warning />}
                            records={
                              sesIdentity.records?.map((it: any) => ({
                                ...it,
                                value: it.data,
                              })) || []
                            }
                          />
                        ) : (
                          <Close />
                        )}
                      </TableCell>
                      <TableCell>
                        <DNSChallengePopover
                          icon={gameIsConfigured ? <Check /> : <Warning />}
                          records={[
                            {
                              name: 'juego',
                              value: gameDistribution?.DomainName,
                            },
                          ]}
                        />
                      </TableCell>
                      <TableCell>
                        <DNSChallengePopover
                          icon={adminIsConfigured ? <Check /> : <Warning />}
                          records={[
                            {
                              name: 'admin',
                              value: adminDistribution?.DomainName,
                            },
                          ]}
                        />
                      </TableCell>
                      <TableCell>
                        {isUsingCloudFlareNameServers ||
                        cleanDomain === 'futbolprode.com' ? (
                          <Check />
                        ) : acmVerified &&
                          sesVerified &&
                          gameIsConfigured &&
                          adminIsConfigured ? (
                          <IconButton
                            onClick={() =>
                              onMigrateToCloudFlareClick(cleanDomain)
                            }
                          >
                            <Refresh />
                          </IconButton>
                        ) : (
                          <DNSChallengePopover
                            icon={<Warning />}
                            records={[]}
                            customContent={
                              <Typography p={2}>
                                Para porder migrar el dominio a CloudFlare,
                                primero hay que esperar que las configuraciones
                                de ACM, SES, Juego y Admin terminen exitosamente
                              </Typography>
                            }
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  );
                },
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </PageSection>
      <PageSection title="domains.sites">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 300 }}>
            <TableHead>
              <TableRow>
                <TableCell>Nombre</TableCell>
                <TableCell>Id</TableCell>
                <TableCell>Certificado</TableCell>
                <TableCell>Estado</TableCell>
                <TableCell>
                  <IconButton
                    onClick={onSyncCloudfrontDistributionsClick}
                    disabled={
                      allDistributionsAreUpdated || allDistributionsAreDeployed
                    }
                  >
                    <Refresh />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cloudfrontDistributions?.map((cloudfrontDistribution: any) => {
                return (
                  <TableRow key={cloudfrontDistribution.Id}>
                    <TableCell>{cloudfrontDistribution.Comment}</TableCell>
                    <TableCell>{cloudfrontDistribution.Id}</TableCell>
                    <TableCell>
                      {cloudfrontDistribution.ViewerCertificate?.Certificate}
                    </TableCell>
                    <TableCell>{cloudfrontDistribution.Status}</TableCell>
                    <TableCell>
                      {distributionIsUpdated(cloudfrontDistribution) ? (
                        <Check />
                      ) : (
                        <Warning />
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </PageSection>

      <>
        {!!cloudfrontError && (
          <Alert severity="error" variant="outlined" sx={{ mt: 1 }}>
            <AlertTitle>
              {(cloudfrontError as any)?.data?.message || JSON.stringify(error)}
            </AlertTitle>
          </Alert>
        )}
      </>

      <PageSection title="domains.missingDomains">
        <Paper>
          <Box p={2}>
            <Box display="flex" flexDirection="row" justifyContent="end">
              <Box>
                <Button
                  variant="contained"
                  onClick={onConfigureDomainsClick}
                  disabled={!hasMissingDomains || certificateIsPending}
                >
                  Configurar
                </Button>
              </Box>
            </Box>
            {certificateIsPending && (
              <Box display="flex" flexDirection="row">
                <Box>
                  <HourglassBottom />
                </Box>
                <Box>
                  <Typography>
                    Configurando dominios. Este proceso puede demorar varios
                    minutos.
                  </Typography>
                </Box>
              </Box>
            )}
            {isSuccess && (
              <Alert severity="info" variant="outlined">
                <AlertTitle>
                  Tus nuevos dominios van a estar listos en unos minutos
                </AlertTitle>
              </Alert>
            )}

            {error && (
              <Alert severity="error" variant="outlined" sx={{ mt: 1 }}>
                <AlertTitle>
                  {(error as any)?.data?.message || JSON.stringify(error)}
                </AlertTitle>
              </Alert>
            )}
            {!hasMissingDomains ? (
              <Typography> No hay dominios sin configurar</Typography>
            ) : (
              <Table>
                <TableBody>
                  {missingDomains.map((missingDomain: string) => (
                    <TableRow key={missingDomain}>
                      <TableCell>{missingDomain}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </Box>
        </Paper>
      </PageSection>
    </Page>
  );
}
function DNSChallengePopover({
  records,
  icon,
  customContent,
}: {
  records: { name: string; value: string }[];
  icon?: JSX.Element;
  customContent?: JSX.Element;
}) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = !!anchorEl;
  return (
    <>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {customContent || (
          <Box>
            <Typography variant="h5" sx={{ p: 2 }}>
              CNAME challenge
            </Typography>
            <Box>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>CNAME</TableCell>
                    <TableCell>Value</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {records.map((record) => (
                    <TableRow key={record.name}>
                      <TableCell>{record.name}</TableCell>
                      <TableCell>{record.value}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Box>
        )}
      </Popover>
      <IconButton onClick={handleClick}>{icon || <Warning />}</IconButton>
    </>
  );
}
