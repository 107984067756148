import { Divider, Stack, Typography } from '@mui/material';
import { PageSection } from '../../common/PageSection';
import { useTranslation } from 'react-i18next';
import { Input } from '../../common/form/Input';
import { AnnouncementsDto } from './AnnouncementsPage';
import AnnouncementInput from './AnnouncementInput';
import { FormikHandlers, FormikHelpers } from 'formik';

const AnnouncementTitle = ({
  titleKey,
  subtitleKey,
}: {
  titleKey: string;
  subtitleKey: string;
}) => {
  const { t } = useTranslation();
  return (
    <Typography variant="h6">
      {t(titleKey)}
      <Typography variant="subtitle1" color="text.secondary">
        {t(subtitleKey)}
      </Typography>
    </Typography>
  );
};

export default function AnnouncementsForm({
  locale,
  handleChange,
  values,
  setFieldValue,
}: {
  locale: string;
  handleChange: FormikHandlers['handleChange'];
  values: AnnouncementsDto;
  setFieldValue: FormikHelpers<any>['setFieldValue'];
}) {
  return (
    <Stack spacing={2}>
      <PageSection title="appearanceDashboard.loginCustomization">
        <AnnouncementTitle
          titleKey="companiesDashboard.loginText"
          subtitleKey="announcementsPage.loginWelcomeMessageHelper"
        />
        <Input
          value={values.translations[locale].customization.loginWelcomeMessage}
          name={`translations.${locale}.customization.loginWelcomeMessage`}
          label={''}
          onChange={handleChange}
        />
        <Divider />
        <AnnouncementTitle
          titleKey="companiesDashboard.loginText"
          subtitleKey="announcementsPage.loginAnnouncementHelper"
        />
        <AnnouncementInput
          handleChange={handleChange}
          locale={locale}
          name="login"
          values={values}
          setFieldValue={setFieldValue}
        />
      </PageSection>
      <PageSection title="announcementsPage.mainPage">
        <AnnouncementTitle
          titleKey="announcementsPage.announcement"
          subtitleKey="announcementsPage.mainPageAnnouncementHelper"
        />
        <AnnouncementInput
          handleChange={handleChange}
          locale={locale}
          name="mainPage"
          values={values}
          setFieldValue={setFieldValue}
        />
      </PageSection>
    </Stack>
  );
}
