import { useTheme } from '@mui/material';
import { useMemo } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'highlight.js/styles/github.css';
import { isNilOrEmpty } from './helpers/ramdaExtension';

export const QuillFormats = [
  'background',
  'bold',
  'color',
  'font',
  'code',
  'italic',
  'link',
  'size',
  'strike',
  'script',
  'underline',
  'blockquote',
  'header',
  'indent',
  'list',
  'align',
  'direction',
  'code-block',
  'image',
  'video',
];

export type QuillFormat = (typeof QuillFormats)[number];

export interface HtmlEditorProps {
  value: string;
  setValue: (value: string) => void;
  allowedFormats?: QuillFormat[];
  customToolbar?: any;
}

// Bug conocido, el editor nunca se borra completamente.
// https://github.com/zenoamaro/react-quill/issues/871
const isQuillEmptyValue = (value: string) => value === '<p><br></p>';

const toolbarOptions = {
  items: [
    [{ header: [1, 2, 3, 4, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link'],
    ['clean'],
  ],
  handlers: {
    link: function (this: { quill: any }, value: string) {
      if (value) {
        const href = prompt('Ingresa la URL');
        if (isNilOrEmpty(href)) {
          return;
        }
        const withProtocol = href?.startsWith('http')
          ? href
          : `https://${href}`;
        this.quill.format('link', withProtocol);
      } else {
        this.quill.format('link', false);
      }
    },
  },
};

export default function HtmlEditor({
  value,
  setValue,
  allowedFormats,
  customToolbar,
}: HtmlEditorProps) {
  const theme = useTheme();

  // Ver toolbar default en https://github.com/zenoamaro/react-quill#default-toolbar-elements
  const modules = useMemo(() => {
    return {
      toolbar: {
        ...toolbarOptions,
        items: customToolbar ?? toolbarOptions.items,
      },
    };
  }, [customToolbar]);

  const editorStyles = useMemo(
    () => `
  #editor-container {
    height: 300px;
    margin-bottom: 3rem;
  }
          
  .ql-container {
    font-size: ${theme.typography.body2.fontSize};
    font-family: ${theme.typography.fontFamily}
  `,
    [theme],
  );

  const handleChange = (value: string) => {
    setValue(isQuillEmptyValue(value) ? '' : value);
  };

  return (
    <>
      <style>{editorStyles}</style>
      <ReactQuill
        id="editor-container"
        theme="snow"
        value={value}
        modules={modules}
        formats={allowedFormats}
        onChange={handleChange}
      />
    </>
  );
}
