import { LinearProgress } from '@mui/material';
import {
  useCompaniesControllerFindMeQuery,
  useCompaniesControllerFindOneQuery,
  useCompaniesControllerRemoveMutation,
} from '../../app/services/futbolProdeApi';
import Page from '../../common/Page';
import { useNavigate, useParams } from 'react-router-dom';
import { CompanyForm } from './CompanyForm';
import DeleteIcon from '@mui/icons-material/Delete';
import FloatingButton from '../../common/FloatingButton';
import { useTranslation } from 'react-i18next';
import { Routes, useCompanyRoutes } from '../../router';
import { If, Then, Else } from 'react-if';
import { useUserPermissions } from '../../common/hooks/useUserPermissions';

export function CompanyEdit({ isReseller = false }: { isReseller?: boolean }) {
  const { id } = useParams();
  const { data, isLoading: companyIsLoading } =
    useCompaniesControllerFindOneQuery(id!);
  const { data: me, isLoading: meIsLoading } =
    useCompaniesControllerFindMeQuery();
  const [removeCompany] = useCompaniesControllerRemoveMutation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { appRoute } = useCompanyRoutes();
  const isLoading = companyIsLoading || meIsLoading;
  const handleRemove = async () => {
    const confirmMessage = t('companiesDashboard.confirmDelete', {
      name: data?.name,
    });
    if (window.confirm(confirmMessage)) {
      await removeCompany(id!).unwrap();
      navigate(appRoute(isReseller ? `resellers` : `companies`));
    }
  };
  const { isAdmin } = useUserPermissions();
  return (
    <Page
      breadcrumbs={[
        { label: 'sidebar.globalSettings' },
        isReseller
          ? { label: 'sidebar.resellers', url: appRoute(Routes.RESELLERS) }
          : { label: 'sidebar.companies', url: appRoute(Routes.COMPANIES) },
      ]}
      title={data?.name}
      actions={
        !isAdmin || isLoading || me?.id === data?.id ? (
          <></>
        ) : (
          <FloatingButton color="primary" onClick={() => handleRemove()}>
            <DeleteIcon />
          </FloatingButton>
        )
      }
    >
      <If condition={companyIsLoading}>
        <Then>
          <LinearProgress />
        </Then>
        <Else>
          <CompanyForm company={data} isReseller={data?.isReseller} />
        </Else>
      </If>
    </Page>
  );
}
