import { Box, Container } from '@mui/material';
import { Outlet } from 'react-router-dom';
import SideBar from '../common/SideBar';

function AppLayout() {
  return (
    <>
      <SideBar>
        <Container maxWidth="lg">
          <Box my={4}>
            <Outlet />
          </Box>
        </Container>
      </SideBar>
    </>
  );
}

export default AppLayout;
