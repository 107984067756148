import Page from '../../common/Page';
import { UserForm } from './UserForm';
import { useSearchParams } from 'react-router-dom';

export default function UserEditPage() {
  const [queryParams] = useSearchParams();
  const isSelf = queryParams.get('isSelf') === 'true';

  return (
    <Page
      breadcrumbs={[{ label: 'sidebar.users' }]}
      title={isSelf ? 'usersForm.editProfile' : 'usersForm.editUsers'}
    >
      <UserForm kind="user" isSelf={isSelf} />
    </Page>
  );
}
