import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Grid,
  Skeleton,
  Typography,
} from '@mui/material';
import {
  News,
  useNewsControllerRemoveMutation,
} from '../../app/services/futbolProdeApi';
import { stripHtml } from '../../common/helpers/htmlHelpers';
import SkeletonOr from '../../common/SkeletonOr';
import { useTranslation } from 'react-i18next';
import { When } from 'react-if';
import PushPinIcon from '@mui/icons-material/PushPin';

export default function NewsCard({
  isLoading,
  news,
}: {
  isLoading: boolean;
  news: News | undefined;
}) {
  const { t } = useTranslation();
  const [removeNews] = useNewsControllerRemoveMutation();
  const handleDelete = () => {
    const confirmMessage = t('customNews.confirmDelete', {
      title: news?.title,
    });
    if (window.confirm(confirmMessage)) {
      removeNews(news!.id.toString());
    }
  };

  return (
    <Card>
      {isLoading ? (
        <Skeleton sx={{ height: 200 }} />
      ) : (
        <CardMedia
          sx={{ height: 200, backgroundPosition: 'top' }}
          image={news?.imageUrl}
          title={news?.title}
        />
      )}
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          <SkeletonOr isLoading={isLoading} value={news?.title} />
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <SkeletonOr
            isLoading={isLoading}
            height={150}
            value={stripHtml(news?.description).substring(0, 150)}
          />
        </Typography>
      </CardContent>
      <CardActions>
        <Button href={news?.id?.toString()} disabled={isLoading}>
          {t('common.edit')}
        </Button>{' '}
        <Button color="error" onClick={handleDelete} disabled={isLoading}>
          {t('common.delete')}
        </Button>
        <When condition={!isLoading && news?.fixed}>
          <Grid container justifyContent="flex-end">
            <Chip
              icon={<PushPinIcon />}
              color="info"
              variant="outlined"
              label={t('customNews.fixed')}
              sx={{ borderRadius: 16 }}
            />
          </Grid>
        </When>
      </CardActions>
    </Card>
  );
}
