import { Autocomplete, InputLabel, Stack, TextField } from '@mui/material';
import {
  CombinedRoundsRankingDefinition,
  Round,
  Tournament,
  useCombinedRoundsRankingDefinitionsControllerCreateMutation,
  useCombinedRoundsRankingDefinitionsControllerUpdateMutation,
  useTournamentsControllerFindAllByCompanyQuery,
  useTournamentsControllerFindTournamentRoundsQuery,
} from '../../../app/services/futbolProdeApi';
import { useTranslation } from 'react-i18next';
import TournamentSelect from '../TournamentSelect';
import { ChangeEvent, useMemo, useState } from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { eqProps } from 'ramda';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { Routes, useCompanyRoutes } from '../../../router';
import { useCompanyTranslations } from '@futbolprode/ui-common';

interface CombinedRoundsFormProps {
  combinedRound?: CombinedRoundsRankingDefinition;
}

export function CombinedRoundsForm({ combinedRound }: CombinedRoundsFormProps) {
  const { t } = useTranslation();
  const { roundName } = useCompanyTranslations();
  const navigate = useNavigate();
  const { appRoute } = useCompanyRoutes();
  const [createCombinedRounds, createResult] =
    useCombinedRoundsRankingDefinitionsControllerCreateMutation();
  const [updateCombinedRounds, updateResult] =
    useCombinedRoundsRankingDefinitionsControllerUpdateMutation();

  const { data: tournamentsData } =
    useTournamentsControllerFindAllByCompanyQuery();

  const [chosenName, setChosenName] = useState(combinedRound?.name ?? '');

  const tournaments = tournamentsData ?? [];
  const [chosenTournament, setChosenTournament] = useState<Tournament | null>(
    null,
  );

  const { data: roundsData } =
    useTournamentsControllerFindTournamentRoundsQuery(
      chosenTournament ? chosenTournament.id.toString() : skipToken,
    );
  const rounds = useMemo(() => roundsData?.data, [roundsData]);
  const [chosenRounds, setChosenRounds] = useState(combinedRound?.rounds ?? []);

  const upsertCombinedRound = async () => {
    const bodyToUpsert: any = {
      name: chosenName,
      rounds: chosenRounds.map((it: Round) => ({ id: it.id })),
    };
    if (!combinedRound) {
      await createCombinedRounds(bodyToUpsert).unwrap();
    } else {
      await updateCombinedRounds({
        id: combinedRound!.id.toString(),
        updateCombinedRoundsRankingDefinitionDto: bodyToUpsert,
      }).unwrap();
    }
    navigate(appRoute(Routes.COMBINED_ROUNDS));
  };

  return (
    <Stack spacing={2}>
      <TextField
        label={t('combinedRoundsDashboard.name')}
        variant="outlined"
        value={chosenName}
        onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
          setChosenName(e.target.value)
        }
      />
      <>
        <TournamentSelect
          tournaments={tournaments}
          onValueUpdated={(value) => setChosenTournament(value)}
          initialValue={chosenTournament ?? undefined}
          placeholderLabel={t('combinedRoundsDashboard.chooseTournament')}
        />
        <>
          <InputLabel htmlFor={'rounds-autocomplete'}>
            {t('combinedRoundsDashboard.rounds')}
          </InputLabel>

          <Autocomplete
            id="rounds-autocomplete"
            multiple
            isOptionEqualToValue={eqProps('id')}
            options={(rounds ?? []).filter(
              (round) => !chosenRounds.some((it) => it.id === round.id),
            )}
            getOptionLabel={(round) => roundName(round)!}
            value={chosenRounds as any}
            onChange={(_eventa, values) => {
              setChosenRounds(values as any);
            }}
            renderInput={(params) => (
              <TextField {...params} variant="standard" />
            )}
          />
        </>
      </>
      <LoadingButton
        loading={createResult.isLoading || updateResult.isLoading}
        fullWidth
        onClick={() => upsertCombinedRound()}
        variant="contained"
      >
        {t('save')}
      </LoadingButton>
    </Stack>
  );
}
