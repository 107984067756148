import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Fab,
} from '@mui/material';
import {
  Company,
  Invitation,
  useInvitationsControllerRemoveMutation,
  User,
  useUsersControllerRemoveMutation,
} from '../../app/services/futbolProdeApi';
import { t } from 'i18next';
import { Delete, Edit } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useCompanyRoutes } from '../../router';

export function ManagersTable({
  company,
  pendingManagers,
}: {
  company: Company | undefined;
  pendingManagers: Invitation[] | undefined;
}) {
  const [deleteUser] = useUsersControllerRemoveMutation();
  const [deleteInvitation] = useInvitationsControllerRemoveMutation();
  const handleDeleteActiveManager = (userId: number) => {
    deleteUser({ id: userId.toString(), soft: false });
    setTimeout(() => window.location.reload(), 2000);
  };
  const handleDeletePendingManager = (invitation: number) => {
    deleteInvitation(invitation.toString());
    setTimeout(() => window.location.reload(), 2000);
  };
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>{t('activeUsersPage.name')}</TableCell>
          <TableCell>{t('activeUsersPage.email')}</TableCell>
          <TableCell>{t('status')}</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <>
          {(company?.managerUsers || []).map((managerUser) => (
            <ManagerTableRow
              key={'active' + managerUser.id}
              managerUser={managerUser}
              onDelete={handleDeleteActiveManager}
              status="active"
              isPending={false}
            />
          ))}
          {(pendingManagers || []).map((pendingManager: Invitation) => (
            <ManagerTableRow
              key={'pending' + pendingManager.id}
              managerUser={pendingManager}
              onDelete={handleDeletePendingManager}
              status="pending"
              isPending
            />
          ))}
        </>
      </TableBody>
    </Table>
  );
}
function ManagerTableRow({
  managerUser,
  onDelete,
  status,
  isPending,
}: {
  managerUser: User | Invitation;
  onDelete: (id: number) => void;
  status: string;
  isPending: boolean;
}) {
  return (
    <TableRow key={managerUser.id}>
      <TableCell>{managerUser.name}</TableCell>
      <TableCell>{managerUser.email}</TableCell>
      <TableCell>{t(status)}</TableCell>
      <DeleteManagerCell
        managerUser={managerUser}
        onDelete={onDelete}
        isInvitation={isPending}
      />
    </TableRow>
  );
}

function DeleteManagerCell({
  managerUser,
  onDelete,
  isInvitation,
}: {
  managerUser: User | Invitation;
  onDelete: (userId: number) => void;
  isInvitation: boolean;
}) {
  const navigate = useNavigate();
  const { appRoute } = useCompanyRoutes();

  return (
    <>
      <TableCell align="left">
        {!isInvitation ? (
          <Fab
            onClick={() => navigate(appRoute(`users/${managerUser.id}`))}
            color="secondary"
            size="small"
          >
            <Edit />
          </Fab>
        ) : null}
      </TableCell>
      <TableCell align="left">
        <Fab
          onClick={() => {
            const deleteUserConfirmMessage = t(
              'activeUsersPage.deleteUserConfirmMessage',
              { name: managerUser.name },
            );
            if (window.confirm(deleteUserConfirmMessage)) {
              onDelete(managerUser.id);
            }
          }}
          color="secondary"
          size="small"
        >
          <Delete />
        </Fab>
      </TableCell>
    </>
  );
}
