import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {
  Country,
  ImportableTournament,
  useTournamentImporterControllerFindAllQuery,
  useTournamentImporterControllerImportExternalLeagueSeasonMutation,
} from '../../app/services/futbolProdeApi';
import { useMemo, useState } from 'react';
import {
  Alert,
  Avatar,
  Divider,
  LinearProgress,
  Link,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Skeleton,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Case, Switch, When } from 'react-if';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import CountrySelect from './CountrySelect';
import { useSkeletonReadyList } from '@futbolprode/ui-common';
import { isEmpty, isNil, sortBy } from 'ramda';
import { WORLD } from '@futbolprode/ui-common';
import {
  hasPoorCoverage,
  poorCoverageDetails,
} from '../../common/helpers/tournamentHelpers';
import { useNavigate } from 'react-router-dom';
import { Routes, useCompanyRoutes } from '../../router';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

interface ImportTournamentModalProps {
  handleClose: () => void;
  open: boolean;
}

export default function ImportTournamentModal({
  handleClose,
  open,
}: ImportTournamentModalProps) {
  const navigate = useNavigate();
  const { appRoute } = useCompanyRoutes();
  const { t } = useTranslation();
  const [country, setCountry] = useState<Country | null>();
  const [importTournament, { isLoading: isImporting }] =
    useTournamentImporterControllerImportExternalLeagueSeasonMutation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const findQuery = useMemo(() => {
    if (isNil(country)) {
      return skipToken;
    }

    if (WORLD.isWorld(country)) {
      return country.name;
    } else {
      return country.alpha2Code!;
    }
  }, [country]);

  const {
    data,
    isFetching,
    isUninitialized: tournamentsUninitialized,
  } = useTournamentImporterControllerFindAllQuery(findQuery);

  const sortedData = useMemo(
    () => data && sortBy((it) => it.isImported, data),
    [data],
  );
  const tournamentsList = useSkeletonReadyList(sortedData);

  const onModalClose = () => {
    setCountry(null);
    handleClose();
  };

  function renderRow(props: ListChildComponentProps<ImportableTournament[]>) {
    const { index, data, style } = props;
    const tournament = data[index];

    return (
      <ListItem style={style} key={index} disablePadding>
        <ListItemButton
          disabled={tournament.isImported}
          onClick={async () => {
            const newTournament = await importTournament({
              externalLeagueId: tournament.externalId,
              season: tournament.season,
            }).unwrap();
            navigate(appRoute(`${Routes.TOURNAMENTS}/${newTournament.id}`));
          }}
        >
          <ListItemAvatar>
            {isFetching ? (
              <Skeleton variant="circular">
                <Avatar />
              </Skeleton>
            ) : (
              <Avatar alt={tournament.name} src={tournament.logoUrl} />
            )}
          </ListItemAvatar>
          {isFetching ? (
            <Skeleton width="100%">
              <Typography>.</Typography>
            </Skeleton>
          ) : (
            <ListItemText
              primary={tournament.name}
              secondary={
                <>
                  <Typography variant="body2" color="text.secondary">
                    {tournament.season}
                  </Typography>
                  <When condition={hasPoorCoverage(tournament)}>
                    <Typography
                      sx={{ display: 'inline' }}
                      variant="body2"
                      color="warning.main"
                    >
                      {t('importTournament.poorCoverageDetails', {
                        context: poorCoverageDetails(tournament),
                      })}
                    </Typography>
                  </When>
                </>
              }
            />
          )}
        </ListItemButton>
      </ListItem>
    );
  }

  return (
    <Modal
      open={open}
      onClose={onModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack spacing={2} sx={{ ...style, width: isMobile ? 400 : 500 }}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {t('importTournament.title')}
        </Typography>
        <Typography variant="body2">
          {t('importTournament.chooseCountry')}
        </Typography>
        <CountrySelect onValueUpdated={setCountry} />
        <Typography variant="body2">
          <Trans i18nKey="importTournament.searchInternational">
            o{' '}
            <Link onClick={() => setCountry(WORLD)}>
              buscá torneos internacionales.
            </Link>
          </Trans>
        </Typography>
        <Switch>
          <Case condition={isImporting}>
            <Stack
              direction="column"
              spacing={2}
              alignItems="center"
              justifyContent="center"
            >
              <LinearProgress sx={{ width: '100%' }} color="primary" />
              <Typography variant="body2">
                {t('importTournament.importing')}
              </Typography>
            </Stack>
          </Case>
          <Case condition={isEmpty(tournamentsList)}>
            <Alert severity="warning">
              {t('importTournament.noTournamentsFound')}
            </Alert>
          </Case>
          <Case condition={!tournamentsUninitialized}>
            <>
              <Divider />
              <Typography variant="body2">
                {t('importTournament.selectTournament')}
              </Typography>
              <FixedSizeList
                itemCount={tournamentsList.length}
                itemSize={100}
                itemData={tournamentsList}
                overscanCount={5}
                height={400}
                width="100%"
              >
                {renderRow}
              </FixedSizeList>
            </>
          </Case>
        </Switch>
      </Stack>
    </Modal>
  );
}
