import {
  Trivia,
  UpdateTriviaDto,
  useTriviasControllerCreateMutation,
  useTriviasControllerCreateTemplateMutation,
  useTriviasControllerUpdateMutation,
} from '../../app/services/futbolProdeApi';
import { LoadingButton } from '@mui/lab';
import { isNil, mergeDeepRight, times } from 'ramda';
import { Formik } from 'formik';
import { LinearProgress, Stack } from '@mui/material';
import TriviaFormQuestions from './TriviaFormQuestions';
import { TriviaFormBasicData } from './TriviaFormBasicData';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { Routes, useCompanyRoutes } from '../../router';
import { useNavigate } from 'react-router-dom';
import createValidator from 'class-validator-formik';
import { CreateTriviaDto } from './triviaDto';
import { TriviaKind } from './TriviasPage';
import { Unless } from 'react-if';

export const makeDefaultQuestionChoice = (
  index: number,
  isCorrect = false,
) => ({ option: `Respuesta ${index + 1}`, isCorrect });

export const makeDefaultQuestion = (index: number) => ({
  question: `Pregunta ${index + 1}`,
  choices: [
    ...times(makeDefaultQuestionChoice, 2),
    makeDefaultQuestionChoice(2, true),
  ],
});

const defaultValues: UpdateTriviaDto = {
  maxTimePerQuestion: 15,
  pointsByCorrectAnswer: 5,
  randomSelectionCount: 5,
  questions: times(makeDefaultQuestion, 5),
};

export default function TriviaForm({
  value,
  children,
  kind = 'company',
  isLoading = false,
  disabled = false,
}: {
  children?: JSX.Element;
  value?: Trivia;
  kind?: TriviaKind;
  isLoading?: boolean;
  disabled?: boolean;
}) {
  const { t } = useTranslation();
  const isCreating = useMemo(() => isNil(value?.id), [value]);
  const navigate = useNavigate();
  const { appRoute } = useCompanyRoutes();
  const [updateTrivia, updateResult] = useTriviasControllerUpdateMutation();

  const [createTriviaTemplate, createTemplateResult] =
    useTriviasControllerCreateTemplateMutation();

  const [createCompanyTrivia, createCompanyResult] =
    useTriviasControllerCreateMutation();

  const createResult =
    kind === 'company' ? createCompanyResult : createTemplateResult;

  const handleSubmit = async (values: CreateTriviaDto) => {
    if (isCreating) {
      const create =
        kind === 'company' ? createCompanyTrivia : createTriviaTemplate;
      const trivia = await create({ ...values, status: 'draft' }).unwrap();
      navigate(appRoute(`${Routes.TRIVIAS}/${trivia.id}`));
    } else {
      updateTrivia({ id: value!.id, updateTriviaDto: values });
    }
  };

  // @ts-ignore
  const dto: CreateTriviaDto = mergeDeepRight(defaultValues, value ?? {});

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <Formik
      initialValues={dto}
      onSubmit={handleSubmit}
      validateOnChange={false}
      validate={createValidator(CreateTriviaDto)}
      enableReinitialize
      handleSubmit
    >
      {({ handleSubmit, handleChange, values, errors, setFieldValue }) => (
        <Stack spacing={2}>
          {children}
          <form onSubmit={handleSubmit} noValidate>
            <Stack spacing={2}>
              {TriviaFormBasicData({
                values,
                handleChange,
                errors,
                setFieldValue,
                disabled,
              })}
              {TriviaFormQuestions({
                values,
                handleChange,
                errors,
                setFieldValue,
                disabled,
              })}
              <Unless condition={disabled}>
                <LoadingButton
                  loading={createResult.isLoading || updateResult.isLoading}
                  fullWidth
                  type="submit"
                  variant="contained"
                >
                  {t('save')}
                </LoadingButton>
              </Unless>
            </Stack>
          </form>
        </Stack>
      )}
    </Formik>
  );
}
