import { LinearProgress } from '@mui/material';
import {
  Company,
  useCompaniesControllerFindMeQuery,
} from '../../app/services/futbolProdeApi';
import Page from '../../common/Page';
import { If, Then, Else } from 'react-if';

export interface UpdateCompanySettingsFormProps {
  company?: Company;
}

export interface UpdateCompanySettingsDashboardProps {
  Form: React.FC<UpdateCompanySettingsFormProps>;
  title: string;
}

export function UpdateCompanySettingsDashboard({
  Form,
  title,
}: UpdateCompanySettingsDashboardProps) {
  const { data: me, isLoading } = useCompaniesControllerFindMeQuery();

  return (
    <Page breadcrumbs={[{ label: 'sidebar.settings' }]} title={title}>
      <If condition={isLoading}>
        <Then>
          <LinearProgress />
        </Then>
        <Else>
          <Form company={me} />
        </Else>
      </If>
    </Page>
  );
}
