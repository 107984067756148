import {
  Chip,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { isEmpty, when } from 'ramda';
import { JobLog } from '../../app/services/futbolProdeApi';
import { intervalToDuration, parseISO } from 'date-fns';
import { useI18nHelpers, useSkeletonReadyList } from '@futbolprode/ui-common';
import useTranslationHelpers from '../../common/hooks/useTranslationHelpers';
import { SkeletonTableCell } from '../../common/SkeletonTableCell';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import { JobLogMetadata } from './JobLogMetadata';
import { durationToSeverityColor } from '../../common/helpers/durationHelpers';
import { InfoOutlined, Newspaper, Public } from '@mui/icons-material';

function DurationLabel({ value }: { value: Duration }) {
  const { t } = useTranslation();
  const { formatDuration } = useI18nHelpers();

  return (
    <Typography variant="body2" color={durationToSeverityColor(value)}>
      {when(isEmpty, (_) => t('common.lessThanASecond'), formatDuration(value))}
    </Typography>
  );
}

const jobTypeGroupToIcon: Record<string, JSX.Element> = {
  match: <SportsSoccerIcon />,
  ranking: <MilitaryTechIcon />,
  tournament: <EmojiEventsIcon />,
  news: <Newspaper />,
  domains: <Public />,
};

const JobLogIcon = (jobLog: JobLog) => {
  const group = jobLog.type?.split(':')[0];
  return jobTypeGroupToIcon[group] ?? <InfoOutlined />;
};

export default function JobLogsTable({
  isFetching,
  logs,
}: {
  isFetching: boolean;
  logs?: JobLog[];
}) {
  const { t } = useTranslation();
  const { formatDistance, formatDate } = useI18nHelpers();
  const { formatJobType } = useTranslationHelpers();

  const logsList = useSkeletonReadyList(logs, isFetching);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 300 }}>
        <TableHead>
          <TableRow>
            <TableCell>{t('jobLog.type')}</TableCell>
            <TableCell>{t('jobLog.start')}</TableCell>{' '}
            <TableCell>{t('jobLog.duration')}</TableCell>
            <TableCell>{t('jobLog.metadata')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {logsList.map((it) => (
            <TableRow hover key={it.id}>
              <SkeletonTableCell isLoading={isFetching}>
                {it.type && (
                  <Stack direction="row" spacing={1} alignItems="center">
                    {JobLogIcon(it)}
                    <Chip label={formatJobType(it.type)} />
                  </Stack>
                )}
              </SkeletonTableCell>
              <SkeletonTableCell isLoading={isFetching}>
                {it.start && (
                  <Tooltip title={formatDate('PPPpp', parseISO(it.start))}>
                    <Typography variant="body2">
                      {formatDistance(new Date(), parseISO(it.start))}
                    </Typography>
                  </Tooltip>
                )}
              </SkeletonTableCell>
              <SkeletonTableCell isLoading={isFetching}>
                {it.start && (
                  <DurationLabel
                    value={intervalToDuration({
                      start: parseISO(it.start),
                      end: parseISO(it.end),
                    })}
                  />
                )}
              </SkeletonTableCell>
              <SkeletonTableCell isLoading={isFetching}>
                {it.metadata && <JobLogMetadata value={it} />}
              </SkeletonTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
