import { ChangeEvent, Fragment } from 'react';
import {
  Box,
  TextField,
  FormHelperText,
  useTheme,
  InputBaseProps,
} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import { useTranslation } from 'react-i18next';

export interface EmailInputProps {
  placeholder?: string;
  autoComplete?: string;
  label: string;
  name: string;
  value: string | number;
  onChange: (e: ChangeEvent<any>) => void;
  error: string | undefined;
  InputProps?: InputBaseProps;
  type?: string;
}

export function Input({
  label,
  name,
  value,
  onChange,
  error,
  ...props
}: EmailInputProps) {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Fragment>
      <Box sx={{ my: 2 }}>
        <InputLabel
          htmlFor={name}
          sx={{
            color: theme.palette.primary.main,
            marginBottom: 1,
            overflow: 'visible',
            whiteSpace: 'normal',
          }}
        >
          {label}
        </InputLabel>
        <TextField
          id={name}
          fullWidth
          value={value}
          onChange={onChange}
          name={name}
          inputProps={{ borderColor: theme.palette.primary.main }}
          sx={{
            color: theme.palette.primary.main,
            '& label.Mui-focused': {
              color: theme.palette.primary.main,
              borderColor: theme.palette.primary.main,
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.primary.main,
            },
            '& .MuiInputBase-root': {
              borderColor: theme.palette.primary.main,
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.primary.main,
            },
            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
              {
                borderColor: theme.palette.primary.main,
              },
          }}
          {...props}
        />
      </Box>
      <FormHelperText error>{t(error as string)}</FormHelperText>
    </Fragment>
  );
}
