import 'reflect-metadata';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { RouterProvider } from 'react-router-dom';
import router from './router';
import { LinearProgress } from '@mui/material';
import { useMakeAppTheme } from '@futbolprode/ui-common';
import { SnackbarProvider } from 'notistack';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { es } from 'date-fns/locale';
import { appThemeOptions } from './common/configuration/appThemeOptions';

export default function App() {
  const { theme, isLoading } = useMakeAppTheme({
    appThemeOptions,
    ignoreCompanyTheme: true,
  });
  if (isLoading) return <LinearProgress />;
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        preventDuplicate
        maxSnack={1}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        {/* TODO: tomar el locale que corresponde, probablemente exportandolo desde ui-common */}
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
          <CssBaseline />
          <RouterProvider router={router} />
        </LocalizationProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
}
