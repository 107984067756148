import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import PeopleIcon from '@mui/icons-material/People';
import PersonIcon from '@mui/icons-material/Person';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import LanguageIcon from '@mui/icons-material/Language';
import MergeTypeIcon from '@mui/icons-material/MergeType';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import CampaignIcon from '@mui/icons-material/Campaign';
import BusinessIcon from '@mui/icons-material/Business';
import StarIcon from '@mui/icons-material/Star';
import { Routes } from '../../router';
import SvgIcon from '@mui/material/SvgIcon';
import {
  hasRole,
  Role,
  selectImpersonatedCompany,
  useAuth,
} from '@futbolprode/ui-common';
import { any, curry, isNil } from 'ramda';
import { Company, User } from '../../app/services/futbolProdeApi';
import {
  SportsSoccer,
  Gavel,
  Visibility,
  ViewCarousel,
  Settings,
  Lock,
  AccessTime,
  Public,
  Quiz,
  VerifiedUser,
} from '@mui/icons-material';
import { useAppSelector } from '../../app/hooks';

export const DreamFinalIcon = WorkspacePremiumIcon;

const userHasAnyRole = curry((roles: Role[], user: User) =>
  any((role) => hasRole(role, user as any), roles),
);

const allowRoles = (roles: Role[]) => userHasAnyRole(roles);

interface Filtrable {
  isAllowedToSee?: (user: User) => boolean;
}

const removeUnallowedItems = <T extends Filtrable>(
  user: User,
  items: T[],
): T[] =>
  items.filter((it) => isNil(it.isAllowedToSee) || it.isAllowedToSee(user));

interface MenuCategory extends Filtrable {
  title: string;
  subtitle?: string;
  items: MenuItem[];
}

interface MenuItem extends Filtrable {
  label: string;
  Icon: typeof SvgIcon;
  link: string;
}

export function useAppMenu() {
  const { t } = useTranslation();
  const { user } = useAuth();

  const appUser = user! as unknown as User;
  const impersonatedCompany = useAppSelector(
    selectImpersonatedCompany,
  ) as Company | null;
  const isAdmin = appUser.role === Role.ADMIN;
  const isReseller = appUser.role === Role.RESELLER;
  const isAdminOrReseller = isAdmin || isReseller;
  const shouldSeeOnlyGeneralView = isAdminOrReseller && !impersonatedCompany;
  const isImpersonating = isAdminOrReseller && !!impersonatedCompany;

  const fullMenu = useMemo(
    () =>
      [
        {
          title: t('sidebar.globalSettings'),
          subtitle: '',
          isAllowedToSee: allowRoles([Role.ADMIN, Role.RESELLER]),
          items: [
            isAdmin
              ? {
                  label: t('sidebar.jobLogs'),
                  Icon: ManageHistoryIcon,
                  link: Routes.JOB_LOGS,
                }
              : null,
            isAdmin
              ? {
                  label: t('sidebar.domains'),
                  Icon: Public,
                  link: Routes.DOMAINS,
                }
              : null,
            isAdmin
              ? {
                  label: t('sidebar.global'),
                  Icon: Settings,
                  link: Routes.GLOBAL,
                }
              : null,
            isAdmin
              ? {
                  label: t('sidebar.translations'),
                  Icon: LanguageIcon,
                  link: Routes.COMMON_TRANSLATIONS,
                }
              : null,
            isAdmin
              ? {
                  label: t('sidebar.tournament'),
                  Icon: EmojiEventsIcon,
                  link: Routes.TOURNAMENTS,
                }
              : null,
            isAdmin
              ? {
                  label: t('sidebar.triviaTemplates'),
                  Icon: Quiz,
                  link: Routes.TRIVIA_TEMPLATES,
                }
              : null,
            isAdmin
              ? {
                  label: t('sidebar.teams'),
                  Icon: SportsSoccer,
                  link: Routes.TEAMS,
                }
              : null,
            isAdmin
              ? {
                  label: t('sidebar.gameRules'),
                  Icon: Gavel,
                  link: Routes.GAME_RULES,
                }
              : null,
            {
              label: t('sidebar.companies'),
              Icon: PeopleIcon,
              link: Routes.COMPANIES,
            },
            isAdmin
              ? {
                  label: t('sidebar.resellers'),
                  Icon: PeopleIcon,
                  link: Routes.RESELLERS,
                }
              : null,
            isImpersonating
              ? null
              : {
                  label: t('sidebar.activeUsers'),
                  Icon: PersonIcon,
                  link: Routes.ACTIVE_USERS,
                },
            isImpersonating
              ? null
              : {
                  label: t('sidebar.pendingInvitations'),
                  Icon: PeopleOutlineIcon,
                  link: Routes.INVITATIONS,
                },
            isReseller && !isImpersonating
              ? {
                  label: t('sidebar.appearance'),
                  Icon: ColorLensIcon,
                  link: Routes.APPEARANCE,
                }
              : null,
          ].filter((it) => it),
        },
        shouldSeeOnlyGeneralView
          ? null
          : {
              title: t('sidebar.news'),
              items: [
                {
                  label: t('sidebar.rssNews'),
                  Icon: NewspaperIcon,
                  link: Routes.NEWS,
                },
                {
                  label: t('sidebar.customNews'),
                  Icon: NoteAltIcon,
                  link: Routes.CUSTOM_NEWS,
                },
              ],
            },
        shouldSeeOnlyGeneralView
          ? null
          : {
              title: t('sidebar.customization'),
              items: [
                {
                  label: t('sidebar.appearance'),
                  Icon: ColorLensIcon,
                  link: Routes.APPEARANCE,
                },
                {
                  label: t('sidebar.authentication'),
                  Icon: VerifiedUser,
                  link: Routes.AUTHENTICATION,
                },
                {
                  label: t('sidebar.banners'),
                  Icon: ViewCarousel,
                  link: Routes.BANNERS,
                },
                {
                  label: t('sidebar.announcements'),
                  Icon: CampaignIcon,
                  link: Routes.ANNOUNCEMENTS,
                },
                {
                  label: t('sidebar.comingSoon'),
                  Icon: AccessTime,
                  link: Routes.COMING_SOON,
                },
                {
                  label: t('sidebar.translations'),
                  Icon: LanguageIcon,
                  link: Routes.COMPANY_TRANSLATIONS,
                },
                {
                  label: t('sidebar.termsAndConditions'),
                  Icon: Gavel,
                  link: Routes.TERMS_AND_CONDITIONS,
                },
                {
                  label: t('sidebar.activeSections'),
                  Icon: Visibility,
                  link: Routes.ACTIVE_SECTIONS,
                },
              ],
            },
        {
          title: t('sidebar.game'),
          items: [
            {
              label: t('sidebar.rankings'),
              Icon: MilitaryTechIcon,
              link: Routes.RANKINGS,
            },
            shouldSeeOnlyGeneralView
              ? null
              : {
                  label: t('sidebar.combinedRounds'),
                  Icon: MergeTypeIcon,
                  link: Routes.COMBINED_ROUNDS,
                },
            {
              label: t('sidebar.dreamFinal'),
              Icon: DreamFinalIcon,
              link: Routes.DREAM_FINAL,
            },
            {
              label: t('sidebar.awards'),
              Icon: StarIcon,
              link: Routes.AWARDS,
            },
            {
              label: t('sidebar.trivias'),
              Icon: Quiz,
              link: Routes.TRIVIAS,
            },
          ].filter((it) => it),
        },
        {
          title: t('sidebar.users'),
          subtitle: '',
          items: [
            {
              label: t('sidebar.activeUsers'),
              Icon: PersonIcon,
              link: Routes.ACTIVE_USERS,
            },
            {
              label: t('sidebar.pendingInvitations'),
              Icon: PeopleOutlineIcon,
              link: Routes.INVITATIONS,
            },
            shouldSeeOnlyGeneralView
              ? null
              : {
                  label: t('sidebar.importUsers'),
                  Icon: GroupAddIcon,
                  link: Routes.IMPORT_USERS,
                },
            {
              label: t('sidebar.areas'),
              Icon: BusinessIcon,
              link: Routes.AREAS,
            },
            {
              label: t('sidebar.userPermissions'),
              Icon: Lock,
              link: Routes.USERS_PERMISSIONS,
            },
          ].filter((it) => it),
        },
      ].filter((it) => it),
    [t, shouldSeeOnlyGeneralView, isAdmin, isReseller, isImpersonating],
  );

  return useMemo(
    () =>
      removeUnallowedItems(appUser, fullMenu as MenuCategory[]).map((it) => ({
        ...it,
        items: removeUnallowedItems(appUser, it.items),
      })),
    [fullMenu, appUser],
  );
}
