import {
  FormHelperText,
  Stack,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import {
  Company,
  useCompaniesControllerUpdateMutation,
} from '../../app/services/futbolProdeApi';
import { LoadingButton } from '@mui/lab';
import { Formik } from 'formik';
import { t } from 'i18next';
import { PageSection } from '../../common/PageSection';

export function UserEditPermissionsForm({ company }: { company?: Company }) {
  const [updateCompany, updateResult] = useCompaniesControllerUpdateMutation();
  const handleSubmit = async (formValues: any) => {
    if (company) {
      await updateCompany({
        id: company!.id.toString(),
        updateCompanyDto: formValues,
      });
    }
  };

  const companyDto = {
    allowUserEmailEdit: company?.allowUserEmailEdit,
    allowUserNameEdit: company?.allowUserNameEdit,
  };

  return (
    <Formik
      initialValues={companyDto}
      onSubmit={handleSubmit}
      validateOnChange={false}
      handleSubmit
    >
      {({ handleSubmit, handleChange, values }) => (
        <form onSubmit={handleSubmit}>
          <Stack spacing={2}>
            <PageSection
              title="userPermissionsDashboard.personalData"
              subtitle="userPermissionsDashboard.personalDataExplain"
            >
              <Stack spacing={2}>
                <Stack>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id={'allowUserEmailEdit'}
                        checked={values.allowUserEmailEdit}
                        onChange={handleChange}
                        name={'allowUserEmailEdit'}
                      />
                    }
                    label={t('userPermissionsDashboard.allowUserEmailEdit')}
                  />
                </Stack>
                <Stack>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id={'allowUserNameEdit'}
                        checked={values.allowUserNameEdit}
                        onChange={handleChange}
                        name={'allowUserNameEdit'}
                      />
                    }
                    label={t('userPermissionsDashboard.allowUserNameEdit')}
                  />
                </Stack>
              </Stack>
            </PageSection>
            <FormHelperText error>
              {updateResult.error && t('companiesDashboard.error')}
            </FormHelperText>
            <LoadingButton
              loading={updateResult.isLoading}
              fullWidth
              type="submit"
              variant="contained"
            >
              {t('save')}
            </LoadingButton>
          </Stack>
        </form>
      )}
    </Formik>
  );
}
