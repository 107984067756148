import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import {
  Company,
  useCompaniesControllerSearchQuery,
} from '../../app/services/futbolProdeApi';
import { useAppSelector } from '../../app/hooks';
import {
  selectImpersonatedCompany,
  useDebouncedState,
} from '@futbolprode/ui-common';
import { eqProps } from 'ramda';
import { useTranslation } from 'react-i18next';
import { Chip } from '@mui/material';
import { When } from 'react-if';

interface ImpersonatedCompanySelectorProps {
  handleChange?: (company: Company | null) => void;
}

export default function ImpersonatedCompanySelector({
  handleChange = (_) => {},
}: ImpersonatedCompanySelectorProps) {
  const [inputValue, setInputValue] = useDebouncedState('', 500);
  const { data, isFetching } = useCompaniesControllerSearchQuery({
    search: inputValue,
    take: 20,
  });
  const options = data ?? [];
  const [value, setValue] = React.useState(
    useAppSelector(selectImpersonatedCompany) as Company | null,
  );
  const { t } = useTranslation();
  return (
    <Autocomplete
      getOptionLabel={(option) => option.name}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      noOptionsText={t('autocomplete.noOptions')}
      value={value}
      loading={isFetching}
      isOptionEqualToValue={eqProps('id')}
      onChange={(_event: any, newValue: Company | null) => {
        setValue(newValue);
        handleChange(newValue);
      }}
      onInputChange={(_event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={t('autocomplete.chooseOne') as string}
          fullWidth
          onClick={() => setValue(null)}
        />
      )}
      renderOption={(props, option) => {
        const matches = match(option.name, inputValue, { insideWords: true });
        const parts = parse(option.name, matches);

        return (
          <li {...props}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item sx={{ wordWrap: 'break-word' }}>
                {parts.map((part, index) => (
                  <Box
                    key={index}
                    component="span"
                    sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                  >
                    {part.text}
                  </Box>
                ))}
              </Grid>
              <When condition={option.isReseller}>
                <Grid item>
                  <Chip color="info" label={t('companyForm.resellerShort')} />
                </Grid>
              </When>
            </Grid>
          </li>
        );
      }}
    />
  );
}
