import { useParams } from 'react-router-dom';
import { CombinedRoundsForm } from './CombinedRoundsForm';
import { useCombinedRoundsRankingDefinitionsControllerFindOneQuery } from '../../../app/services/futbolProdeApi';
import Skeleton from '@mui/material/Skeleton';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Routes, useCompanyRoutes } from '../../../router';
import Page from '../../../common/Page';

export function EditCombinedRounds() {
  const { id } = useParams();
  const { appRoute } = useCompanyRoutes();

  const { data, isLoading } =
    useCombinedRoundsRankingDefinitionsControllerFindOneQuery(
      id ? id : skipToken,
    );
  return isLoading ? (
    <Skeleton width="100%" height="300px" />
  ) : (
    <Page
      breadcrumbs={[
        { label: 'sidebar.game' },
        {
          label: 'sidebar.combinedRounds',
          url: appRoute(Routes.COMBINED_ROUNDS),
        },
      ]}
      title="combinedRoundsDashboard.editCombinedRounds"
    >
      <CombinedRoundsForm combinedRound={data} />
    </Page>
  );
}
