import { CardContent, Stack, darken, useTheme } from '@mui/material';
import { useStatsControllerStatsQuery } from '../../app/services/futbolProdeApi';
import { useI18nHelpers } from '@futbolprode/ui-common';
import { evolve, compose, reverse } from 'ramda';
import {
  XAxis,
  YAxis,
  Tooltip,
  Area,
  AreaChart,
  ResponsiveContainer,
} from 'recharts';
import { parseISO } from 'date-fns/fp';
import { RoundedCard } from '../../common/MuiExtensions';
import { HomeTitles } from './HomePage';

export function RegisteredUsersCard() {
  const { formatDate } = useI18nHelpers();
  const { palette } = useTheme();
  const { data, isLoading } = useStatsControllerStatsQuery();

  return (
    <RoundedCard sx={{ backgroundColor: 'secondary.main' }}>
      <CardContent>
        <Stack spacing={2}>
          <HomeTitles
            titleKey="homePage.registeredUsersTitle"
            titleColor="secondary.contrastText"
            subtitleKey="homePage.registeredUsersSubtitle"
            subtitleColor={darken(palette.secondary.contrastText, 0.25)}
          />
          {isLoading ? (
            <Stack />
          ) : (
            <ResponsiveContainer height={250} width="95%">
              <AreaChart
                data={reverse(data?.recentlyRegisteredUsers ?? []).map(
                  evolve({
                    date: compose(formatDate('dd/MM'), parseISO),
                  }),
                )}
                maxBarSize={30}
              >
                <defs>
                  <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                    <stop
                      offset="5%"
                      stopColor={palette.primary.light}
                      stopOpacity={0.8}
                    />
                    <stop
                      offset="95%"
                      stopColor={palette.primary.light}
                      stopOpacity={0.3}
                    />
                  </linearGradient>
                </defs>
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Area
                  type="monotone"
                  dataKey="count"
                  stroke={palette.primary.dark}
                  strokeWidth={2}
                  fillOpacity={1}
                  fill="url(#colorUv)"
                />
              </AreaChart>
            </ResponsiveContainer>
          )}
        </Stack>
      </CardContent>
    </RoundedCard>
  );
}
