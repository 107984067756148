import Page from '../../common/Page';
import { CompaniesTable } from './CompaniesTable';
import AddIcon from '@mui/icons-material/Add';
import FloatingButton from '../../common/FloatingButton';
import { useNavigate } from 'react-router-dom';
import { InputAdornment, Pagination, TextField } from '@mui/material';
import { isEmpty, isNil } from 'ramda';
import { Search } from '@mui/icons-material';
import { useUserPermissions } from '../../common/hooks/useUserPermissions';

export function CompaniesDashboard({
  data,
  isLoading,
  isReseller = false,
  page,
  pageSize,
  count,
  setPage,
  search,
  setSearch,
}: {
  data: any;
  isLoading: boolean;
  isReseller?: boolean;
  page: number;
  pageSize: number;
  count: number;
  setPage: (it: number) => void;
  search?: string;
  setSearch: (it: string) => void;
}) {
  const companies = data || [];
  const navigate = useNavigate();
  const { isAdmin } = useUserPermissions();
  return (
    <Page
      breadcrumbs={[{ label: 'sidebar.globalSettings' }]}
      title={isReseller ? 'sidebar.resellers' : 'sidebar.companies'}
      actions={
        !isAdmin ? (
          <></>
        ) : (
          <FloatingButton color="primary" onClick={() => navigate('new')}>
            <AddIcon />
          </FloatingButton>
        )
      }
    >
      <TextField
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
      />
      <Pagination
        showLastButton
        variant="outlined"
        disabled={isLoading || isNil(companies) || isEmpty(companies)}
        count={Math.ceil(count / pageSize)}
        key={page}
        page={page + 1}
        onChange={(_event: React.ChangeEvent<unknown>, value: number) =>
          setPage(value - 1)
        }
        sx={{
          alignSelf: 'flex-end',
          '& .MuiPaginationItem-page': {
            border: 'none',
            backgroundColor: 'transparent',
          },
        }}
      />
      <CompaniesTable
        isLoading={isLoading}
        isReseller={isReseller}
        companies={companies}
      />
    </Page>
  );
}
