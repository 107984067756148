import { MuiColorInput } from 'mui-color-input';
import { FormControlLabel, InputLabel, Stack, Switch } from '@mui/material';
import { PageSection } from '../../common/PageSection';
import { useTranslation } from 'react-i18next';
import { Input } from '../../common/form/Input';
import { ComingSoonModeDto } from './ComingSoonModePage';
import ImageUploadField from '../../common/ImageUploadField';
import { TranslationFormProps } from '../translations/translationHelpers';

export default function ComingSoonModeForm({
  locale,
  handleChange,
  values,
  setFieldValue,
  errors,
}: TranslationFormProps<ComingSoonModeDto>) {
  const { t } = useTranslation();

  return (
    <Stack spacing={2}>
      <PageSection title="">
        <FormControlLabel
          control={
            <Switch
              checked={values.company.comingSoonModeIsEnabled}
              onChange={handleChange}
              name={`company.comingSoonModeIsEnabled`}
            />
          }
          label={t('common.isActive')}
        />
        <Input
          value={values.translations[locale].comingSoonMode.title}
          name={`translations.${locale}.comingSoonMode.title`}
          label={'comingSoonModePage.title'}
          onChange={handleChange}
          required
        />
        <Input
          value={values.translations[locale].comingSoonMode.subtitle}
          name={`translations.${locale}.comingSoonMode.subtitle`}
          label={'comingSoonModePage.subtitle'}
          onChange={handleChange}
        />
        <InputLabel htmlFor={`company.comingSoonModeTextColor`}>
          {t('comingSoonModePage.textColor')}
        </InputLabel>
        <MuiColorInput
          name={`company.comingSoonModeTextColor`}
          value={values.company.comingSoonModeTextColor ?? '#ffffff'}
          onChange={(value: string) =>
            setFieldValue('company.comingSoonModeTextColor', value)
          }
        />
        <ImageUploadField
          label={`${t('comingSoonModePage.backgroundImage')} *`}
          error={errors.company?.comingSoonModeBackgroundImageUrl}
          value={values.company.comingSoonModeBackgroundImageUrl ?? ''}
          fieldName={'company.comingSoonModeBackgroundImageUrl'}
          setFieldValue={setFieldValue}
        />
      </PageSection>
    </Stack>
  );
}
