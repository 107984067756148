import {
  Alert,
  Avatar,
  LinearProgress,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Else, If, Then, When } from 'react-if';
import { Stack } from '@mui/system';
import { isEmpty, isNil } from 'ramda';
import {
  Company,
  CompanyWithUsersCount,
} from '../../app/services/futbolProdeApi';
import { useNavigate } from 'react-router-dom';
import { compact, isNilOrZero } from '../../common/helpers/ramdaExtension';
import { useUserPermissions } from '../../common/hooks/useUserPermissions';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';

// El icono hacia arriba no tiene relleno y este sí
const ArrowUpIcon = styled(ArrowCircleRightIcon)(() => ({
  rotate: '-90deg',
}));

const appRoute = (company: Company) =>
  compact([company.appCustomDomain, company.appPath]).join('/');

const usersCount = (company: CompanyWithUsersCount | Company) => {
  const max = company.maxUsersAmount;
  const actual = 'usersCount' in company ? company.usersCount : undefined;
  return isNil(actual)
    ? undefined
    : { max, actual, exceeded: Math.max(0, actual - max) };
};

const between = (min: number, max: number, actual: number) =>
  actual > min && actual <= max;

function UsersCountTableCells({
  company,
}: {
  company: CompanyWithUsersCount | Company;
}) {
  const { actual, max, exceeded } = usersCount(company) ?? {
    max: 0,
    actual: 0,
  };

  const color =
    actual >= max
      ? 'error.main'
      : between(max * 0.9, max, actual)
      ? 'warning.main'
      : 'inherit';

  return (
    <>
      <TableCell sx={{ color }}>
        {actual} / {max}
      </TableCell>
      <TableCell>
        {isNilOrZero(exceeded) ? null : (
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <ArrowUpIcon sx={{ color }} />
            <span>{exceeded}</span>
          </Stack>
        )}
      </TableCell>
    </>
  );
}

export function CompaniesTable({
  isLoading,
  isReseller,
  companies,
}: {
  isLoading: boolean;
  isReseller: boolean;
  companies: (Company | CompanyWithUsersCount)[];
}) {
  const { isAdmin } = useUserPermissions();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const showUsersCount = !isReseller && isAdmin;

  return (
    <If condition={isLoading}>
      <Then>
        <Stack
          direction="column"
          spacing={2}
          alignItems="center"
          justifyContent="center"
        >
          <LinearProgress sx={{ width: '100%' }} color="primary" />
        </Stack>
      </Then>
      <Else>
        <If condition={isEmpty(companies)}>
          <Then>
            <Alert severity="info">{t('companiesDashboard.noCompanies')}</Alert>
          </Then>
          <Else>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 300 }}>
                <TableHead>
                  <TableRow>
                    <TableCell>{t('companiesDashboard.name')}</TableCell>
                    <TableCell>
                      {t('companiesDashboard.appCustomDomainOrPath')}
                    </TableCell>
                    <When condition={showUsersCount}>
                      <TableCell>
                        {t('companiesDashboard.usersCount')}
                      </TableCell>
                      <TableCell>
                        {t('companiesDashboard.exceededUsersCount')}
                      </TableCell>
                    </When>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(companies || []).map((company) => (
                    <TableRow
                      hover
                      key={company.id}
                      onClick={() => navigate(company.id.toString())}
                      sx={{ cursor: 'pointer' }}
                    >
                      <TableCell>
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <Avatar src={company.isologoUrl} />
                          <p>{company.name || '-'}</p>
                        </Stack>
                      </TableCell>
                      <TableCell>{appRoute(company)}</TableCell>
                      <When condition={showUsersCount}>
                        <UsersCountTableCells company={company} />
                      </When>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Else>
        </If>
      </Else>
    </If>
  );
}
