import { Search } from '@mui/icons-material';
import {
  Avatar,
  Grid,
  InputAdornment,
  TextField,
  Table,
  TableRow,
  TableCell,
  Typography,
  TableContainer,
  LinearProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Else, If, Then } from 'react-if';
import { useNavigate } from 'react-router-dom';
import {
  Team,
  useTeamsControllerFindAllQuery,
} from '../../app/services/futbolProdeApi';
import Page from '../../common/Page';
import { compose, groupBy, isNil, sortBy, toPairs } from 'ramda';
import FloatingButton from '../../common/FloatingButton';
import AddIcon from '@mui/icons-material/Add';

function TeamRow({ team }: { team: Team }) {
  const navigate = useNavigate();

  return (
    <TableRow
      hover
      key={team.id}
      sx={{
        borderBottom: 1,
        borderColor: 'primary.main',
        p: 1,
        m: 1,
        cursor: 'pointer',
      }}
      onClick={() => {
        navigate(`${team.id}`);
      }}
    >
      <TableCell>
        <Grid
          flexDirection="row"
          direction="row"
          display="flex"
          alignItems="center"
        >
          <Grid item>
            <Avatar src={team.logoUrl}></Avatar>
          </Grid>
          <Grid item ml={1}>
            <Typography>{team.name}</Typography>
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  );
}

function TeamGroup({ teams, name }: { teams: Team[]; name: string }) {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">{name}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <TableContainer>
          <Table>
            {teams.map((team) => (
              <TeamRow team={team} />
            ))}
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  );
}

export function TeamsDashboard() {
  const { data, isLoading } = useTeamsControllerFindAllQuery();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState('');
  const filteredTeams = useMemo(() => {
    const teams = data?.data || [];
    return isNil(searchText)
      ? teams
      : teams.filter((team) =>
          team.name.toLowerCase().includes(searchText.toLowerCase()),
        );
  }, [data, searchText]);
  const teamsByCountry = useMemo(
    () =>
      compose(
        sortBy(([countryName]) => countryName),
        toPairs,
        groupBy((it: Team) => it.country.name),
      )(filteredTeams) as [string, Team[]][],
    [filteredTeams],
  );

  return (
    <Page
      breadcrumbs={[{ label: 'sidebar.globalSettings' }]}
      actions={
        <FloatingButton color="primary" onClick={() => navigate('new')}>
          <AddIcon />
        </FloatingButton>
      }
      title="sidebar.teams"
    >
      <Grid container>
        <Grid xs={12} mb={2} alignItems="center" justifyContent="center">
          <TextField
            placeholder={t('search') as string}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid xs={12}>
          <If condition={isLoading}>
            <Then>
              <LinearProgress />
            </Then>
            <Else>
              {teamsByCountry.map(([countryName, teams]) => (
                <TeamGroup teams={teams} name={countryName} />
              ))}
            </Else>
          </If>
        </Grid>
      </Grid>
    </Page>
  );
}
