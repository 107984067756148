import { skipToken } from '@reduxjs/toolkit/query/react';
import { useTriviasControllerGetTriviaQuery } from '../../app/services/futbolProdeApi';
import { useCompanyRoutes } from '../../router';
import { useLocation, useParams } from 'react-router-dom';
import Page from '../../common/Page';
import TriviaForm from './TriviaForm';
import { TriviaKind, TriviasPageProps } from './TriviasPage';
import { TriviaKindProps } from '../../router';
import { Stack } from '@mui/material';
import { useUserPermissions } from '../../common/hooks/useUserPermissions';
import { TriviaStatusExplanation } from './TriviaStatusExplanation';
import { isNil } from 'ramda';
import { Unless } from 'react-if';
import queryString from 'query-string';

export default function TriviaEditPage({ kind }: TriviasPageProps) {
  const { isAdmin } = useUserPermissions();
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const source = queryParams.source as TriviaKind | undefined;
  // Solo el admin puede navegar al listado de trivias common
  const forcedKind = isAdmin ? source ?? kind : 'company';
  const { appRoute } = useCompanyRoutes();

  const { id } = useParams();
  const { data, isLoading } = useTriviasControllerGetTriviaQuery(
    id ? +id : skipToken,
  );

  const canEdit =
    (kind === 'company' && !isNil(data) && data.status !== 'published') ||
    (kind === 'template' && source !== 'company' && isAdmin);

  const triviaKindProps = TriviaKindProps[forcedKind];

  return (
    <Page
      breadcrumbs={[
        triviaKindProps.baseBreadcrumb,
        { label: 'sidebar.trivias', url: appRoute(triviaKindProps.route) },
      ]}
      title={data?.title}
    >
      <Stack spacing={2}>
        <TriviaForm value={data} isLoading={isLoading} disabled={!canEdit}>
          <Unless condition={source === 'template'}>
            <TriviaStatusExplanation
              kind={kind}
              trivia={data}
              isLoading={isLoading}
            />
          </Unless>
        </TriviaForm>
      </Stack>
    </Page>
  );
}
