import { FormHelperText } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { parseISO, formatISO } from 'date-fns';
import { FormikErrors, FormikHelpers } from 'formik';
import { Nullable } from 'typescript-nullable';
import { useTranslation } from 'react-i18next';
import InputWrapper, { InputWrapperProps } from './InputWrapper';

export type DatePickerInputProps<T> = Omit<InputWrapperProps, 'children'> & {
  values: T;
  errors: FormikErrors<T>;
  name: keyof T;
  label: string;
  helperText?: string;
  disabled?: boolean;
  setFieldValue: FormikHelpers<T>['setFieldValue'];
};

export default function DatePickerInput<T>({
  values,
  errors,
  setFieldValue,
  disabled,
  ...props
}: DatePickerInputProps<T>) {
  const { t } = useTranslation();
  return (
    <InputWrapper {...props}>
      <>
        <DatePicker
          sx={{ width: '100%' }}
          disabled={disabled}
          value={Nullable.map(parseISO, values?.[props.name] as string)}
          onChange={(newValue: Date | null) => {
            setFieldValue(
              props.name.toString(),
              Nullable.map(formatISO, newValue),
            );
          }}
        />
        <FormHelperText error>
          {t(errors?.[props.name] as string)}
        </FormHelperText>
      </>
    </InputWrapper>
  );
}
