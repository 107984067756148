import {
  FormHelperText,
  InputBaseProps,
  InputLabel,
  Stack,
  TextField,
} from '@mui/material';
import {
  Company,
  useInvitationsControllerCreateMutation,
} from '../../app/services/futbolProdeApi';
import { LoadingButton } from '@mui/lab';
import { Formik } from 'formik';
import { ChangeEvent, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCompanyRoutes } from '../../router';
import createValidator from 'class-validator-formik';
import { InviteManagerDtoValidator } from './CreateCompanyDtoValidator';
import { PageSection } from '../../common/PageSection';
import { useTranslation } from 'react-i18next';

interface InputProps {
  multiline?: boolean;
  label: string;
  name: string;
  value: string | number | null;
  onChange: (e: ChangeEvent<any>) => void;
  error: string | undefined;
  InputProps?: InputBaseProps;
  type?: string;
}

export function Input({
  label,
  name,
  value,
  onChange,
  multiline,
  error,
  ...props
}: InputProps) {
  const { t } = useTranslation();
  return (
    <Fragment>
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <TextField
        id={name}
        fullWidth
        value={value}
        onChange={onChange}
        name={name}
        multiline={multiline}
        InputProps={
          props.type === 'number'
            ? {
                inputProps: {
                  min: 0,
                },
              }
            : {}
        }
        {...props}
      />
      <FormHelperText error>{t(error as string)}</FormHelperText>
    </Fragment>
  );
}

export enum LoginStrategy {
  byEmail,
  byExternalId,
}

export function ManagerForm({ company }: { company: Company }) {
  const { t } = useTranslation();
  const [createInvitation, invitationResult] =
    useInvitationsControllerCreateMutation();
  const navigate = useNavigate();
  const { appRoute } = useCompanyRoutes();
  const isReseller = company.isReseller;

  const createManagerInvitation = async (creationValues: any) => {
    const managerInfo = {
      name: creationValues.managerUserName,
      email: creationValues.managerUserEmail,
    };
    delete creationValues.managerUserName;
    delete creationValues.managerUserEmail;
    const managerUserInvitation = {
      ...managerInfo,
      externalId: managerInfo.email,
      role: isReseller ? 'reseller' : 'manager',
      company: { id: company.id },
    };
    await createInvitation(managerUserInvitation).unwrap();

    navigate(
      appRoute(
        isReseller ? `resellers/${company.id}` : `companies/${company.id}`,
      ),
    );
  };

  const inviteManagerDto = {
    managerUserName: '',
    managerUserEmail: '',
  };

  return (
    <Formik
      initialValues={inviteManagerDto}
      onSubmit={createManagerInvitation}
      validate={createValidator(InviteManagerDtoValidator)}
      validateOnChange={false}
      handleSubmit
    >
      {({ handleSubmit, handleChange, values, errors }) => (
        <form onSubmit={handleSubmit}>
          <Stack spacing={2}>
            <PageSection title="companiesDashboard.managerUser">
              <Input
                value={values.managerUserEmail || ''}
                name={'managerUserEmail'}
                label={`${t('companiesDashboard.managerUserEmail')} *`}
                onChange={handleChange}
                error={t(errors.managerUserEmail as string) ?? undefined}
              />
              <Input
                value={values.managerUserName || ''}
                name={'managerUserName'}
                label={`${t('companiesDashboard.managerUserName')} *`}
                onChange={handleChange}
                error={t(errors.managerUserName as string) ?? undefined}
              />
            </PageSection>

            <FormHelperText error>
              {invitationResult.error && t('companiesDashboard.error')}
            </FormHelperText>
            <LoadingButton
              loading={invitationResult.isLoading}
              fullWidth
              type="submit"
              variant="contained"
            >
              {t('save')}
            </LoadingButton>
          </Stack>
        </form>
      )}
    </Formik>
  );
}
