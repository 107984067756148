import { UpdateTriviaDto } from '../../app/services/futbolProdeApi';
import { FormikErrors, FormikHandlers, FormikHelpers } from 'formik';
import { PageSection } from '../../common/PageSection';
import { Input } from '../../common/form/Input';
import DatePickerInput from '../../common/form/DatePickerInput';
import ImageUploadField from '../../common/ImageUploadField';
import { useTranslation } from 'react-i18next';
import { Switch } from '@mui/material';
import InputWrapper from '../../common/form/InputWrapper';

export function TriviaFormBasicData({
  values,
  handleChange,
  errors,
  setFieldValue,
  disabled,
}: {
  values: UpdateTriviaDto;
  handleChange: FormikHandlers['handleChange'];
  errors: FormikErrors<UpdateTriviaDto>;
  setFieldValue: FormikHelpers<UpdateTriviaDto>['setFieldValue'];
  disabled: boolean;
}) {
  const { t } = useTranslation();
  return (
    <PageSection title="triviasPage.basicData">
      <InputWrapper
        name={'allowReplay'}
        label={'entity.trivia.allowReplay'}
        helperText={'entity.trivia.allowReplay_helperText'}
      >
        <Switch
          checked={values.allowReplay}
          onChange={handleChange}
          disabled={disabled}
          name={'allowReplay'}
        />
      </InputWrapper>
      <Input
        value={values.title}
        name={'title'}
        label={'entity.trivia.title'}
        onChange={handleChange}
        error={errors.title}
        disabled={disabled}
        required
      />
      <Input
        value={values.subtitle}
        name={'subtitle'}
        label={'entity.trivia.subtitle'}
        onChange={handleChange}
        error={errors.subtitle}
        disabled={disabled}
      />
      <Input
        value={values.maxTimePerQuestion}
        name={'maxTimePerQuestion'}
        label={'entity.trivia.maxTimePerQuestion'}
        onChange={handleChange}
        error={errors.maxTimePerQuestion}
        type="number"
        disabled={disabled}
        required
      />
      <Input
        value={values.pointsByCorrectAnswer}
        name={'pointsByCorrectAnswer'}
        label={'entity.trivia.pointsByCorrectAnswer'}
        onChange={handleChange}
        error={errors.pointsByCorrectAnswer}
        type="number"
        disabled={disabled}
        required
      />
      <Input
        value={values.randomSelectionCount}
        name={'randomSelectionCount'}
        label={'entity.trivia.randomSelectionCount'}
        helperText={'entity.trivia.randomSelectionCount_helperText'}
        onChange={handleChange}
        error={errors.randomSelectionCount}
        type="number"
        InputProps={{ inputProps: { min: 5 } }}
        disabled={disabled}
        required
      />
      <DatePickerInput<UpdateTriviaDto>
        values={values}
        errors={errors}
        label="entity.trivia.startsAt"
        helperText={'entity.trivia.startsAt_helperText'}
        name="startsAt"
        setFieldValue={setFieldValue}
        disabled={disabled}
        required
      />
      <DatePickerInput<UpdateTriviaDto>
        values={values}
        errors={errors}
        label="entity.trivia.finishesAt"
        helperText={'entity.trivia.finishesAt_helperText'}
        name="finishesAt"
        setFieldValue={setFieldValue}
        disabled={disabled}
        required
      />
      <ImageUploadField
        label={`${t('entity.trivia.coverImageUrl')} *`}
        error={errors.coverImageUrl}
        value={values.coverImageUrl ?? ''}
        fieldName={'coverImageUrl'}
        setFieldValue={setFieldValue}
        disabled={disabled}
      />
    </PageSection>
  );
}
