import { Stack } from '@mui/material';
import { HtmlEditorInput } from '../../common/HtmlEditorInput';
import {
  TranslationFormProps,
  TranslationsDto,
} from '../translations/translationHelpers';
import { GameRulesTranslations } from './GameRulesDashboard';
import { PageSection } from '../../common/PageSection';

export function GameRulesForm({
  locale,
  values,
  setFieldValue,
  errors,
}: TranslationFormProps<TranslationsDto<GameRulesTranslations>>) {
  return (
    <PageSection title="">
      <Stack spacing={2}>
        <HtmlEditorInput
          isRequired={true}
          value={values[locale].gameRules.general}
          label="gameRulesDashboard.generalRules"
          errorLabel={errors?.[locale]?.gameRules?.general}
          setValue={(value) => {
            setFieldValue(`${locale}.gameRules.general`, value);
          }}
        />
        <HtmlEditorInput
          isRequired={true}
          value={values[locale].gameRules.dreamFinal}
          label="gameRulesDashboard.dreamFinalRules"
          errorLabel={errors?.[locale]?.gameRules?.dreamFinal}
          setValue={(value) => {
            setFieldValue(`${locale}.gameRules.dreamFinal`, value);
          }}
        />
        <HtmlEditorInput
          isRequired={true}
          value={values[locale].gameRules.penalties}
          label="gameRulesDashboard.penaltiesRules"
          errorLabel={errors?.[locale]?.gameRules?.penalties}
          setValue={(value) => {
            setFieldValue(`${locale}.gameRules.penalties`, value);
          }}
        />
        <HtmlEditorInput
          isRequired={true}
          value={values[locale].gameRules.twoWayPenalties}
          label="gameRulesDashboard.twoWayPenaltiesRules"
          errorLabel={errors?.[locale]?.gameRules?.twoWayPenalties}
          setValue={(value) => {
            setFieldValue(`${locale}.gameRules.twoWayPenalties`, value);
          }}
        />
      </Stack>
    </PageSection>
  );
}
