import Page from '../../common/Page';
import { Routes, useCompanyRoutes } from '../../router';
import { NewsForm } from './NewsForm';

export function CustomNewsCreate() {
  const { appRoute } = useCompanyRoutes();
  return (
    <Page
      breadcrumbs={[
        { label: 'sidebar.news' },
        {
          label: 'sidebar.customNews',
          url: appRoute(Routes.CUSTOM_NEWS),
        },
      ]}
      title="newsDashboard.createNews"
    >
      <NewsForm />
    </Page>
  );
}
