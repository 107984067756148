import {
  ListItemIcon,
  IconButton,
  Menu,
  MenuItem,
  Button,
  Stack,
  Link,
} from '@mui/material';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { useAuth, logout } from '@futbolprode/ui-common';
import Logout from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useAppDispatch } from '../app/hooks';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ImpersonationAlert from '../features/impersonation/ImpersonationAlert';
import { useNavigate } from 'react-router-dom';
import {
  useCompaniesControllerFindMeQuery,
  useUsersControllerFindMeQuery,
} from '../app/services/futbolProdeApi';
import { useCompanyRoutes } from '../router';
import CompanyHeader from './CompanyHeader';
import { OpenInNew } from '@mui/icons-material';
import { gameUrl } from '../features/home/companyHelpers';

export default function AppBarContent() {
  const { canImpersonate } = useAuth();
  const { data: company } = useCompaniesControllerFindMeQuery();
  const navigate = useNavigate();
  const { appRoute } = useCompanyRoutes();
  const { data: me } = useUsersControllerFindMeQuery();
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { t } = useTranslation();

  return (
    <>
      {canImpersonate ? (
        <ImpersonationAlert />
      ) : (
        <CompanyHeader company={company} />
      )}
      <Stack direction="row" spacing={1}>
        <Link href={'//' + gameUrl(company)} target="_blank" rel="noopener">
          <Button variant="contained" endIcon={<OpenInNew />}>
            {t('sidebar.goToGame')}
          </Button>
        </Link>
        <IconButton
          color="inherit"
          onClick={handleClick}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <AccountCircleOutlinedIcon />
        </IconButton>
      </Stack>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {me ? (
          <MenuItem
            onClick={() => {
              navigate(appRoute(`users/${me.id}?isSelf=true`));
            }}
          >
            <ListItemIcon>
              <AccountCircleIcon />
            </ListItemIcon>
            {t('user.accountEdit')}
          </MenuItem>
        ) : null}
        <MenuItem
          onClick={() => {
            dispatch(logout());
          }}
        >
          <ListItemIcon>
            <Logout />
          </ListItemIcon>
          {t('user.logout')}
        </MenuItem>
      </Menu>
    </>
  );
}
