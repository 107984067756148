import { Grid, LinearProgress } from '@mui/material';
import { If, Then, Else } from 'react-if';
import { useParams } from 'react-router-dom';
import { useCompaniesControllerFindOneQuery } from '../../app/services/futbolProdeApi';
import Page from '../../common/Page';
import { Routes, useCompanyRoutes } from '../../router';
import { ManagerForm } from './ManagerForm';

export function CompanyManagers() {
  const { appRoute } = useCompanyRoutes();
  const { id } = useParams();
  const { data, isLoading } = useCompaniesControllerFindOneQuery(id!);
  return (
    <Page
      breadcrumbs={[
        { label: 'sidebar.globalSettings' },
        {
          label: 'sidebar.companies',
          url: appRoute(Routes.COMPANIES),
        },
        {
          label: data?.name || id!,
          url: appRoute(`${Routes.COMPANIES}/${id}`),
        },
      ]}
      title="companiesDashboard.inviteManager"
    >
      <Grid container>
        <Grid item xs={12}>
          <If condition={isLoading}>
            <Then>
              <LinearProgress />
            </Then>
            <Else>
              <ManagerForm company={data!} />
            </Else>
          </If>
        </Grid>
      </Grid>
    </Page>
  );
}
