import { any, indexBy, prop, isNil, isEmpty } from 'ramda';
import {
  Area,
  User,
  useAreasControllerFindAllQuery,
} from '../../app/services/futbolProdeApi';
import { useCallback, useMemo } from 'react';
import { compact } from '../../common/helpers/ramdaExtension';

type AreaIndex = Record<number, Area>;

const areasIndex = (areas?: Area[]) => indexBy((it) => it.id, areas ?? []);
const areaName = (index: AreaIndex, id?: number) =>
  isNil(id) ? undefined : index[id]?.name;

export const useAreasForUserQuery = () => {
  const areasLevel1 = useAreasControllerFindAllQuery(1);
  const areasLevel2 = useAreasControllerFindAllQuery(2);
  const areasLevel3 = useAreasControllerFindAllQuery(3);

  const isLoading = useMemo(
    () => any(prop('isLoading'), [areasLevel1, areasLevel2, areasLevel3]),
    [areasLevel1, areasLevel2, areasLevel3],
  );

  const area1Index = useMemo(
    () => areasIndex(areasLevel1.data),
    [areasLevel1.data],
  );

  const area2Index = useMemo(
    () => areasIndex(areasLevel2.data),
    [areasLevel2.data],
  );

  const area3Index = useMemo(
    () => areasIndex(areasLevel3.data),
    [areasLevel3.data],
  );

  const areaString = useCallback(
    (user: User) => {
      const names = compact([
        areaName(area1Index, user.areaLevel1Id),
        areaName(area2Index, user.areaLevel2Id),
        areaName(area3Index, user.areaLevel3Id),
      ]);
      return isEmpty(names) ? undefined : names.join(' / ');
    },
    [area1Index, area2Index, area3Index],
  );

  return { isLoading, areaString };
};
