import { FormHelperText, LinearProgress, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  useTranslationsControllerCreateMutation,
  useTranslationsControllerGetCompanyTranslationsQuery,
} from '../../app/services/futbolProdeApi';
import { Formik } from 'formik';
import { Input } from '../news/Input';
import { LoadingButton } from '@mui/lab';

interface CompanyTranslationsDto {
  identifier: string;
  areaLevel1: string;
  areaLevel2: string;
  areaLevel3: string;
}

export function CompanyTranslationsEdit({ locale }: { locale: string }) {
  const { t } = useTranslation();
  const { data, isLoading } =
    useTranslationsControllerGetCompanyTranslationsQuery(locale ?? 'es');

  const [createTranslations, createResult] =
    useTranslationsControllerCreateMutation();
  if (isLoading) return <LinearProgress></LinearProgress>;

  const translations =
    data ??
    ({
      user: {},
    } as any);

  const handleSubmit = async (values: any) => {
    const translationsBody = {
      locale: locale!,
      data: JSON.stringify(values),
    };
    await createTranslations(translationsBody).unwrap();
  };

  const translationsDto = {
    user: (translations.user ?? {}) as CompanyTranslationsDto,
  };

  return (
    <Formik
      initialValues={translationsDto}
      onSubmit={handleSubmit}
      //        validate={createValidator(CreateTranslationDtoValidator)}
      validateOnChange={false}
      handleSubmit
    >
      {({ handleSubmit, handleChange, values, errors }) => (
        <form onSubmit={handleSubmit}>
          <Stack spacing={2}>
            <Stack>
              <Input
                value={values.user.identifier}
                name={'user.identifier'}
                label={`${t('translationsDashboard.identifier')}`}
                onChange={handleChange}
                error={(errors.user?.identifier as string) ?? undefined}
              />
            </Stack>
            <Stack>
              <Input
                value={values.user.areaLevel1}
                name={'user.areaLevel1'}
                label={`${t('translationsDashboard.areaLevel1')}`}
                onChange={handleChange}
                error={(errors.user?.areaLevel1 as string) ?? undefined}
              />
            </Stack>
            <Stack>
              <Input
                value={values.user.areaLevel2}
                name={'user.areaLevel2'}
                label={`${t('translationsDashboard.areaLevel2')}`}
                onChange={handleChange}
                error={(errors.user?.areaLevel2 as string) ?? undefined}
              />
            </Stack>
            <Stack>
              <Input
                value={values.user.areaLevel3}
                name={'user.areaLevel3'}
                label={`${t('translationsDashboard.areaLevel3')}`}
                onChange={handleChange}
                error={(errors.user?.areaLevel3 as string) ?? undefined}
              />
            </Stack>
            <FormHelperText error>
              {createResult.error && t('translationsDashboard.error')}
            </FormHelperText>

            <LoadingButton
              loading={createResult.isLoading}
              fullWidth
              type="submit"
              variant="contained"
            >
              {t('save')}
            </LoadingButton>
          </Stack>
        </form>
      )}
    </Formik>
  );
}
