import { IsString, IsNotEmpty, IsEmail } from 'class-validator';

export class CreateUserDto {
  constructor() {
    this.email = '';
    this.password = '';
  }

  @IsNotEmpty({ message: 'validations.email.empty' })
  @IsEmail(undefined, { message: 'validations.email.notEmail' })
  email: string;

  @IsNotEmpty({ message: 'validations.password.empty' })
  @IsString()
  password: string;
}
