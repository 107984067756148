import { styled } from '@mui/material/styles';
import {
  ListItemIcon,
  ListItemText,
  Avatar,
  Grid,
  Box,
  Drawer,
  Toolbar,
  List,
  Typography,
  IconButton,
  ListItem,
  ListItemButton,
  useTheme,
  useMediaQuery,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  SvgIcon,
} from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import { useDrawer } from './hooks/useDrawer';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'ramda';
import { useState } from 'react';
import { useAppMenu } from './hooks/useAppMenu';
import AppBarContent from './AppBarContent';
import { ExpandMore } from '@mui/icons-material';
import { User } from '../app/services/futbolProdeApi';
import { useUserPermissions } from './hooks/useUserPermissions';
import UserAvatar from './UserAvatar';
import { Routes, useCompanyRoutes } from '../router';
import { useTranslation } from 'react-i18next';

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
  matches?: boolean;
}

interface SideBarProps {
  children: JSX.Element;
}

const drawerWidth = 280;

const Children = styled('main', {
  shouldForwardProp: (prop) => prop !== 'open' && prop !== 'matches',
})<{
  open?: boolean;
  matches?: boolean;
}>(({ theme, open, matches }) => ({
  flexGrow: 1,
  minHeight: '100vh',
  backgroundColor: '#f1f5f9',
  maxWidth: '100%',
  padding: theme.spacing(3),
  paddingTop: 50,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(matches && !open && { marginLeft: `-${drawerWidth}px` }),
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open' && prop !== 'matches',
})<AppBarProps>(({ theme, open, matches }) => ({
  boxShadow: `0 0 7px 0 lightgrey`,
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: matches ? `calc(100% - ${drawerWidth}px)` : '',
    marginLeft: matches ? `${drawerWidth}px` : '',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function SideBar({ children }: SideBarProps) {
  const theme = useTheme();
  const isComputer = useMediaQuery(theme.breakpoints.up('md'));
  const { open, toggleDrawerState } = useDrawer(isComputer);
  const navigate = useNavigate();
  const [managerMenuIsCollapsed, setManagerMenuIsCollapsed] = useState(false);
  const { appRoute } = useCompanyRoutes();
  const { t } = useTranslation();

  // El sidebar solo se muestra si el usuario está logueado.

  const navigationAppBar = useAppMenu();
  const [generalMenu, ...managerMenu] = navigationAppBar;
  const onClickMenu = (link: string) => {
    navigate(appRoute(link));
    if (!isComputer) {
      toggleDrawerState();
    }
  };

  const { user, isImpersonating, isAdminOrReseller, impersonatedCompany } =
    useUserPermissions();

  const superiorMenu = !isAdminOrReseller
    ? [generalMenu].concat(managerMenu)
    : [generalMenu];

  const collapsibleMenu = isImpersonating ? managerMenu : [];

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar position="fixed" open={open} matches={isComputer} color="inherit">
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            onClick={toggleDrawerState}
            edge="start"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <AppBarContent />
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            backgroundColor: theme.palette.secondary.dark,
          },
        }}
        variant={!isComputer ? 'temporary' : 'persistent'}
        onClose={toggleDrawerState}
        anchor="left"
        open={open}
      >
        <Grid container justifyContent={'center'} textAlign="center" mb={4}>
          <Grid item pt={10}>
            <UserAvatar user={user! as User} />
          </Grid>
          <Grid item xs={12} pt={2}>
            <Typography variant="body1" color="secondary.contrastText">
              {user?.name ?? ''}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" color="secondary.contrastText">
              {user?.email ?? ''}
            </Typography>
          </Grid>
        </Grid>

        <List>
          <SideBarMenuButton
            label={t('sidebar.home')}
            Icon={HomeIcon}
            link={Routes.HOME}
            onClickMenu={onClickMenu}
          />
        </List>

        <SideBarMenu menu={superiorMenu} onClickMenu={onClickMenu} />

        {!isEmpty(collapsibleMenu) && (
          <Accordion
            expanded={!managerMenuIsCollapsed}
            onChange={(_e) =>
              setManagerMenuIsCollapsed(!managerMenuIsCollapsed)
            }
            sx={{
              backgroundColor: theme.palette.secondary.dark,
              color: theme.palette.secondary.contrastText,
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMore htmlColor={theme.palette.secondary.contrastText} />
              }
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Grid container flexDirection="row" alignItems="center">
                <Grid item mr={1}>
                  <Avatar src={impersonatedCompany?.isologoUrl} />
                </Grid>
                <Grid item>
                  <Typography>{impersonatedCompany?.name}</Typography>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <SideBarMenu menu={collapsibleMenu} onClickMenu={onClickMenu} />
            </AccordionDetails>
          </Accordion>
        )}
      </Drawer>
      <Children open={open} matches={isComputer}>
        {children}
      </Children>
    </Box>
  );
}

function SideBarMenu({
  menu,
  onClickMenu,
}: {
  menu: {
    items: any[];
    title: string;
    subtitle?: string | undefined;
    isAllowedToSee?: ((user: User) => boolean) | undefined;
  }[];
  onClickMenu: (link: string) => void;
}) {
  return (
    <>
      {menu.map(({ items, title, subtitle }, index) => (
        <SideBarMenuItem
          key={index}
          title={title}
          subtitle={subtitle}
          onClickMenu={onClickMenu}
          items={items}
        />
      ))}
    </>
  );
}

function SideBarMenuButton({
  Icon,
  link,
  onClickMenu,
  label,
  index = 0,
}: {
  Icon: typeof SvgIcon;
  link: string;
  onClickMenu: (link: string) => void;
  label: string;
  index?: number;
}) {
  const theme = useTheme();
  return (
    <ListItem disablePadding key={index}>
      <ListItemButton
        onClick={() => onClickMenu(link)}
        sx={{ padding: '5px 10px 5px 25px' }}
      >
        <ListItemIcon
          sx={{ color: theme.palette.secondary.contrastText, minWidth: '45px' }}
        >
          <Icon />
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={{
            color: theme.palette.secondary.contrastText,
            fontWeight: 500,
          }}
          primary={label}
        />
      </ListItemButton>
    </ListItem>
  );
}

export function SideBarMenuItem({
  title,
  subtitle,
  items,
  onClickMenu,
}: {
  title: string;
  subtitle: string | undefined;
  items: any[];
  onClickMenu: (link: string) => void;
}) {
  return (
    <Grid container mb={2}>
      <Grid item xs={12} pl={2}>
        <Typography
          variant="body2"
          fontWeight={500}
          textTransform={'uppercase'}
          color="primary.main"
        >
          {title}
        </Typography>
        <Typography variant="subtitle2" fontWeight={500} color="textSecondary">
          {subtitle}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <List>
          {items.map(({ label, Icon, link }, index) => (
            <SideBarMenuButton
              label={label}
              Icon={Icon}
              link={link}
              index={index}
              key={index}
              onClickMenu={onClickMenu}
            />
          ))}
        </List>
      </Grid>
    </Grid>
  );
}
