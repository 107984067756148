import { IconButton, IconButtonProps } from '@mui/material';

type LoadingIconButtonProps = IconButtonProps & { loading: boolean };

export default function LoadingIconButton(props: LoadingIconButtonProps) {
  const { loading, children } = props;
  const rotatingAnimation = {
    animation: 'spin 2s linear infinite',
    '@keyframes spin': {
      '0%': {
        transform: 'rotate(360deg)',
      },
      '100%': {
        transform: 'rotate(0deg)',
      },
    },
  };

  return (
    <IconButton
      {...props}
      sx={loading ? rotatingAnimation : {}}
      disabled={loading}
    >
      {children}
    </IconButton>
  );
}
