import {
  Box,
  CardContent,
  Stack,
  Typography,
  TypographyProps,
} from '@mui/material';
import { RoundedCard } from './MuiExtensions';
import { useTranslation } from 'react-i18next';
import { Unless } from 'react-if';
import { isNil } from 'ramda';
import { Nullable } from 'typescript-nullable';

export function PageSection({
  title,
  subtitle,
  children,
  actions,
}: {
  title?: Nullable<string>;
  subtitle?: string;
  children: JSX.Element | JSX.Element[];
  actions?: JSX.Element;
}) {
  const { t } = useTranslation();
  return (
    <Stack spacing={1}>
      <RoundedCard>
        <CardContent>
          <Stack spacing={2}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Unless condition={isNil(title)}>
                <Typography variant="h5" fontWeight="bold">
                  {t(title!)}
                  <Unless condition={isNil(subtitle)}>
                    <Typography variant="subtitle1" color="textSecondary">
                      {t(subtitle!, { defaultValue: '' })}
                    </Typography>
                  </Unless>
                </Typography>
                {actions}
              </Unless>
            </Box>
            {children}
          </Stack>
        </CardContent>
      </RoundedCard>
    </Stack>
  );
}

export const PageSectionSubtitle = (props: TypographyProps) => (
  <Typography {...props} pt={2} variant="h6" />
);
