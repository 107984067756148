import {
  CreateDreamFinalSettingsDto,
  DreamFinalSettings,
  useDreamFinalSettingsControllerCreateMutation,
  useDreamFinalSettingsControllerUpdateMutation,
  useTournamentsControllerFindOneQuery,
} from '../../app/services/futbolProdeApi';
import { Formik } from 'formik';
import createValidator from 'class-validator-formik';
import { CreateDreamFinalDtoValidator } from './CreateDreamFinalDtoValidator';
import {
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Input } from '../news/Input';
import { LoadingButton } from '@mui/lab';
import { partition } from 'ramda';
import { useCompanyTranslations } from '@futbolprode/ui-common';

export function DreamFinalForm({
  tournamentId,
  dreamFinalSettings,
}: {
  tournamentId: number;
  dreamFinalSettings?: DreamFinalSettings;
}) {
  const { t } = useTranslation();
  const { roundName } = useCompanyTranslations();
  const { data: tournament, isLoading } = useTournamentsControllerFindOneQuery(
    tournamentId.toString(),
  );
  const [createDreamFinalSettings, createResult] =
    useDreamFinalSettingsControllerCreateMutation();
  const [updateDreamFinalSettings, updateResult] =
    useDreamFinalSettingsControllerUpdateMutation();
  const handleSubmit = (
    values: CreateDreamFinalSettingsDto & { isEnabled: boolean },
  ) => {
    if (dreamFinalSettings) {
      updateDreamFinalSettings({
        id: dreamFinalSettings!.id.toString(),
        updateDreamFinalSettingsDto: values,
      });
    } else {
      createDreamFinalSettings(values);
    }
  };
  const dreamFinalDto = {
    limitRoundId: dreamFinalSettings?.limitRound.id ?? '',
    tournamentId:
      dreamFinalSettings?.tournament.id.toString() ?? tournament?.id,
    awardedPositions: dreamFinalSettings?.awardedPositions ?? 2,
    awardedPoints: dreamFinalSettings?.awardedPoints ?? 5,
    isEnabled: dreamFinalSettings?.isEnabled ?? false,
  };

  const parseTranslation = (translation: any) =>
    partition((it) => !'0123456789'.includes(it), translation).map((it) =>
      it.join(''),
    );
  return (
    <Formik
      initialValues={dreamFinalDto}
      onSubmit={(input) =>
        handleSubmit({
          isEnabled: input.isEnabled,
          awardedPositions: +input.awardedPositions,
          awardedPoints: +input.awardedPoints,
          limitRound: { id: +input.limitRoundId },
          tournament: { id: tournament!.id },
        })
      }
      validate={createValidator(CreateDreamFinalDtoValidator)}
      validateOnChange={true}
    >
      {({ handleSubmit, handleChange, values, errors }) => (
        <form onSubmit={handleSubmit}>
          <fieldset style={{ border: 'none' }}>
            <Stack spacing={2}>
              <Stack>
                <FormControlLabel
                  control={
                    <Switch
                      id={'isEnabled'}
                      checked={values.isEnabled}
                      onChange={handleChange}
                      name={'isEnabled'}
                    />
                  }
                  label={t('dreamFinalDashboard.isEnabled')}
                />
              </Stack>
              <Stack>
                <InputLabel htmlFor={'limitRoundId'}>
                  {`${t('dreamFinalDashboard.limitRound')} *`}
                </InputLabel>
                <Select
                  name={'limitRoundId'}
                  id={'limitRoundId'}
                  value={values.limitRoundId}
                  label={`${t('dreamFinalDashboard.limitRound')} *`}
                  onChange={handleChange}
                  disabled={!values.isEnabled}
                >
                  {tournament?.rounds.map((round) => (
                    <MenuItem key={round.id} value={round.id}>
                      {roundName(round)}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error>
                  {t(errors.limitRoundId ?? '')}
                </FormHelperText>
                <Input
                  type="number"
                  value={values.awardedPositions}
                  name={'awardedPositions'}
                  label={`${t('dreamFinalDashboard.awardedPositions')} *`}
                  onChange={handleChange}
                  disabled={!values.isEnabled}
                  error={
                    !errors.awardedPositions
                      ? undefined
                      : t(parseTranslation(errors.awardedPositions)[0], {
                          value: parseTranslation(errors.awardedPositions)[1],
                        }) ?? undefined
                  }
                />
                <Input
                  type="number"
                  value={values.awardedPoints}
                  name={'awardedPoints'}
                  label={`${t('dreamFinalDashboard.awardedPoints')} *`}
                  onChange={handleChange}
                  disabled={!values.isEnabled}
                  error={
                    !errors.awardedPoints
                      ? undefined
                      : t(parseTranslation(errors.awardedPoints)[0], {
                          value: parseTranslation(errors.awardedPoints)[1],
                        }) ?? undefined
                  }
                />
              </Stack>
              <LoadingButton
                disabled={isLoading}
                loading={updateResult.isLoading || createResult.isLoading}
                fullWidth
                type="submit"
                variant="contained"
              >
                {t('save')}
              </LoadingButton>
            </Stack>
          </fieldset>
        </form>
      )}
    </Formik>
  );
}
