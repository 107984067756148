import { skipToken } from '@reduxjs/toolkit/dist/query';
import { isNil } from 'ramda';
import { useState } from 'react';
import {
  useRankingsControllerTournamentRankingQuery,
  useRankingsControllerRoundRankingQuery,
  useRankingsControllerGlobalRankingQuery,
  Round,
  Tournament,
  useRankingsControllerTournamentRankingByAreaQuery,
  useRankingsControllerRoundRankingByAreaQuery,
  useRankingsControllerGlobalRankingByAreaQuery,
} from '../../app/services/futbolProdeApi';
import { PAGE_SIZE } from './RankingsDashboard';

export type RankingGrouping = 'user' | 'area';

export interface RankingQuery {
  tournament: Tournament | null;
  round: Round | null;
  search: string;
  grouping: RankingGrouping;
}

export function useTournamentRankingQuery({
  tournament,
  round,
  search,
  grouping,
}: RankingQuery) {
  const [page, setPage] = useState(0);
  const paginationParams = { skip: page * PAGE_SIZE, take: PAGE_SIZE, search };

  const userTournamentRanking = useRankingsControllerTournamentRankingQuery(
    grouping === 'user' && tournament && isNil(round)
      ? { id: tournament.id, ...paginationParams }
      : skipToken,
  );

  const areaTournamentRanking =
    useRankingsControllerTournamentRankingByAreaQuery(
      grouping === 'area' && tournament && isNil(round)
        ? { id: tournament.id, ...paginationParams }
        : skipToken,
    );

  const userRoundRanking = useRankingsControllerRoundRankingQuery(
    round ? { id: round.id, ...paginationParams } : skipToken,
  );

  const areaRoundRanking = useRankingsControllerRoundRankingByAreaQuery(
    round ? { id: round.id, ...paginationParams } : skipToken,
  );

  const userGlobalRanking = useRankingsControllerGlobalRankingQuery(
    isNil(tournament) ? paginationParams : skipToken,
  );

  const areaGlobalRanking = useRankingsControllerGlobalRankingByAreaQuery(
    isNil(tournament) ? paginationParams : skipToken,
  );

  const chooseByGrouping = <T, U>(userQuery: T, areaQuery: U) =>
    grouping === 'user' ? userQuery : areaQuery;

  const query = round
    ? chooseByGrouping(userRoundRanking, areaRoundRanking)
    : tournament
    ? chooseByGrouping(userTournamentRanking, areaTournamentRanking)
    : chooseByGrouping(userGlobalRanking, areaGlobalRanking);

  return {
    query,
    page,
    setPage,
  };
}
