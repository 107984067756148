import {
  Navigate,
  createBrowserRouter,
  useParams,
  useLocation,
} from 'react-router-dom';
import AppLayout from './layouts/AppLayout';
import TournamentsDashboard from './features/tournaments/TournamentsDashboard';
import TournamentDetails from './features/tournaments/TournamentDetails';
import Login from './features/auth/Login';
import { useAuth } from '@futbolprode/ui-common';
import EmptyLayout from './layouts/EmptyLayout';
import { isEmpty, isNil } from 'ramda';
import RankingsDashboard from './features/rankings/RankingsDashboard';
import ImportUsersDashboard from './features/invitations/ImportUsersDashboard';
import { InvitationsPage } from './features/users/InvitationsPage';
import { ActiveUsersPage } from './features/users/ActiveUsersPage';
import { RssNewsDashboard } from './features/news/RssNewsDashboard';
import { CustomNewsDashboard } from './features/news/CustomNewsDashboard';
import { CustomNewsEdit } from './features/news/CustomNewsEdit';
import { CustomNewsCreate } from './features/news/CustomNewsCreate';
import { AppearanceDashboard } from './features/appearance/AppearanceDashboard';
import { RegularCompaniesDashboard } from './features/users/RegularCompaniesDashboard';
import { ResellersDashboard } from './features/users/ResellersDashboard';
import { CompanyEdit } from './features/users/CompanyEdit';
import { CompanyCreate } from './features/users/CompanyCreate';
import { CompanyTranslationsDashboard } from './features/translations/CompanyTranslationsDashboard';
import { CommonTranslationsDashboard } from './features/translations/CommonTranslationsDashboard';
import { CompanyManagers } from './features/users/CompanyManagers';
import { CombinedRoundsDashboard } from './features/rankings/combinedRounds/CombinedRoundsDashboard';
import { CreateCombinedRounds } from './features/rankings/combinedRounds/CreateCombinedRounds';
import { EditCombinedRounds } from './features/rankings/combinedRounds/EditCombinedRounds';
import { DreamFinalDashboard } from './features/dreamFinal/DreamFinalDashboard';
import { TeamsDashboard } from './features/tournaments/TeamsDashboard';
import { GameRulesDashboard } from './features/gameRules/GameRulesDashboard';
import { TermsAndConditionsDashboard } from './features/gameRules/TermsAndConditionsDashboard';
import { ActiveSectionsDashboard } from './features/users/ActiveSectionsDashboard';
import { AuthenticationPage } from './features/users/AuthenticationPage';
import BannersPage from './features/banners/BannersPage';
import { HomePage } from './features/home/HomePage';
import { UserEditPermissionsDashboard } from './features/users/UserEditPermissionsDashboard';
import { GlobalSettingsDashboard } from './features/globalSettings/GlobalSettingsDashboard';
import TeamEditPage from './features/tournaments/TeamEditPage';
import TeamCreatePage from './features/tournaments/TeamCreatePage';
import JobLogsPage from './features/stats/JobLogsPage';
import AnnouncementsPage from './features/announcements/AnnouncementsPage';
import ComingSoonModePage from './features/announcements/ComingSoonModePage';
import DomainsDashboard from './features/domains/DomainsDashboard';
import TriviasPage from './features/trivias/TriviasPage';
import TriviaEditPage from './features/trivias/TriviaEditPage';
import TriviaCreatePage from './features/trivias/TriviaCreatePage';
import queryString from 'query-string';
import AreasPage from './features/areas/AreasPage';
import UnhandledErrorPage from './UnhandledErrorPage';
import InvitationCreatePage from './features/users/InvitationCreatePage';
import InvitationEditPage from './features/users/InvitationEditPage';
import UserEditPage from './features/users/UserEditPage';
import AwardsPage from './features/awards/AwardsPage';

const rootRoute = '/:company?';
const appRoute = `${rootRoute}/app`;

export const createPath = (entity: string) => `${entity}/new`;
export const updatePath = (entity: string) => `${entity}/:id`;

export const Routes = {
  ROOT: rootRoute,
  APP: appRoute,
  HOME: '',
  ACTIVE_SECTIONS: `sections`,
  ACTIVE_USERS: 'users/active',
  AUTHENTICATION: 'authentication',
  ANNOUNCEMENTS: 'announcements',
  APPEARANCE: 'appearance',
  AREAS: 'areas',
  AWARDS: 'awards',
  BANNERS: `banners`,
  COMBINED_ROUNDS: 'combinedRounds',
  COMBINED_ROUNDS_CREATE: 'combinedRounds/new',
  COMBINED_ROUNDS_EDIT: 'combinedRounds/:id',
  COMING_SOON: 'coming-soon',
  COMPANIES: 'companies',
  COMPANIES_CREATE: `companies/new`,
  COMPANIES_EDIT: `companies/:id`,
  COMPANIES_MANAGERS: `companies/:id/managers`,
  COMMON_TRANSLATIONS: 'commontranslations',
  COMPANY_TRANSLATIONS: 'companytranslations',
  CUSTOM_NEWS: `news/custom`,
  CUSTOM_NEWS_CREATE: `news/custom/new`,
  CUSTOM_NEWS_EDIT: `news/custom/:id`,
  DOMAINS: 'domains',
  DREAM_FINAL: 'dreamFinal',
  GAME_RULES: `gamerules`,
  GLOBAL: `global`,
  IMPORT_USERS: 'users/import',
  LOGIN: `login`,
  JOB_LOGS: 'joblogs',
  NEWS: `news`,
  INVITATIONS: 'invitations',
  RANKINGS: 'rankings',
  RESELLERS: 'resellers',
  RESELLERS_CREATE: `resellers/new`,
  RESELLERS_EDIT: `resellers/:id`,
  RESELLERS_MANAGERS: `resellers/:id/managers`,
  STATS: `stats`,
  TEAM_CREATE: `teams/new`,
  TEAM_EDIT: `teams/:id`,
  TEAMS: `teams`,
  TERMS_AND_CONDITIONS: `termsandconditions`,
  TRIVIA_TEMPLATES: 'trivias/templates',
  TRIVIAS: 'trivias',
  TOURNAMENTS: `tournaments`,
  USERS_EDIT: 'users/active/:id',
  USERS_PERMISSIONS: 'users/permissions',
};

// Esto está acá porque depende de Routes y si lo ponés en otro archivo pincha
export const TriviaKindProps = {
  template: {
    baseBreadcrumb: { label: 'sidebar.globalSettings' },
    route: Routes.TRIVIA_TEMPLATES,
    queryParams: { source: 'template' },
  },
  company: {
    baseBreadcrumb: { label: 'sidebar.game' },
    route: Routes.TRIVIAS,
    queryParams: { source: 'company' },
  },
};

export function useCompanyRoutes() {
  const { company } = useParams();
  const companyPrefix = company ? `/${company}` : '';

  return {
    appRoute(path = '', params = {}) {
      const query = queryString.stringify(params);
      const route = `${companyPrefix}/app/${path}`;
      return isEmpty(query) ? route : `${route}?${query}`;
    },

    rootRoute(path = '') {
      return `${companyPrefix}/${path}`;
    },
  };
}

function CheckLoginState() {
  const { user } = useAuth();
  const { appRoute, rootRoute } = useCompanyRoutes();
  const initialRoute = isNil(user) ? rootRoute(Routes.LOGIN) : appRoute();
  return <Navigate to={initialRoute} />;
}
const ALLOWED_ROLES = ['admin', 'reseller', 'manager'];
function RequireAuth({ children }: { children: JSX.Element }) {
  const { user } = useAuth();
  const { rootRoute } = useCompanyRoutes();
  const location = useLocation();

  return user && ALLOWED_ROLES.includes(user.role) ? (
    children
  ) : (
    <Navigate to={rootRoute(Routes.LOGIN)} state={{ from: location }} replace />
  );
}

const router = createBrowserRouter([
  {
    path: Routes.ROOT,
    errorElement: <UnhandledErrorPage />,
    element: <EmptyLayout />,
    children: [
      { index: true, element: <CheckLoginState /> },
      { path: Routes.LOGIN, element: <Login /> },
    ],
  },
  {
    path: Routes.APP,
    errorElement: <UnhandledErrorPage />,
    element: (
      <RequireAuth>
        <AppLayout />
      </RequireAuth>
    ),
    children: [
      { index: true, element: <HomePage /> },
      { path: Routes.ACTIVE_SECTIONS, element: <ActiveSectionsDashboard /> },
      { path: Routes.ACTIVE_USERS, element: <ActiveUsersPage /> },
      { path: Routes.ANNOUNCEMENTS, element: <AnnouncementsPage /> },
      { path: Routes.APPEARANCE, element: <AppearanceDashboard /> },
      { path: Routes.AREAS, element: <AreasPage /> },
      { path: Routes.AUTHENTICATION, element: <AuthenticationPage /> },
      { path: Routes.AWARDS, element: <AwardsPage /> },
      { path: Routes.BANNERS, element: <BannersPage /> },
      { path: Routes.COMBINED_ROUNDS, element: <CombinedRoundsDashboard /> },
      {
        path: Routes.COMBINED_ROUNDS_CREATE,
        element: <CreateCombinedRounds />,
      },
      { path: Routes.COMBINED_ROUNDS_EDIT, element: <EditCombinedRounds /> },
      { path: Routes.COMPANIES, element: <RegularCompaniesDashboard /> },
      { path: Routes.COMPANIES_CREATE, element: <CompanyCreate /> },
      { path: Routes.COMPANIES_EDIT, element: <CompanyEdit /> },
      { path: Routes.COMPANIES_MANAGERS, element: <CompanyManagers /> },
      { path: Routes.COMING_SOON, element: <ComingSoonModePage /> },
      {
        path: Routes.COMPANY_TRANSLATIONS,
        element: <CompanyTranslationsDashboard />,
      },
      {
        path: Routes.COMMON_TRANSLATIONS,
        element: <CommonTranslationsDashboard />,
      },
      { path: Routes.CUSTOM_NEWS, element: <CustomNewsDashboard /> },
      { path: Routes.CUSTOM_NEWS_CREATE, element: <CustomNewsCreate /> },
      { path: Routes.CUSTOM_NEWS_EDIT, element: <CustomNewsEdit /> },
      { path: Routes.DOMAINS, element: <DomainsDashboard /> },
      { path: Routes.DREAM_FINAL, element: <DreamFinalDashboard /> },
      { path: Routes.GAME_RULES, element: <GameRulesDashboard /> },
      { path: Routes.GLOBAL, element: <GlobalSettingsDashboard /> },
      { path: Routes.IMPORT_USERS, element: <ImportUsersDashboard /> },
      { path: Routes.INVITATIONS, element: <InvitationsPage /> },
      {
        path: createPath(Routes.INVITATIONS),
        element: <InvitationCreatePage />,
      },
      {
        path: updatePath(Routes.INVITATIONS),
        element: <InvitationEditPage />,
      },
      { path: Routes.JOB_LOGS, element: <JobLogsPage /> },
      { path: Routes.NEWS, element: <RssNewsDashboard /> },
      {
        path: updatePath(Routes.TOURNAMENTS),
        element: <TournamentDetails />,
      },
      { path: Routes.RANKINGS, element: <RankingsDashboard /> },
      { path: Routes.RESELLERS, element: <ResellersDashboard /> },
      { path: Routes.RESELLERS_CREATE, element: <CompanyCreate isReseller /> },
      { path: Routes.RESELLERS_EDIT, element: <CompanyEdit isReseller /> },
      { path: Routes.RESELLERS_MANAGERS, element: <CompanyManagers /> },
      { path: Routes.STATS, element: <HomePage /> },
      { path: Routes.TEAM_CREATE, element: <TeamCreatePage /> },
      { path: Routes.TEAM_EDIT, element: <TeamEditPage /> },
      { path: Routes.TEAMS, element: <TeamsDashboard /> },
      {
        path: Routes.TERMS_AND_CONDITIONS,
        element: <TermsAndConditionsDashboard />,
      },
      { path: Routes.TOURNAMENTS, element: <TournamentsDashboard /> },
      {
        path: Routes.TRIVIA_TEMPLATES,
        element: <TriviasPage kind="template" />,
      },
      { path: Routes.TRIVIAS, element: <TriviasPage kind="company" /> },
      {
        path: updatePath(Routes.TRIVIA_TEMPLATES),
        element: <TriviaEditPage kind="template" />,
      },
      {
        path: createPath(Routes.TRIVIA_TEMPLATES),
        element: <TriviaCreatePage kind="template" />,
      },
      {
        path: updatePath(Routes.TRIVIAS),
        element: <TriviaEditPage kind="company" />,
      },
      {
        path: createPath(Routes.TRIVIAS),
        element: <TriviaCreatePage kind="company" />,
      },
      { path: Routes.USERS_EDIT, element: <UserEditPage /> },
      {
        path: Routes.USERS_PERMISSIONS,
        element: <UserEditPermissionsDashboard />,
      },
    ],
  },
]);

export default router;
