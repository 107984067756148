import { memo, useState } from 'react';
import {
  Grid,
  Typography,
  FormHelperText,
  InputAdornment,
  IconButton,
  Box,
  Avatar,
  useTheme,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useLocation, useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import { createValidator } from 'class-validator-formik';
import { CreateUserDto } from './CreateUserDto';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
  LoginUserDto,
  useAuthControllerLoginMutation,
  useCompaniesControllerFindMeQuery,
} from '../../app/services/futbolProdeApi';
import { instanceToPlain } from 'class-transformer';
import { useTranslation } from 'react-i18next';
import { useCompanyRoutes } from '../../router';
import { Input } from './LoginInput';

function LoginForm() {
  const { appRoute } = useCompanyRoutes();
  const theme = useTheme();
  const { state } = useLocation();
  const [login, result] = useAuthControllerLoginMutation();
  const [showPassword, setShowPassword] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data: company } = useCompaniesControllerFindMeQuery();

  const handleLogin = async (c: CreateUserDto) => {
    await login(instanceToPlain(c) as LoginUserDto).unwrap();
    navigate(state?.from?.pathname ?? appRoute());
  };

  const handleSubmit = (values: CreateUserDto) => {
    handleLogin(values);
  };

  const user = new CreateUserDto();

  return (
    <Formik
      initialValues={user}
      onSubmit={handleSubmit}
      validate={createValidator(CreateUserDto)}
      validateOnChange={false}
    >
      {({ handleSubmit, handleChange, values, errors }) => (
        <form onSubmit={handleSubmit}>
          <Grid
            container
            justifyContent="center"
            maxWidth="80%"
            sx={{ margin: '0 auto' }}
          >
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'flex-start',
              }}
            >
              <Avatar
                src={company?.isologoUrl}
                alt="company-isologo"
                style={{ width: 72, height: 72, marginBottom: 30 }}
              />
            </Box>
            <Grid item xs={12}>
              <Typography
                variant="h3"
                color="primary"
                sx={{ marginBottom: 1, fontWeight: 600 }}
              >
                {t('login.signin')}
              </Typography>
            </Grid>
            <Grid item xs={12} pt={3}>
              <Input
                value={values.email}
                name={'email'}
                label={`${t('user.identifier', { ns: 'company' })} *`}
                onChange={handleChange}
                error={errors.email}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                value={values.password}
                name={'password'}
                label={`${t('login.password')} *`}
                onChange={handleChange}
                error={errors.password}
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Toggle password visibility"
                        onClick={() => setShowPassword((show) => !show)}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormHelperText error>
                {result.error && t('login.userInvalid')}
              </FormHelperText>
            </Grid>
            <Grid item xs={12} pt={2}>
              <LoadingButton
                loading={result.isLoading}
                fullWidth
                type="submit"
                variant="contained"
                size="large"
                sx={{
                  borderRadius: 25,
                  backgroundColor: theme.palette.primary.main,
                  fontWeight: 600,
                  textTransform: 'capitalize',
                  marginTop: 5,
                }}
              >
                {t('login.signin')}
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
}

export default memo(LoginForm);
