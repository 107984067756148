import { FormHelperText, Stack, Switch, FormControlLabel } from '@mui/material';
import {
  Company,
  UpdateCompanyDto,
  useCompaniesControllerUpdateMutation,
} from '../../app/services/futbolProdeApi';
import { LoadingButton } from '@mui/lab';
import { Formik } from 'formik';
import { t } from 'i18next';
import { PageSection, PageSectionSubtitle } from '../../common/PageSection';
import { mergeDeepRight } from 'ramda';

const defaultValues: UpdateCompanyDto = {
  googleServicesConfig: {
    allowSearchEngineIndexing: false,
  },
};

export function ActiveSectionsForm({ company }: { company?: Company }) {
  const [updateCompany, updateResult] = useCompaniesControllerUpdateMutation();
  const handleSubmit = async (formValues: any) => {
    if (company) {
      await updateCompany({
        id: company!.id.toString(),
        updateCompanyDto: formValues,
      });
    }
  };

  const companyDto = mergeDeepRight(defaultValues, company ?? {});

  return (
    <Formik
      initialValues={companyDto}
      onSubmit={handleSubmit}
      validateOnChange={false}
      handleSubmit
    >
      {({ handleSubmit, handleChange, values }) => (
        <form onSubmit={handleSubmit}>
          <Stack spacing={2}>
            <PageSection title="activeSectionsPage.general.title">
              <FormControlLabel
                control={
                  <Switch
                    id={'disableTournamentDetails'}
                    checked={values.disableTournamentDetails}
                    onChange={handleChange}
                    name={'disableTournamentDetails'}
                  />
                }
                label={t('activeSectionsPage.general.disableTournamentDetails')}
              />
              <FormControlLabel
                control={
                  <Switch
                    id={'disableInitialTour'}
                    checked={values.disableInitialTour}
                    onChange={handleChange}
                    name={'disableInitialTour'}
                  />
                }
                label={t('activeSectionsPage.general.disableInitialTour')}
              />
              <FormControlLabel
                control={
                  <Switch
                    id={'googleServicesConfig.allowSearchEngineIndexing'}
                    checked={
                      values.googleServicesConfig!.allowSearchEngineIndexing
                    }
                    onChange={handleChange}
                    name={'googleServicesConfig.allowSearchEngineIndexing'}
                  />
                }
                label={t(
                  'entity.company.googleServicesConfig.allowSearchEngineIndexing',
                )}
              />
            </PageSection>
            <PageSection title="sidebar.trivias">
              <FormControlLabel
                control={
                  <Switch
                    id={'disableTrivias'}
                    checked={values.disableTrivias}
                    onChange={handleChange}
                    name={'disableTrivias'}
                  />
                }
                label={t('activeSectionsPage.trivias.disableTrivias')}
              />
            </PageSection>
            <PageSection title="sidebar.news">
              <FormControlLabel
                control={
                  <Switch
                    id={'disableNews'}
                    checked={values.disableNews}
                    onChange={handleChange}
                    name={'disableNews'}
                  />
                }
                label={t('newsDashboard.disableNews')}
              />
              <FormControlLabel
                control={
                  <Switch
                    id={'disableNewsFromHome'}
                    checked={values.disableNewsFromHome}
                    onChange={handleChange}
                    name={'disableNewsFromHome'}
                  />
                }
                label={t('newsDashboard.disableNewsFromHome')}
              />
            </PageSection>
            <PageSection title="sidebar.rankings">
              <PageSectionSubtitle>
                {t('activeSectionsPage.rankings.positionsSection')}
              </PageSectionSubtitle>
              <FormControlLabel
                control={
                  <Switch
                    id={'disableRankingsUserNames'}
                    checked={values.disableRankingsUserNames}
                    onChange={handleChange}
                    name={'disableRankingsUserNames'}
                  />
                }
                label={t('activeSectionsPage.rankings.hideUserNames')}
              />
              <FormControlLabel
                control={
                  <Switch
                    id={'disableRankingsTable'}
                    checked={values.disableRankingsTable}
                    onChange={handleChange}
                    name={'disableRankingsTable'}
                  />
                }
                label={t('activeSectionsPage.rankings.hideTable')}
              />
              <PageSectionSubtitle>
                {t('activeSectionsPage.rankings.mainPage')}
              </PageSectionSubtitle>
              <FormControlLabel
                control={
                  <Switch
                    id={'disableRankingsMainPageCards'}
                    checked={values.disableRankingsMainPageCards}
                    onChange={handleChange}
                    name={'disableRankingsMainPageCards'}
                  />
                }
                label={t('activeSectionsPage.rankings.hideMainPageCards')}
              />
              <FormControlLabel
                control={
                  <Switch
                    id={'disableRankingsMainPageSummary'}
                    checked={values.disableRankingsMainPageSummary}
                    onChange={handleChange}
                    name={'disableRankingsMainPageSummary'}
                  />
                }
                label={t('activeSectionsPage.rankings.hideMainPageSummary')}
              />
              <FormControlLabel
                control={
                  <Switch
                    id={'disableAreaRankingsMainPageSummary'}
                    checked={values.disableAreaRankingsMainPageSummary}
                    onChange={handleChange}
                    name={'disableAreaRankingsMainPageSummary'}
                  />
                }
                label={t(
                  'activeSectionsPage.rankings.hideMainPageAreasSummary',
                )}
              />
            </PageSection>
            <PageSection title="activeSectionsPage.auth">
              <FormControlLabel
                control={
                  <Switch
                    id={'disableRegister'}
                    checked={values.disableRegister}
                    onChange={handleChange}
                    name={'disableRegister'}
                  />
                }
                label={t('activeSectionsPage.disableRegister')}
              />
              <FormControlLabel
                control={
                  <Switch
                    id={'disableGoogleLogin'}
                    checked={values.disableGoogleLogin}
                    onChange={handleChange}
                    name={'disableGoogleLogin'}
                  />
                }
                label={t('activeSectionsPage.disableGoogleLogin')}
              />
            </PageSection>
            <FormHelperText error>
              {updateResult.error && t('companiesDashboard.error')}
            </FormHelperText>
            <LoadingButton
              loading={updateResult.isLoading}
              fullWidth
              type="submit"
              variant="contained"
            >
              {t('save')}
            </LoadingButton>
          </Stack>
        </form>
      )}
    </Formik>
  );
}
