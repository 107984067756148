import { useAuth } from '@futbolprode/ui-common';
import { LoadingButton } from '@mui/lab';
import {
  LinearProgress,
  FormHelperText,
  Stack,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Else, If, Then } from 'react-if';
import {
  CreateGlobalSettingsDto,
  useGlobalSettingsControllerFindAllQuery,
  useGlobalSettingsControllerUpsertMutation,
} from '../../app/services/futbolProdeApi';
import { PageSection } from '../../common/PageSection';
import Page from '../../common/Page';

export function GlobalSettingsDashboard() {
  const { data: globalSettings, isLoading } =
    useGlobalSettingsControllerFindAllQuery();
  const [upsertGlobalSettings, updateResult] =
    useGlobalSettingsControllerUpsertMutation();
  const { t } = useTranslation();
  const handleSubmit = (values: CreateGlobalSettingsDto) => {
    upsertGlobalSettings(values);
  };
  const globalSettingsDto = {
    impersonationEnabled: globalSettings?.impersonationEnabled,
  } as CreateGlobalSettingsDto;
  const { user } = useAuth();
  return (
    <Page
      breadcrumbs={[{ label: 'sidebar.globalSettings' }]}
      title="sidebar.globalSettings"
    >
      <If condition={isLoading || !user}>
        <Then>
          <LinearProgress />
        </Then>
        <Else>
          <PageSection title="">
            <Formik
              initialValues={globalSettingsDto}
              onSubmit={handleSubmit}
              validateOnChange={false}
              handleSubmit
            >
              {({ handleSubmit, handleChange, values }) => (
                <form onSubmit={handleSubmit}>
                  <Stack spacing={2}>
                    <Stack>
                      <FormControlLabel
                        disabled={user!.role !== 'admin'}
                        control={
                          <Checkbox
                            id={'impersonationEnabled'}
                            checked={values.impersonationEnabled}
                            onChange={handleChange}
                            name={'impersonationEnabled'}
                          />
                        }
                        label={t(
                          'globalSettingsDashboard.impersonationEnabled',
                        )}
                      />
                    </Stack>

                    <FormHelperText error>
                      {updateResult.error && t('error')}
                    </FormHelperText>
                    <LoadingButton
                      loading={updateResult.isLoading}
                      fullWidth
                      type="submit"
                      variant="contained"
                    >
                      {t('save')}
                    </LoadingButton>
                  </Stack>
                </form>
              )}
            </Formik>
          </PageSection>
        </Else>
      </If>
    </Page>
  );
}
