import { useTranslation } from 'react-i18next';
import { AppearanceDto } from './AppearanceDashboard';

export const LoginPreviewSvg = ({
  appearance,
}: {
  appearance: AppearanceDto;
}) => {
  const { t } = useTranslation();
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 489.5 287.8"
    >
      <style type="text/css">
        {`
        .login-st0{fill:#A2AEBA;}
        .login-st1{fill:#FFFFFF;}
        .login-st2{fill:${appearance.secondaryColor};}
        .login-st3{fill:${appearance.primaryColor};}
        .login-labels{fill:${
          appearance.customColors?.loginLabelsColor ?? appearance.primaryColor
        };}
        .login-welcome-message{fill:${
          appearance.customColors?.loginWelcomeMessageColor ?? '#ffffff'
        }}
        .login-st4{font-family:'Poppins-Bold';}
        .login-st5{font-size:20.0537px;}
        .login-st6{font-family:'Poppins-Regular';}
        .login-st7{font-size:10px;}
        .login-st8{fill:#EDEDED;}
        .login-st9{font-size:11.9358px;}
    `}
      </style>
      <g>
        <g>
          <g>
            <path
              className="login-st0"
              d="M485.5,287.8H4c-2.2,0-4-1.8-4-4V4c0-2.2,1.8-4,4-4h481.5c2.2,0,4,1.8,4,4v279.8
                    C489.5,286,487.7,287.8,485.5,287.8z"
            />
          </g>
          <path
            className="login-st1"
            d="M483.3,284.9H6.2c-2.2,0-4.1-1.8-4.1-4V27.1h485.3v253.8C487.4,283,485.5,284.9,483.3,284.9z"
          />
          <circle className="login-st1" cx="9.8" cy="12.7" r="3.4" />
          <circle className="login-st1" cx="18.6" cy="12.7" r="3.4" />
          <circle className="login-st1" cx="27.4" cy="12.7" r="3.4" />
          <path
            className="login-st2"
            d="M487.4,27.1H244.7v257.8h238.1c2.5,0,4.6-2,4.6-4.6V27.1z"
          />
          <defs>
            <path
              id="SVGID_1_"
              d="M487.4,27.1H244.7v257.8h238.1c2.5,0,4.6-2,4.6-4.6V27.1z"
            />
          </defs>
          <clipPath id="SVGID_00000076590630470504722440000013843618135735127175_">
            <use href="#SVGID_1_" style={{ overflow: 'visible' }} />
          </clipPath>
          <g clip-path="url(#SVGID_00000076590630470504722440000013843618135735127175_)">
            <image
              style={{ overflow: 'visible' }}
              width="310"
              height="268"
              href={appearance.loginImageUrl}
              transform="matrix(1 0 0 1 214 21.8)"
            ></image>
          </g>
        </g>
        <text
          transform="matrix(1 0 0 1 98.0098 108.8167)"
          className="login-labels login-st4 login-st5"
        >
          {t('appearanceDashboard.loginPreview.login')}
        </text>
        <text
          transform="matrix(1 0 0 1 258.9326 164.8848)"
          className="login-welcome-message login-st4 login-st9"
        >
          {t('customization.loginWelcomeMessage', {
            ns: 'company',
            defaultValue: '',
          })}
        </text>
        <text
          transform="matrix(1 0 0 1 98.0098 141.0121)"
          className="login-labels login-st6 login-st7"
        >
          {t('appearanceDashboard.loginPreview.user')}
        </text>
        <text
          transform="matrix(1 0 0 1 98.0098 169.0121)"
          className="login-labels login-st6 login-st7"
        >
          {t('appearanceDashboard.loginPreview.password')}
        </text>
        <g>
          <path
            className="login-st8"
            d="M213.3,156H99.9c-0.8,0-1.5-0.7-1.5-1.5v-9.1c0-0.8,0.7-1.5,1.5-1.5h113.4c0.8,0,1.5,0.7,1.5,1.5v9.1
                C214.8,155.3,214.1,156,213.3,156z"
          />
        </g>
        <g>
          <path
            className="login-st8"
            d="M213.3,184.1H99.9c-0.8,0-1.5-0.7-1.5-1.5v-9.1c0-0.8,0.7-1.5,1.5-1.5h113.4c0.8,0,1.5,0.7,1.5,1.5v9.1
                C214.8,183.4,214.1,184.1,213.3,184.1z"
          />
        </g>
        <path
          className="login-st3"
          d="M208.2,209.6H105c-3.6,0-6.6-3-6.6-6.6v0c0-3.6,3-6.6,6.6-6.6h103.1c3.6,0,6.6,3,6.6,6.6v0
            C214.8,206.7,211.8,209.6,208.2,209.6z"
        />
        <g>
          <image
            width="27"
            height="27"
            href={appearance.isologoUrl}
            transform="matrix(1 0 0 1 98 56.8)"
          ></image>
        </g>
        <g>
          <image
            width="94"
            height="31"
            href={appearance.logoUrl}
            transform="matrix(1 0 0 1 259 116.8)"
          ></image>
        </g>
      </g>
    </svg>
  );
};
