import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Stack,
  TextField,
  Button,
  Divider,
  Typography,
  Grid,
} from '@mui/material';
import CreateIcon from '@mui/icons-material/Create';
import {
  Company,
  CreateCompanyDto,
  OrderedRound,
  Tournament,
  TournamentCustomSettings,
  useCompaniesControllerCreateMutation,
  useCompaniesControllerUpdateMutation,
  useInvitationsControllerCreateMutation,
  useInvitationsControllerFindAllPendingManagersQuery,
  useRoundsControllerFindRecentRoundsInTournamentQuery,
  useTournamentCustomSettingsControllerCreateMutation,
  useTournamentCustomSettingsControllerFindAllByCompanyQuery,
  useTournamentCustomSettingsControllerRemoveMutation,
  useTournamentsControllerFindAllQuery,
  useUsersControllerFindAllResellerUsersQuery,
} from '../../app/services/futbolProdeApi';
import { LoadingButton } from '@mui/lab';
import { Formik } from 'formik';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
import { useCompanyRoutes } from '../../router';
import createValidator from 'class-validator-formik';
import {
  CreateCompanyDtoValidator,
  UpdateCompanyDtoValidator,
} from './CreateCompanyDtoValidator';
import { Else, If, Then } from 'react-if';
import { eqProps, isNil, sortBy, uniqBy } from 'ramda';
import ImageUploadField from '../../common/ImageUploadField';
import { PageSection } from '../../common/PageSection';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { ManagersTable } from './ManagersTable';
import { Input } from '../../common/form/Input';
import { LoginStrategy } from './LoginStrategy';
import { format } from 'date-fns';
import { useUserPermissions } from '../../common/hooks/useUserPermissions';
import { useMemo } from 'react';
import { isNilOrEmpty } from '../../common/helpers/ramdaExtension';
import { useCompanyTranslations } from '@futbolprode/ui-common';
import SwitchInput from '../../common/form/SwitchInput';

export function CompanyForm({
  company,
  isReseller = false,
}: {
  company?: Company;
  isReseller?: boolean;
}) {
  const [updateCompany, updateResult] = useCompaniesControllerUpdateMutation();
  const [createCompany, createResult] = useCompaniesControllerCreateMutation();
  const { data: pendingManagers } =
    useInvitationsControllerFindAllPendingManagersQuery(
      company?.id?.toString() ?? skipToken,
    );
  const { data: resellers } = useUsersControllerFindAllResellerUsersQuery();
  const [createInvitation, invitationResult] =
    useInvitationsControllerCreateMutation();
  const { data, isLoading: tournamentsAreLoading } =
    useTournamentsControllerFindAllQuery();

  const {
    data: tournamentCustomSettings,
    isLoading: tournamentCustomSettingsAreLoading,
  } = useTournamentCustomSettingsControllerFindAllByCompanyQuery(
    company?.id?.toString() ?? skipToken,
  );
  const { isAdmin } = useUserPermissions();

  const tournaments = data?.data || [];
  const navigate = useNavigate();
  const { appRoute } = useCompanyRoutes();

  const sendManagerInvitationIfRequired = async (
    managerInfo: any,
    newCompany: Company,
  ) => {
    const managerUserInvitation = {
      ...managerInfo,
      externalId: managerInfo.email,
      role: isReseller ? 'reseller' : 'manager',
      company: { id: newCompany.id },
    };

    if (isNilOrEmpty(managerUserInvitation.externalId)) {
      return;
    }

    await createInvitation(managerUserInvitation).unwrap();
  };

  const handleSubmit = async (formValues: CreateCompanyDto) => {
    const values = {
      ...formValues,
      appCustomDomain: formValues.appCustomDomain
        ? formValues.appCustomDomain
        : null,
      appPath: formValues.appPath ? formValues.appPath : null,
    };
    const companyFormValues = values as any;
    companyFormValues.managerUsers = companyFormValues.managerUserId
      ? [{ id: +companyFormValues.managerUserId }]
      : [];
    companyFormValues.extraFields = companyFormValues.extraFields.filter(
      (it: { name: string }) => it.name,
    );
    if (company) {
      await updateCompany({
        id: company!.id.toString(),
        updateCompanyDto: companyFormValues,
      });
    } else {
      const creationValues = companyFormValues;
      const managerInfo = {
        name: creationValues.managerUserName,
        email: creationValues.managerUserEmail,
      };
      delete creationValues.managerUserName;
      delete creationValues.managerUserEmail;
      const newCompany = await createCompany(creationValues).unwrap();
      await sendManagerInvitationIfRequired(managerInfo, newCompany);
    }
    navigate(appRoute(isReseller ? 'resellers' : 'companies'));
  };

  const companyDto = {
    isReseller,
    name: company?.name || '',
    logoUrl: company?.logoUrl || '',
    isologoUrl: company?.isologoUrl || '',
    loginStrategy: company?.loginStrategy || LoginStrategy.byEmail,
    requiresEmailVerification: company?.requiresEmailVerification || false,
    maxUsersAmount: company?.maxUsersAmount || 20,
    allowExceedMaxUsers: company?.allowExceedMaxUsers ?? false,
    appCustomDomain: company?.appCustomDomain || '',
    appPath: company?.appPath || '',
    tournaments: company?.tournaments || [],
    managerUserName: '',
    managerUserEmail: '',
    managerUserId:
      company?.managerUsers && company?.managerUsers[0]
        ? company!.managerUsers[0].id
        : null,
    extraFields: company?.extraFields || [],
    loginImageUrl: company?.loginImageUrl || '',
    loginAnnouncementSeverity: company?.loginAnnouncementSeverity || 'info',
    comingSoonModeIsEnabled: company?.comingSoonModeIsEnabled || false,
    superiorBannerDesktopImageUrl:
      company?.superiorBanner?.desktopImageUrl || '',
    superiorBannerMobileImageUrl: company?.superiorBanner?.mobileImageUrl || '',
    superiorBannerLink: company?.superiorBanner?.link || '',
    middleBannerDesktopImageUrl: company?.middleBanner?.desktopImageUrl || '',
    middleBannerMobileImageUrl: company?.middleBanner?.mobileImageUrl || '',
    middleBannerLink: company?.middleBanner?.link || '',
    sideBannerDesktopImageUrl: company?.sideBanner?.desktopImageUrl || '',
    sideBannerMobileImageUrl: company?.sideBanner?.mobileImageUrl || '',
    sideBannerLink: company?.sideBanner?.link || '',
    googleServicesConfig: company?.googleServicesConfig ?? {
      allowSearchEngineIndexing: false,
    },
  };

  return (
    <Formik
      initialValues={companyDto}
      onSubmit={handleSubmit}
      validate={createValidator(
        company ? UpdateCompanyDtoValidator : CreateCompanyDtoValidator,
      )}
      validateOnChange={false}
      handleSubmit
    >
      {({ handleSubmit, handleChange, values, errors, setFieldValue }) => (
        <form onSubmit={handleSubmit}>
          <Stack spacing={2}>
            <PageSection title="companyForm.basicData">
              <Input
                value={values.name}
                name={'name'}
                label={`${t('companiesDashboard.name')} *`}
                onChange={handleChange}
                error={errors.name}
              />
              <Input
                value={values.appPath || ''}
                name={'appPath'}
                label={`${t('companiesDashboard.appPath')}`}
                onChange={handleChange}
                error={errors.appPath}
              />
              <Input
                value={values.appCustomDomain || ''}
                name={'appCustomDomain'}
                label={`${t('companiesDashboard.appCustomDomain')}`}
                onChange={handleChange}
                error={errors.appCustomDomain}
              />
              <If condition={isAdmin}>
                <If
                  condition={
                    tournamentsAreLoading || tournamentCustomSettingsAreLoading
                  }
                >
                  <Then>
                    <LinearProgress />
                  </Then>
                  <Else>
                    <>
                      <InputLabel htmlFor={'tournaments-autocomplete'}>
                        {t('companiesDashboard.tournaments')}
                      </InputLabel>

                      <Autocomplete
                        id="tournaments-autocomplete"
                        multiple
                        isOptionEqualToValue={eqProps('id')}
                        options={sortBy(
                          (t) => (t.isActive ? 0 : 1),
                          tournaments.filter(
                            (tournament) =>
                              !values.tournaments.some(
                                (companyTournament) =>
                                  companyTournament.id === tournament.id,
                              ),
                          ),
                        )}
                        getOptionLabel={(tournament) =>
                          `${tournament.name} (${tournament.season})`
                        }
                        getOptionDisabled={(tournament) => !tournament.isActive}
                        value={values.tournaments}
                        onChange={(_eventa, values) => {
                          setFieldValue('tournaments', values);
                        }}
                        renderInput={(params) => (
                          <TextField {...params} variant="standard" />
                        )}
                      />

                      {!company ? (
                        <></>
                      ) : (
                        values.tournaments.map((tournament) => (
                          <TournamentCustomSettingsForm
                            key={tournament.id}
                            tournament={tournament}
                            company={company!}
                            tournamentCustomSettingsDto={tournamentCustomSettings?.find(
                              (it) => it.tournament.id === tournament.id,
                            )}
                          />
                        ))
                      )}
                    </>
                  </Else>
                </If>
              </If>
            </PageSection>

            <PageSection title="companyForm.googleServicesConfig">
              <Input
                value={values.googleServicesConfig.analyticsTrackingId}
                name={'googleServicesConfig.analyticsTrackingId'}
                label={`${t(
                  'entity.company.googleServicesConfig.analyticsTrackingId',
                )}`}
                placeholder="G-1234567890"
                onChange={handleChange}
                error={errors.googleServicesConfig?.analyticsTrackingId}
              />
              <Input
                value={values.googleServicesConfig.adSenseClientId}
                name={'googleServicesConfig.adSenseClientId'}
                label={`${t(
                  'entity.company.googleServicesConfig.adSenseClientId',
                )}`}
                placeholder="ca-pub-1234567890"
                onChange={handleChange}
                error={errors.googleServicesConfig?.adSenseClientId}
              />
            </PageSection>

            <PageSection title="companyForm.logos">
              <ImageUploadField
                label={`${t('companyForm.logo')} *`}
                error={errors.logoUrl}
                value={values.logoUrl}
                fieldName={'logoUrl'}
                setFieldValue={setFieldValue}
              />
              <ImageUploadField
                label={`${t('companyForm.isologo')} *`}
                error={errors.isologoUrl}
                value={values.isologoUrl}
                fieldName={'isologoUrl'}
                setFieldValue={setFieldValue}
              />
              <Divider />
            </PageSection>
            <PageSection title="companyForm.registerAndLogin">
              <FormControlLabel
                control={
                  <Checkbox
                    id={'requiresEmailVerification'}
                    checked={values.requiresEmailVerification}
                    onChange={handleChange}
                    name={'requiresEmailVerification'}
                  />
                }
                label={t('companiesDashboard.requiresEmailVerification')}
              />
              <>
                <InputLabel htmlFor={'loginStrategy'}>
                  {t('companiesDashboard.loginStrategy')}
                </InputLabel>
                <Select
                  labelId="login-strategy-select-label"
                  name="loginStrategy"
                  id="login-strategy-select"
                  value={values.loginStrategy}
                  label={t('companiesDashboard.loginStrategy')}
                  onChange={handleChange}
                >
                  <MenuItem value={0}>
                    {t('companiesDashboard.byEmail')}
                  </MenuItem>
                  <MenuItem value={1}>
                    {t('companiesDashboard.byExternalId')}
                  </MenuItem>
                </Select>
              </>
              <Input
                value={values.maxUsersAmount}
                type="number"
                name={'maxUsersAmount'}
                label={`${t('companiesDashboard.maxUsersAmount')}`}
                onChange={handleChange}
                error={errors.maxUsersAmount}
              />
              <SwitchInput
                value={values.allowExceedMaxUsers}
                onChange={handleChange}
                name={'allowExceedMaxUsers'}
                label={'companiesDashboard.allowExceedMaxUsers'}
              />
            </PageSection>
            <If condition={isNil(company)}>
              <Then>
                <PageSection
                  title={
                    isReseller
                      ? 'companiesDashboard.resellerUser'
                      : 'companiesDashboard.managerUser'
                  }
                >
                  <Input
                    value={values.managerUserEmail || ''}
                    name={'managerUserEmail'}
                    label={'companiesDashboard.managerUserEmail'}
                    onChange={handleChange}
                    error={errors.managerUserEmail}
                  />
                  <Input
                    value={values.managerUserName || ''}
                    name={'managerUserName'}
                    label={'companiesDashboard.managerUserName'}
                    onChange={handleChange}
                    error={errors.managerUserName}
                  />
                </PageSection>
              </Then>
              <Else>
                <PageSection
                  title={t('companiesDashboard.managerUser')}
                  actions={
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => navigate('managers')}
                      startIcon={<CreateIcon />}
                    >
                      {t('companiesDashboard.inviteManager')}
                    </Button>
                  }
                >
                  <ManagersTable
                    company={company}
                    pendingManagers={pendingManagers}
                  />
                </PageSection>
              </Else>
            </If>
            <If condition={!isReseller && isAdmin}>
              <Then>
                <PageSection title="companyForm.reseller">
                  <Select
                    labelId="reseller-select-label"
                    name="managerUserId"
                    id="reseller-select"
                    value={(values as any).managerUserId}
                    label={t('companiesDashboard.loginStrategy')}
                    onChange={handleChange}
                  >
                    <MenuItem value={''}>-</MenuItem>
                    {uniqBy(
                      (it) => it.company?.id,
                      (resellers || []).filter(
                        (reseller) => !isNil(reseller.company),
                      ),
                    ).map((reseller) => (
                      <MenuItem key={reseller.id} value={reseller.id}>
                        {reseller.company?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </PageSection>
              </Then>
            </If>
            <FormHelperText error>
              {(updateResult.error ||
                createResult.error ||
                invitationResult.error) &&
                t('companiesDashboard.error')}
            </FormHelperText>
            <LoadingButton
              loading={updateResult.isLoading || createResult.isLoading}
              fullWidth
              type="submit"
              variant="contained"
            >
              {t('save')}
            </LoadingButton>
          </Stack>
        </form>
      )}
    </Formik>
  );
}

export function TournamentCustomSettingsForm({
  company,
  tournament,
  tournamentCustomSettingsDto,
}: {
  company: Company;
  tournament: Tournament;
  tournamentCustomSettingsDto?: TournamentCustomSettings;
}) {
  const [createTournamentCustomSettings, result] =
    useTournamentCustomSettingsControllerCreateMutation();
  const [deleteTournamentCustomSettings] =
    useTournamentCustomSettingsControllerRemoveMutation();
  const { data, isLoading } =
    useRoundsControllerFindRecentRoundsInTournamentQuery(
      tournament.id.toString(),
    );

  const { roundName } = useCompanyTranslations();

  const rounds = useMemo(() => data?.rounds, [data]);

  const saveRound = async (roundId: string | number) => {
    if (
      window.confirm(
        `Querés cambiar la fecha de inicio del torneo ${tournament.name} para la empresa ${company.name}?`,
      )
    ) {
      await createTournamentCustomSettings({
        tournamentId: tournament.id.toString(),
        createTournamentCustomSettingsDto: {
          tournament: { id: tournament.id },
          round: { id: +roundId },
          company: { id: company.id },
        },
      }).unwrap();
      window.location.reload();
    }
  };

  const deleteSettings = async () => {
    if (isNil(tournamentCustomSettingsDto)) {
      return;
    }

    if (
      window.confirm(
        `Querés borrar la fecha de inicio del torneo ${tournament.name} para la empresa ${company.name}?`,
      )
    ) {
      await deleteTournamentCustomSettings(
        tournamentCustomSettingsDto.tournament.id,
      ).unwrap();
      window.location.reload();
    }
  };

  return (
    <If condition={isLoading || result.isLoading}>
      <Then>
        <LinearProgress />
      </Then>
      <Else>
        <Stack spacing={2}>
          <Grid container alignItems="center">
            <Grid item xs={3}>
              <Typography>{tournament.name}</Typography>
            </Grid>
            <Grid item xs={8}>
              <InputLabel htmlFor={'round'}>
                {t('companyForm.startingRound')}
              </InputLabel>
              <Select
                labelId="login-strategy-select-label"
                name="round"
                id="round"
                fullWidth
                value={tournamentCustomSettingsDto?.round?.id}
                label={t('companyForm.startingRound')}
                onChange={(e) => saveRound(e.target.value)}
              >
                {((rounds as any) || []).map((round: OrderedRound) => (
                  <MenuItem key={round.id} value={round.id}>
                    <Stack>
                      {roundName(round)}{' '}
                      <Typography variant="body2" color="text.secondary">
                        {format(
                          new Date(round.date || new Date()),
                          'dd/MM/yyyy HH:mm',
                        )}
                        {' | '}
                        {round.name}
                      </Typography>
                    </Stack>
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs textAlign="right">
              <Button
                variant="text"
                color="error"
                onClick={deleteSettings}
                disabled={isNil(tournamentCustomSettingsDto)}
              >
                {t('common.delete')}
              </Button>
            </Grid>
          </Grid>
        </Stack>
      </Else>
    </If>
  );
}
