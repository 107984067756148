import { Typography } from '@mui/material';
import { Update, Create, Close } from '@mui/icons-material';
import { ImportUsersResultsRow } from './ImportUsersResultsRow';
import { Stack } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { ImportReport } from '../../app/services/futbolProdeApi';

export default function ImportResultSuccess({
  value,
}: {
  value: ImportReport;
}) {
  const { t } = useTranslation();
  return (
    <Stack spacing={1}>
      <Typography variant="h6">{t('importUsersDashboard.results')}</Typography>
      <ImportUsersResultsRow
        Icon={Create}
        text={t('importUsersDashboard.createdUsers', {
          count: value.createdCount,
        })}
      />
      <ImportUsersResultsRow
        Icon={Create}
        text={t('importUsersDashboard.newAreas', {
          count: value.newAreas!.length,
        })}
      />
      <ImportUsersResultsRow
        Icon={Update}
        text={t('importUsersDashboard.updatedUsers', {
          count: value.updatedCount,
        })}
      />
      <ImportUsersResultsRow
        Icon={Update}
        text={t('importUsersDashboard.existingAreas', {
          count: value.existingAreas!.length,
        })}
      />
      <ImportUsersResultsRow
        Icon={Close}
        text={t('importUsersDashboard.ignoredRows', {
          count: value.ignoredRowsCount,
        })}
      />
    </Stack>
  );
}
