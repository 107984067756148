import { useRouteError } from 'react-router-dom';
import { Container, Stack, Typography, Button } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import UpdateIcon from '@mui/icons-material/Update';

export default function UnhandledErrorPage() {
  const { t } = useTranslation();
  const error = useRouteError();
  console.error(error);

  return (
    <Container
      maxWidth="xl"
      sx={{
        display: 'flex',
        height: '100vh',
        placeItems: 'center',
        placeContent: 'center',
      }}
    >
      <Stack spacing={2} textAlign="center" alignItems="center">
        <UpdateIcon color="primary" sx={{ fontSize: 70 }} />
        <Typography variant="h4">{t('unhandledErrorPage.title')}</Typography>
        <Typography variant="subtitle1">
          <Trans
            i18nKey="unhandledErrorPage.subtitle"
            components={{ p: <p style={{ margin: 0 }} /> }}
          />
        </Typography>
        <Stack>
          <Button
            variant="contained"
            color="primary"
            onClick={() => window.location.reload()}
          >
            {t('unhandledErrorPage.reload')}
          </Button>
        </Stack>
      </Stack>
    </Container>
  );
}
