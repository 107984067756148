import Page from '../../common/Page';
import { CompanyTranslationsEdit } from './CompanyTranslationsEdit';
import TranslationTabs from './TranslationsTabs';

export function CompanyTranslationsDashboard() {
  return (
    <Page
      breadcrumbs={[{ label: 'sidebar.customization' }]}
      title="sidebar.translations"
    >
      <TranslationTabs>
        {(locale) => <CompanyTranslationsEdit locale={locale} />}
      </TranslationTabs>
    </Page>
  );
}
