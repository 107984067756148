import { Skeleton, SkeletonProps } from '@mui/material';
import { useMemo } from 'react';

type SkeletonOrProps<T> = SkeletonProps & {
  isLoading: boolean;
  value: T;
};

export default function SkeletonOr<T>({
  isLoading,
  value,
  ...skeletonProps
}: SkeletonOrProps<T>): JSX.Element {
  return useMemo(
    () => (isLoading ? <Skeleton {...skeletonProps} /> : <>{value}</>),
    [isLoading, value, skeletonProps],
  );
}
