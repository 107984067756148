import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Round } from '../../app/services/futbolProdeApi';
import { useTranslation } from 'react-i18next';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { isNil, eqProps } from 'ramda';
import { Skeleton } from '@mui/material';
import { useCompanyTranslations } from '@futbolprode/ui-common';

interface RoundSelectProps {
  onValueUpdated: (arg: Round | null) => void;
  rounds?: Round[];
  fullWidth?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  disableClearable?: boolean;
  autoSelectSingleElement?: boolean;
  value?: Round | null;
  setValue?: Dispatch<SetStateAction<Round | null>>;
}

export default function RoundSelect({
  onValueUpdated,
  rounds,
  value: externalValue,
  setValue: externalSetValue,
  disabled = false,
  isLoading = false,
  autoSelectSingleElement = false,
  fullWidth = false,
  disableClearable = false,
}: RoundSelectProps) {
  const { t } = useTranslation();
  const { roundName } = useCompanyTranslations();
  const [localValue, localSetValue] = useState<Round | null>(null);

  const value = useMemo(
    () => externalValue ?? localValue,
    [externalValue, localValue],
  );

  const setValue = useMemo(
    () => externalSetValue ?? localSetValue,
    [externalSetValue, localSetValue],
  );

  const onValueChanged = useCallback(
    (newValue: Round | null) => {
      setValue(newValue);
      onValueUpdated(newValue);
    },
    [onValueUpdated, setValue],
  );

  useEffect(() => {
    if (autoSelectSingleElement && rounds?.length === 1) {
      onValueChanged(rounds[0]);
    }
  }, [autoSelectSingleElement, rounds, onValueChanged]);

  return isNil(rounds) ? (
    <Skeleton width="100%"></Skeleton>
  ) : (
    <Autocomplete
      onChange={(_event, newValue) => onValueChanged(newValue)}
      value={value}
      disabled={disabled}
      noOptionsText={t('autocomplete.noOptions')}
      fullWidth={fullWidth}
      options={rounds}
      autoHighlight
      disableClearable={disableClearable}
      getOptionLabel={(it) => roundName(it)!}
      isOptionEqualToValue={eqProps('id')}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          {roundName(option)}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={t('autocomplete.chooseOne') as string}
          label={t(isLoading ? 'common.loading' : 'round.filterBy')}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'off',
          }}
        />
      )}
    />
  );
}
