import { Theme, useTheme } from '@mui/material';
import { AppearanceDto } from './AppearanceDashboard';
import { isNil } from 'ramda';
import { useTranslation } from 'react-i18next';

const safeGetContrastText = (theme: Theme, color?: string) =>
  isNil(color) ? '#000' : theme.palette.getContrastText(color);

export const HomePreviewSvg = ({
  appearance,
}: {
  appearance: AppearanceDto;
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navBarColor =
    appearance.customColors?.navigationBarBackground ?? appearance.primaryColor;
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 489.5 287.8"
    >
      <style type="text/css">
        {`
        .home-st0{fill:#A2AEBA;}
        .home-st1{fill:#FFFFFF;}
        .home-st2{fill:${navBarColor};}
        .home-st3{fill:${appearance.tertiaryColor};}
        .home-st4{fill:${appearance.secondaryColor};}
        .home-st5{fill:none;stroke:${safeGetContrastText(
          theme,
          appearance.secondaryColor,
        )};stroke-width:2;stroke-miterlimit:10;stroke-dasharray:9.907,9.907;}
        .home-st6{font-family:'Poppins-Medium';}
        .home-st7{font-size:18.3957px; fill:${safeGetContrastText(
          theme,
          appearance.secondaryColor,
        )}}
        .home-st8{font-size:11.2882px; fill:${safeGetContrastText(
          theme,
          navBarColor,
        )}}        
        `}
      </style>
      <g>
        <g>
          <path
            className="home-st0"
            d="M485.5,287.8H4c-2.2,0-4-1.8-4-4V4c0-2.2,1.8-4,4-4h481.5c2.2,0,4,1.8,4,4v279.8
                C489.5,286,487.7,287.8,485.5,287.8z"
          />
        </g>
        <path
          className="home-st1"
          d="M483.3,285H6.2c-2.2,0-4.1-1.8-4.1-4V27.2h485.3v253.8C487.4,283.2,485.5,285,483.3,285z"
        />
        <circle className="home-st1" cx="9.8" cy="12.7" r="3.4" />
        <circle className="home-st1" cx="18.6" cy="12.7" r="3.4" />
        <circle className="home-st1" cx="27.4" cy="12.7" r="3.4" />
        <rect
          x="2.1"
          y="27.1"
          className="home-st2"
          width="485.3"
          height="22.8"
        />
        <rect
          x="2.1"
          y="49.9"
          className="home-st3"
          width="485.3"
          height="11.4"
        />
        <rect
          x="2.1"
          y="58.8"
          className="home-st4"
          width="485.3"
          height="141.6"
        />
        <g>
          <path
            className="home-st4"
            d="M154.8,253.1H89.8c-2.8,0-5.2-2.3-5.2-5.2v-28.8c0-2.8,2.3-5.2,5.2-5.2h64.9c2.8,0,5.2,2.3,5.2,5.2v28.8
                C159.9,250.8,157.6,253.1,154.8,253.1z"
          />
          <path
            className="home-st4"
            d="M236.2,253.1h-64.9c-2.8,0-5.2-2.3-5.2-5.2v-28.8c0-2.8,2.3-5.2,5.2-5.2h64.9c2.8,0,5.2,2.3,5.2,5.2v28.8
                C241.4,250.8,239.1,253.1,236.2,253.1z"
          />
          <path
            className="home-st4"
            d="M317.7,253.1h-64.9c-2.8,0-5.2-2.3-5.2-5.2v-28.8c0-2.8,2.3-5.2,5.2-5.2h64.9c2.8,0,5.2,2.3,5.2,5.2v28.8
                C322.9,250.8,320.6,253.1,317.7,253.1z"
          />
          <path
            className="home-st4"
            d="M399.2,253.1h-64.9c-2.8,0-5.2-2.3-5.2-5.2v-28.8c0-2.8,2.3-5.2,5.2-5.2h64.9c2.8,0,5.2,2.3,5.2,5.2v28.8
                C404.4,250.8,402,253.1,399.2,253.1z"
          />
        </g>
      </g>
      <g>
        <path
          className="home-st5"
          d="M395,184.8H98.6c-5.2,0-9.4-4.2-9.4-9.4V75.2c0-5.2,4.2-9.4,9.4-9.4H395c5.2,0,9.4,4.2,9.4,9.4v100.1
		C404.4,180.6,400.2,184.8,395,184.8z"
        />
      </g>
      <text
        transform="matrix(1 0 0 1 211.0664 130.1421)"
        className="home-st1 home-st6 home-st7"
      >
        {t('appearanceDashboard.homePreview.news')}
      </text>
      <text
        transform="matrix(1 0 0 1 140.2602 242.1228)"
        className="home-st1 home-st6 home-st7"
      >
        1°
      </text>
      <text
        transform="matrix(1 0 0 1 204.6629 242.1228)"
        className="home-st1 home-st6 home-st7"
      >
        100
      </text>
      <text
        transform="matrix(1 0 0 1 302.3471 242.1228)"
        className="home-st1 home-st6 home-st7"
      >
        2°
      </text>
      <text
        transform="matrix(1 0 0 1 366.7498 242.1228)"
        className="home-st1 home-st6 home-st7"
      >
        25
      </text>
      <g>
        <image
          width="94"
          height="31"
          href={appearance.logoUrl}
          transform="matrix(0.5551 0 0 0.5551 89.2 29.8953)"
        ></image>
      </g>
    </svg>
  );
};
