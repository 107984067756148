import {
  ArrayMaxSize,
  ArrayMinSize,
  IsDateString,
  IsNotEmpty,
  IsUrl,
  Min,
  ValidationOptions,
  isString,
} from 'class-validator';
import { isNil } from 'ramda';
import ValidatorJS from 'validator';
import { IsURLOptions } from 'validator/lib/isURL';
import { Nullable } from 'typescript-nullable';

export function LocalizedIsNotEmpty(validationOptions?: ValidationOptions) {
  return IsNotEmpty({
    ...validationOptions,
    message: 'validations.common.isNotEmpty',
  });
}

export function LocalizedIsUrl(options?: IsURLOptions) {
  return IsUrl(options, { message: 'validations.common.isUrl' });
}

export function LocalizedMin(
  minValue: number,
  validationOptions?: ValidationOptions,
) {
  return Min(minValue, {
    ...validationOptions,
    message: `validations.common.min|{"value":${minValue}}`,
  });
}

export function LocalizedArrayMinSize(
  min: number,
  validationOptions?: ValidationOptions,
) {
  return ArrayMinSize(min, {
    ...validationOptions,
    message: `validations.common.arrayMinSize|{"value":${min}}`,
  });
}

export function LocalizedArrayMaxSize(
  max: number,
  validationOptions?: ValidationOptions,
) {
  return ArrayMaxSize(max, {
    ...validationOptions,
    message: `validations.common.arrayMaxSize|{"value":${max}}`,
  });
}

export function LocalizedIsDateString(
  options?: ValidatorJS.IsISO8601Options,
  validationOptions?: ValidationOptions,
) {
  return IsDateString(options, {
    ...validationOptions,
    message: 'validations.common.isDateString',
  });
}

export const parseValidationError = (error: string | undefined) => {
  if (isNil(error) || !isString(error)) {
    return undefined;
  }

  const [key, params] = error.split('|');
  return { key, params: Nullable.map(JSON.parse, params) };
};
