import { Stack, Typography } from '@mui/material';
import { WelcomeMessage } from './WelcomeMessage';
import { RegisteredUsersCard } from './RegisteredUsersCard';
import { RankingCard } from './RankingCard';
import { StatsCards } from './StatsCards';
import { useTranslation } from 'react-i18next';
import AccountInfo from './AccountInfo';

export function HomeTitles({
  titleKey,
  subtitleKey,
  titleColor = 'text.primary',
  subtitleColor = 'text.secondary',
}: {
  titleKey: string;
  subtitleKey: string;
  titleColor?: string;
  subtitleColor?: string;
}) {
  const { t } = useTranslation();
  return (
    <Stack>
      <Typography variant="h5" fontWeight="bold" sx={{ color: titleColor }}>
        {t(titleKey)}
      </Typography>
      <Typography variant="subtitle1" sx={{ color: subtitleColor }}>
        {t(subtitleKey)}
      </Typography>
    </Stack>
  );
}

export function HomePage() {
  return (
    <Stack spacing={3}>
      <WelcomeMessage />
      <AccountInfo />
      <HomeTitles
        titleKey="homePage.mainTitle"
        subtitleKey="homePage.mainSubtitle"
      />
      {/* El div este es un hack para que no agregue padding a la izquierda */}
      <div>
        <StatsCards />
      </div>
      <RegisteredUsersCard />
      <RankingCard />
    </Stack>
  );
}
