import {
  Round,
  useCombinedRoundsRankingDefinitionsControllerFindAllQuery,
  useCombinedRoundsRankingDefinitionsControllerRemoveMutation,
  useRankingsControllerCalculateCombinedRoundsRankingMutation,
} from '../../../app/services/futbolProdeApi';
import FloatingButton from '../../../common/FloatingButton';
import Page from '../../../common/Page';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import {
  GridValueGetterParams,
  GridActionsCellItem,
  GridRowParams,
} from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ReplayIcon from '@mui/icons-material/Replay';
import { useUserPermissions } from '../../../common/hooks/useUserPermissions';
import { compact, onlyIf } from '../../../common/helpers/ramdaExtension';

export function CombinedRoundsDashboard() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [deleteCombinedRanking, _result] =
    useCombinedRoundsRankingDefinitionsControllerRemoveMutation();
  const [calculateRanking] =
    useRankingsControllerCalculateCombinedRoundsRankingMutation();
  const { data, isLoading } =
    useCombinedRoundsRankingDefinitionsControllerFindAllQuery();
  const { isAdmin } = useUserPermissions();
  const columns: any = [
    {
      field: 'id',
      headerName: t('combinedRoundsDashboard.id'),
      maxWidth: 100,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      type: 'string',
    },
    {
      field: 'name',
      headerName: t('combinedRoundsDashboard.name'),
      width: 150,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      type: 'string',
    },
    {
      field: 'roundList',
      headerName: t('combinedRoundsDashboard.chosenRounds'),
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      type: 'string',
      valueGetter: (params: GridValueGetterParams) =>
        params.row.rounds.map((it: Round) => it.position).join(', '),
    },
    {
      field: 'actions',
      type: 'actions',
      minWidth: 150,
      getActions: (params: GridRowParams) =>
        compact([
          <GridActionsCellItem
            icon={<EditIcon />}
            onClick={() => navigate(`${params.row.id}`)}
            label="Edit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            onClick={() => deleteCombinedRanking(params.row.id)}
            label="Delete"
          />,
          onlyIf(
            isAdmin,
            <GridActionsCellItem
              icon={<ReplayIcon />}
              onClick={() => calculateRanking(params.row.id)}
              label="Calculate"
            />,
          ),
        ]),
    },
  ];

  return (
    <Page
      breadcrumbs={[{ label: 'sidebar.game' }]}
      title="sidebar.combinedRounds"
      actions={
        <FloatingButton color="primary" onClick={() => navigate('new')}>
          <AddIcon />
        </FloatingButton>
      }
    >
      <DataGrid
        columns={columns}
        rows={data ?? []}
        loading={!data || isLoading}
        autoHeight
        hideFooter
        sx={{ minHeight: '1000px' }}
      />
    </Page>
  );
}
