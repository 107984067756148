import { IsInt, IsNotEmpty, Max, Min } from 'class-validator';

export class CreateDreamFinalDtoValidator {
  constructor() {
    this.limitRoundId = '';
    this.awardedPositions = 0;
    this.awardedPoints = 0;
    this.isEnabled = true;
  }

  @IsNotEmpty({ message: 'validations.common.isNotEmpty' })
  limitRoundId: string;

  tournamentId: any;

  @IsInt()
  @Min(2, {
    message: (args) =>
      `validations.awardedPositions.lowerThan${args.constraints[0]}`,
  })
  @Max(4, {
    message: (args) =>
      `validations.awardedPositions.greaterThan${args.constraints[0]}`,
  })
  awardedPositions: number;

  @IsInt()
  @Min(5, {
    message: (args) =>
      `validations.awardedPoints.lowerThan${args.constraints[0]}`,
  })
  @Max(10, {
    message: (args) =>
      `validations.awardedPoints.greaterThan${args.constraints[0]}`,
  })
  awardedPoints: number;

  isEnabled: boolean;
}
