import { Avatar, Box, Divider, Grid, Typography } from '@mui/material';
import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Tournament,
  useTournamentImporterControllerReimportMutation,
} from '../../app/services/futbolProdeApi';
import SyncIcon from '@mui/icons-material/Sync';
import LoadingIconButton from '../../common/LoadingIconButton';
import { useCompanyTranslations } from '@futbolprode/ui-common';
import { Unless } from 'react-if';

interface TournamentProps {
  tournamentsList: Tournament[];
  displayCountry?: boolean;
}

export function TournamentListDetail({
  tournamentsList,
  displayCountry = false,
}: TournamentProps) {
  const navigate = useNavigate();
  const [reimport, reimportStatus] =
    useTournamentImporterControllerReimportMutation();
  const { tournamentName } = useCompanyTranslations();

  return (
    <Grid container spacing={1} pt={1}>
      {tournamentsList.map((tournament, index) => (
        <Fragment key={index}>
          <Grid
            item
            xs={10}
            sx={{ cursor: 'pointer' }}
            onClick={() => navigate(tournament.id.toString())}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Avatar
                alt={`${tournamentName(tournament)} flag`}
                src={tournament.logoUrl}
              />
              <Typography pl={2}>
                {tournamentName(tournament)}
                <Typography variant="body2" color="text.secondary">
                  {tournament.season +
                    ' ' +
                    (displayCountry ? `(${tournament.country.name})` : '')}
                </Typography>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2} display="flex" justifyContent="flex-end">
            <LoadingIconButton
              color="success"
              onClick={() => reimport(tournament.id.toString())}
              loading={
                reimportStatus.isLoading &&
                reimportStatus.originalArgs === tournament.id.toString()
              }
            >
              <SyncIcon />
            </LoadingIconButton>
          </Grid>
          <Unless condition={index === tournamentsList.length - 1}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Unless>
        </Fragment>
      ))}
    </Grid>
  );
}
