import { useState } from 'react';
import { useCompaniesControllerFindAllQuery } from '../../app/services/futbolProdeApi';
import { CompaniesDashboard } from './CompaniesDashboard';
import { useDebounce } from 'use-debounce';

export function RegularCompaniesDashboard() {
  const [search, setSearch] = useState('');
  const [debouncedSearchTerm] = useDebounce(search, 500);

  const PAGE_SIZE = 50;
  const [page, setPage] = useState(0);
  const { data, isLoading } = useCompaniesControllerFindAllQuery({
    search: debouncedSearchTerm,
    take: PAGE_SIZE,
    skip: page * PAGE_SIZE,
  });

  return (
    <CompaniesDashboard
      data={data?.data ?? []}
      isLoading={isLoading}
      pageSize={PAGE_SIZE}
      page={page}
      setPage={setPage}
      count={data?.pagination?.count ?? 0}
      search={search}
      setSearch={setSearch}
    />
  );
}
