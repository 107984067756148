import { JobLog } from '../../app/services/futbolProdeApi';

export const JobTypes = [
  'ranking:generate',
  'tournament:import',
  'match:import-result',
  'domains:godaddy-create-domain-forward',
  'domains:godaddy-create-site-subdomains',
  'domains:acm-request-certificate',
  'domains:acm-solve-challenge',
  'domains:ses-create-identity',
  'domains:ses-verify-identity',
  'domains:ses-solve-challenge',
  'domains:cloudfront-update-distribution',
  'news:parsehub:fetch',
  'invitation:import',
] as const;

export type JobType = JobLog['type'];
