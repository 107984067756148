import { Stack, LinearProgress } from '@mui/material';
import { Else, If, Then } from 'react-if';
import Page from '../../common/Page';
import { GameRulesForm } from './GameRulesForm';
import {
  TranslationsDto,
  useTranslationsPerLocaleQuery,
  useCreateTranslationsPerLocaleMutation,
  defaultTranslations,
} from '../translations/translationHelpers';
import { LocalizedIsNotEmpty } from '../../common/helpers/validatorHelpers';
import { Formik } from 'formik';
import TranslationTabs from '../translations/TranslationsTabs';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { mergeDeepRight } from 'ramda';

export class GameRules {
  @LocalizedIsNotEmpty()
  general!: string;

  @LocalizedIsNotEmpty()
  penalties!: string;

  @LocalizedIsNotEmpty()
  twoWayPenalties!: string;

  @LocalizedIsNotEmpty()
  dreamFinal!: string;
}

export class GameRulesTranslations {
  gameRules!: GameRules;
}

export function GameRulesDashboard() {
  const { t } = useTranslation();
  const { data: translations, isLoading } =
    useTranslationsPerLocaleQuery<GameRulesTranslations>('common');
  const { createTranslations, result } =
    useCreateTranslationsPerLocaleMutation('common');
  const handleSubmit = (values: TranslationsDto<GameRulesTranslations>) => {
    createTranslations(values);
  };

  const gameRulesDto = mergeDeepRight(
    defaultTranslations({
      gameRules: {
        general: '',
        dreamFinal: '',
        penalties: '',
        twoWayPenalties: '',
      },
    }),
    translations ?? {},
  );

  return (
    <Page
      breadcrumbs={[{ label: 'sidebar.globalSettings' }]}
      title="sidebar.gameRules"
    >
      <If condition={isLoading}>
        <Then>
          <LinearProgress />
        </Then>
        <Else>
          <Formik
            initialValues={gameRulesDto}
            onSubmit={handleSubmit}
            validateOnChange={false}
            handleSubmit
          >
            {({
              handleSubmit,
              handleChange,
              values,
              errors,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <Stack spacing={2}>
                  <TranslationTabs>
                    {(locale) => (
                      <GameRulesForm
                        locale={locale}
                        values={values}
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                        errors={errors}
                      />
                    )}
                  </TranslationTabs>
                  <LoadingButton
                    loading={result.isLoading}
                    fullWidth
                    type="submit"
                    variant="contained"
                  >
                    {t('save')}
                  </LoadingButton>
                </Stack>
              </form>
            )}
          </Formik>
        </Else>
      </If>
    </Page>
  );
}
