import {
  Alert,
  FormHelperText,
  Grid,
  LinearProgress,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  Tournament,
  useTournamentsControllerFindAllQuery,
  useTournamentsControllerFindTournamentRoundsQuery,
  useTranslationsControllerCreateCommonMutation,
  useTranslationsControllerGetCommonTranslationsQuery,
} from '../../app/services/futbolProdeApi';
import { PageSection } from '../../common/PageSection';
import { Formik } from 'formik';
import { Input } from '../news/Input';
import { LoadingButton } from '@mui/lab';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import TournamentSelect from '../rankings/TournamentSelect';
import { useState, useMemo } from 'react';
import { deleteEmptyKeys } from '../../common/helpers/objectHelpers';

export function CommonTranslationsEdit({ locale }: { locale: string }) {
  const { t } = useTranslation();
  const { data, isLoading } =
    useTranslationsControllerGetCommonTranslationsQuery(locale ?? 'es');
  const { data: tournamentsData } = useTournamentsControllerFindAllQuery();
  const tournaments = useMemo(() => tournamentsData?.data, [tournamentsData]);
  const [tournament, setTournament] = useState<Tournament | null>(null);
  const {
    data: roundsData,
    isLoading: isLoadingRounds,
    isFetching,
  } = useTournamentsControllerFindTournamentRoundsQuery(
    tournament ? tournament.id.toString() : skipToken,
    { refetchOnMountOrArgChange: true },
  );

  const rounds = useMemo(() => roundsData?.data, [roundsData]);

  const [createTranslations, createResult] =
    useTranslationsControllerCreateCommonMutation();
  if (isLoading) return <LinearProgress></LinearProgress>;

  const translations = data ?? ({} as any);
  const handleSubmit = async (values: any) => {
    // Solución poco elegante para que no guarde los strings vacíos
    deleteEmptyKeys(values.tournaments);
    deleteEmptyKeys(values.rounds);

    const translationsBody = {
      locale: locale!,
      data: JSON.stringify(values),
    };

    await createTranslations(translationsBody).unwrap();
  };

  const translationsDto = {
    tournaments: translations.tournaments ?? {},
    rounds: translations.rounds ?? {},
  };

  return (
    <Formik
      initialValues={translationsDto}
      onSubmit={handleSubmit}
      validateOnChange={false}
      handleSubmit
    >
      {({ handleSubmit, handleChange, values }) => {
        const InputField = (label: string, name: string, value: string) => (
          <Grid>
            <Typography>{label}</Typography>
            <Input value={value} name={name} onChange={handleChange} />
          </Grid>
        );

        return (
          <form onSubmit={handleSubmit}>
            <Stack spacing={2}>
              <PageSection title="translationsDashboard.tournaments">
                <Stack spacing={1}>
                  {(tournaments ?? []).map((t) =>
                    InputField(
                      t.name,
                      `tournaments[${t.id}]`,
                      values.tournaments[t.id],
                    ),
                  )}
                </Stack>
              </PageSection>
              <PageSection title="translationsDashboard.finalPhaseRounds">
                <Alert severity="info">
                  {t('translationsDashboard.finalPhaseRoundsExplain')}
                </Alert>
                {InputField(
                  'Round of 16',
                  'rounds[roundOfSixteen]',
                  values.rounds.roundOfSixteen,
                )}
                {InputField(
                  'Quarter finals',
                  'rounds[quarterFinals]',
                  values.rounds.quarterFinals,
                )}
                {InputField(
                  'Semifinals',
                  'rounds[semiFinals]',
                  values.rounds.semiFinals,
                )}
                {InputField(
                  '3rd Place Final',
                  'rounds[thirdPlaceFinal]',
                  values.rounds.thirdPlaceFinal,
                )}
                {InputField('Final', 'rounds[final]', values.rounds.final)}
              </PageSection>
              <PageSection
                title={t('translationsDashboard.roundsOfTournament')}
              >
                <TournamentSelect
                  fullWidth
                  tournaments={tournaments ?? []}
                  onValueUpdated={(value) => setTournament(value)}
                  placeholderLabel={t('tournament.filterBy')}
                />
                <Stack>
                  {isLoadingRounds || isFetching
                    ? null
                    : (rounds ?? []).map((r) =>
                        InputField(
                          `${r.name} (${t('round.name', {
                            number: r.position,
                          })})`,
                          `rounds[${r.id}]`,
                          values.rounds[r.id],
                        ),
                      )}
                </Stack>
              </PageSection>
              <FormHelperText error>
                {createResult.error && t('translationsDashboard.error')}
              </FormHelperText>

              <LoadingButton
                loading={createResult.isLoading}
                fullWidth
                type="submit"
                variant="contained"
              >
                {t('save')}
              </LoadingButton>
            </Stack>
          </form>
        );
      }}
    </Formik>
  );
}
