import { ThunkAction, Action, configureStore } from '@reduxjs/toolkit';
import { defaultReducers, defaultMiddlewares } from '@futbolprode/ui-common';
import { setupListeners } from '@reduxjs/toolkit/query';
import {
  mutationResultLoggerMiddleware,
  forceLogoutIfForbiddenMiddleware,
} from './middleware';

export const store = configureStore({
  reducer: defaultReducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      ...defaultMiddlewares,
      forceLogoutIfForbiddenMiddleware,
      mutationResultLoggerMiddleware,
    ),
});

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
