import { FormHelperText, Grid, Stack, useTheme } from '@mui/material';
import { isEmpty, isNil } from 'ramda';
import UploadFileZone from './UploadFileZone';
import { useUploadFileMutation } from '@futbolprode/ui-common';
import { Image } from 'mui-image';
import { useTranslation } from 'react-i18next';
import HideImageIcon from '@mui/icons-material/HideImage';

export interface ImageUploadFieldProps {
  label: string;
  dimensions?: string;
  error: string | undefined;
  value: string;
  fieldName: string;
  disabled?: boolean;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void;
}

export default function ImageUploadField({
  label,
  dimensions,
  error,
  value,
  setFieldValue,
  fieldName,
  disabled = false,
}: ImageUploadFieldProps) {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const [uploadFile] = useUploadFileMutation();

  const onDrop = async (files: File[]) => {
    const formData = new FormData();
    formData.append('file', files[0]);
    const { cdnUrl } = await uploadFile(formData).unwrap();
    setFieldValue(fieldName, cdnUrl);
  };

  return (
    <>
      <Grid container alignItems="flex-start">
        <Grid item xs={12} md={4}>
          <Stack direction="column">
            <UploadFileZone
              onDrop={onDrop}
              acceptedFiles={{
                'image/jpeg': [],
                'image/png': [],
              }}
              label={isNil(dimensions) ? label : `${label}: ${dimensions}`}
              disabled={disabled}
            />
            <FormHelperText error>{t(error as string)}</FormHelperText>
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          md={8}
          container
          justifyContent="center"
          bgcolor={palette.grey[100]}
        >
          {isEmpty(value) ? (
            <>
              <HideImageIcon fontSize="large" color="disabled" />
            </>
          ) : (
            <Image
              alt={fieldName}
              src={value}
              style={{ maxHeight: 128 }}
              fit="contain"
              showLoading
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}
