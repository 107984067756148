import { FormHelperText, Stack } from '@mui/material';
import {
  CreateNewsDto,
  News,
  useNewsControllerCreateMutation,
  useNewsControllerUpdateMutation,
} from '../../app/services/futbolProdeApi';
import { LoadingButton } from '@mui/lab';
import { Formik } from 'formik';
import { t } from 'i18next';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCompanyRoutes } from '../../router';
import createValidator from 'class-validator-formik';
import { CreateNewsDtoValidator } from './CreateNewsDtoValidator';
import { HtmlEditorInput } from '../../common/HtmlEditorInput';
import ImageUploadField from '../../common/ImageUploadField';
import { PageSection } from '../../common/PageSection';
import { Input } from '../../common/form/Input';
import SwitchInput from '../../common/form/SwitchInput';
import { LanguageSelect } from '../../common/form/LanguageSelect';

export function NewsForm({ news }: { news?: News }) {
  const [updateNews, updateResult] = useNewsControllerUpdateMutation();
  const [createNews, createResult] = useNewsControllerCreateMutation();
  const navigate = useNavigate();
  const { appRoute } = useCompanyRoutes();
  const handleSubmit = async (values: CreateNewsDto) => {
    if (news) {
      await updateNews({ id: news!.id.toString(), updateNewsDto: values });
    } else {
      await createNews(values).unwrap();
    }
    navigate(appRoute(`news/custom`));
  };
  const newsDto = {
    title: news?.title || '',
    language: news?.language || 'es',
    description: news?.description || '',
    imageUrl: news?.imageUrl || '',
    date: news?.date || new Date().toISOString(),
    link: news?.link || '',
    fixed: news?.fixed || false,
  };
  const [body, setBody] = useState(newsDto.description);

  return (
    <PageSection>
      <Formik
        initialValues={newsDto}
        onSubmit={handleSubmit}
        validate={createValidator(CreateNewsDtoValidator)}
        validateOnChange={false}
        handleSubmit
      >
        {({ handleSubmit, handleChange, values, errors, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <Stack spacing={2}>
              <SwitchInput
                value={values.fixed}
                onChange={handleChange}
                name={'fixed'}
                label={'newsDashboard.fixed'}
              />
              <Input
                value={values.title}
                name={'title'}
                label={'newsDashboard.title'}
                required
                onChange={handleChange}
                error={errors.title}
              />
              <LanguageSelect
                value={values.language}
                name="language"
                onChange={handleChange}
                required
                helperText="newsDashboard.languageHelperText"
              />
              <HtmlEditorInput
                isRequired={true}
                value={body}
                allowedFormats={['bold', 'header', 'italic', 'list', 'link']}
                customToolbar={[
                  [{ header: [3, 4, false] }],
                  ['bold', 'italic', 'link'],
                ]}
                label="newsDashboard.description"
                errorLabel={errors.description}
                setValue={(value) => {
                  setBody(value);
                  setFieldValue('description', value);
                }}
              />
              <ImageUploadField
                label={`${t('common.image')} *`}
                error={errors.imageUrl}
                value={values.imageUrl}
                fieldName={'imageUrl'}
                setFieldValue={setFieldValue}
              />

              <FormHelperText error>
                {(updateResult.error || createResult.error) &&
                  t('newsDashboard.error')}
              </FormHelperText>

              <LoadingButton
                loading={updateResult.isLoading || createResult.isLoading}
                fullWidth
                type="submit"
                variant="contained"
              >
                {t('save')}
              </LoadingButton>
            </Stack>
          </form>
        )}
      </Formik>
    </PageSection>
  );
}
