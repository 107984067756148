import { TextField, InputBaseProps, TextFieldProps } from '@mui/material';
import { isNil } from 'ramda';
import { ChangeEvent } from 'react';
import { Nullable } from 'typescript-nullable';
import InputWrapper, { InputWrapperProps } from './InputWrapper';
import useTranslationHelpers from '../hooks/useTranslationHelpers';

export type InputProps = Omit<InputWrapperProps, 'children'> & {
  multiline?: boolean;
  value: Nullable<string | number>;
  onChange: (e: ChangeEvent<any>) => void;
  error?: string | undefined;
  InputProps?: InputBaseProps;
  type?: string;
  disabled?: boolean;
  placeholder?: TextFieldProps['placeholder'];
};

export function Input({
  value,
  onChange,
  multiline,
  error,
  label,
  helperText,
  ...props
}: InputProps) {
  const { formatValidationError } = useTranslationHelpers();

  return (
    <InputWrapper {...props} label={label} helperText={helperText}>
      <TextField
        {...props}
        id={props.name}
        // No queremos el tooltip feo del navegador
        required={false}
        fullWidth
        value={value}
        error={!isNil(error)}
        helperText={formatValidationError(error)}
        onChange={onChange}
        multiline={multiline}
        InputProps={
          props.type === 'number'
            ? {
                inputProps: {
                  min: props.InputProps?.inputProps?.min ?? 0,
                },
              }
            : {}
        }
      />
    </InputWrapper>
  );
}
