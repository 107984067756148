import {
  Box,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SkeletonTableCell } from '../../common/SkeletonTableCell';

const StyledTableRow = styled(TableRow)(() => ({
  '& td': {
    border: 0,
    padding: 4,
  },
}));

export interface AccountInfoCardProps {
  title: string;
  data: [string, string | undefined][];
  isLoading: boolean;
}

export default function AccountInfoCard({
  title,
  data,
  isLoading,
}: AccountInfoCardProps) {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Grid item xs={12} md={6}>
      <Stack spacing={1}>
        <Typography fontWeight="bold" color="primary.main">
          {title}
        </Typography>
        <Box
          border={`${theme.palette.grey[200]} 0.5px solid`}
          borderRadius={theme.shape.borderRadius}
          p={1}
        >
          <Table>
            <TableBody>
              {data.map((row) => (
                <StyledTableRow>
                  <TableCell
                    width={isLoading ? '50%' : undefined}
                    sx={{
                      color: 'text.secondary',
                      fontWeight: 'bold',
                    }}
                  >
                    {t(row?.[0])}
                  </TableCell>
                  <SkeletonTableCell
                    isLoading={isLoading}
                    sx={{ color: 'text.secondary' }}
                  >
                    {row?.[1] ?? '-'}
                  </SkeletonTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Stack>
    </Grid>
  );
}
