import {
  Card,
  CardContent,
  CardMedia,
  Skeleton,
  Typography,
} from '@mui/material';
import { TournamentAndDreamFinal } from '../../app/services/futbolProdeApi';
import { DreamFinalForm } from './DreamFinalForm';
import { SkeletonOr, useCompanyTranslations } from '@futbolprode/ui-common';

interface DreamFinalCardProps {
  settings: Partial<TournamentAndDreamFinal>;
  isLoading: boolean;
}

export default function DreamFinalCard({
  settings,
  isLoading,
}: DreamFinalCardProps) {
  const { tournament, dreamFinalSettings } = settings;
  const { tournamentName } = useCompanyTranslations();

  return (
    <Card>
      <CardMedia
        sx={{ height: 200, backgroundSize: 'contain' }}
        image={tournament?.logoUrl}
        title={tournamentName(tournament)!}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          <SkeletonOr
            isLoading={isLoading}
            value={tournamentName(tournament)}
          />
        </Typography>
        {isLoading ? (
          <Skeleton height={350} />
        ) : (
          <DreamFinalForm
            tournamentId={tournament!.id}
            dreamFinalSettings={dreamFinalSettings}
          />
        )}
      </CardContent>
    </Card>
  );
}
