import Page from '../../common/Page';
import { Routes, useCompanyRoutes } from '../../router';
import { UserForm } from './UserForm';

export default function InvitationCreatePage() {
  const { appRoute } = useCompanyRoutes();
  return (
    <Page
      breadcrumbs={[
        { label: 'sidebar.users' },
        {
          label: 'sidebar.pendingInvitations',
          url: appRoute(Routes.INVITATIONS),
        },
      ]}
      title="invitationsPage.edit"
    >
      <UserForm kind="invitation" />
    </Page>
  );
}
