import {
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Link,
  Skeleton,
  Button,
  Stack,
  Typography,
  ButtonProps,
} from '@mui/material';
import { Trivia } from '../../app/services/futbolProdeApi';
import { useI18nHelpers } from '@futbolprode/ui-common';
import { useTranslation } from 'react-i18next';
import { Routes, useCompanyRoutes } from '../../router';
import { TriviaKind } from './TriviasPage';
import { TriviaKindProps } from '../../router';
import { When } from 'react-if';
import { TriviaStatusChip } from './TriviaStatusChip';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useUserPermissions } from '../../common/hooks/useUserPermissions';

const statusToButtonProps: Record<
  Trivia['status'],
  ButtonProps & { text: string }
> = {
  draft: {
    color: 'success',
    text: 'triviasPage.actionPublish',
  },
  published: {
    color: 'warning',
    text: 'triviasPage.actionPause',
  },
  paused: {
    color: 'success',
    text: 'triviasPage.actionPublish',
  },
};

export function TriviaListItem({
  value,
  kind,
  isLoading,
  showStatus,
  onDelete,
}: {
  value: Trivia;
  kind: TriviaKind;
  isLoading: boolean;
  showStatus: boolean;
  onDelete: (trivia: Trivia) => void;
}) {
  const { isAdmin } = useUserPermissions();
  const { formatDate } = useI18nHelpers();
  const { t } = useTranslation();
  const { appRoute } = useCompanyRoutes();
  const location = useLocation();
  const source: TriviaKind = location.pathname.includes(Routes.TRIVIA_TEMPLATES)
    ? 'template'
    : 'company';

  const toHumanDate = (date?: string) => formatDate('P', new Date(date ?? 0));
  const triviaKindProps = TriviaKindProps[kind];
  const canEdit = kind === 'company' || isAdmin;
  const mainActionLabel = canEdit && showStatus ? 'common.edit' : 'common.view';

  const StatusActionButton = useMemo(() => {
    const props = statusToButtonProps[value.status];
    return (
      <Button {...props} variant="outlined">
        {t(props?.text)}
      </Button>
    );
  }, [t, value.status]);

  return (
    <ListItem
      key={value.id}
      alignItems="flex-start"
      secondaryAction={
        <Stack direction="row" spacing={1}>
          <Link
            href={appRoute(`${triviaKindProps.route}/${value.id}`, { source })}
            sx={{ textDecoration: 'none', color: 'inherit' }}
          >
            <Button variant="contained">{t(mainActionLabel)}</Button>
          </Link>
          <When condition={canEdit && showStatus}>
            {isAdmin && StatusActionButton}
            <Button
              variant="outlined"
              color="error"
              onClick={() => onDelete(value)}
            >
              {t('common.delete')}
            </Button>
          </When>
        </Stack>
      }
    >
      <ListItemAvatar>
        {isLoading ? (
          <Skeleton variant="circular" width={40} height={40} />
        ) : (
          <Avatar alt={value.title} src={value.coverImageUrl} />
        )}
      </ListItemAvatar>
      <ListItemText
        primary={
          isLoading ? (
            <Skeleton width="30%" />
          ) : (
            <Stack direction="row" spacing={1} alignItems="center">
              <When condition={showStatus}>
                <TriviaStatusChip value={value.status} />
              </When>
              <Typography>{value.title}</Typography>
            </Stack>
          )
        }
        secondary={
          isLoading ? (
            <Skeleton width="50%" />
          ) : (
            t('triviasPage.listItemDescription', {
              startsAt: toHumanDate(value.startsAt),
              finishesAt: toHumanDate(value.finishesAt),
            })
          )
        }
      />
    </ListItem>
  );
}
