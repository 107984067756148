import {
  Trivia,
  UpdateTriviaDto,
  useTriviaSolutionsControllerResetTriviaMutation,
  useTriviasControllerCreateMutation,
  useTriviasControllerUpdateMutation,
} from '../../app/services/futbolProdeApi';
import { Routes, useCompanyRoutes } from '../../router';
import { useNavigate } from 'react-router-dom';
import { isNil } from 'ramda';
import { triviaStatusToColor } from './TriviaStatusChip';
import { omitDeep } from '../../common/helpers/ramdaExtension';
import { TriviaKind } from './TriviasPage';
import ConfirmationModal from '../../common/ConfirmationModal';
import { useState } from 'react';
import { When } from 'react-if';
import { TriviaStatusAlert } from './TriviaStatusAlert';
import { useFormikContext } from 'formik';

export function TriviaStatusExplanation({
  kind,
  trivia,
  isLoading,
}: {
  kind: TriviaKind;
  trivia: Trivia | undefined;
  isLoading: boolean;
}) {
  const { values } = useFormikContext<UpdateTriviaDto>();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [createTrivia, createResult] = useTriviasControllerCreateMutation();
  const [updateTrivia, updateResult] = useTriviasControllerUpdateMutation();
  const [resetTrivia, resetResult] =
    useTriviaSolutionsControllerResetTriviaMutation();
  const { appRoute } = useCompanyRoutes();

  const navigate = useNavigate();

  if (isNil(trivia)) {
    return null;
  }

  const publishTrivia = () =>
    updateTrivia({
      id: trivia.id,
      updateTriviaDto: {
        ...values,
        status: 'published',
      },
    }).unwrap();

  const states = {
    template: {
      requiresConfirmation: false,
      action: async () => {
        const cleanData = omitDeep(['id'], trivia);
        const newTrivia = await createTrivia({
          ...cleanData,
          status: 'draft',
        }).unwrap();
        navigate(appRoute(`${Routes.TRIVIAS}/${newTrivia.id}`));
      },
      isLoading: createResult.isLoading,
    },
    draft: {
      requiresConfirmation: true,
      action: publishTrivia,
      isLoading: updateResult.isLoading,
    },
    paused: {
      requiresConfirmation: false,
      action: publishTrivia,
      isLoading: updateResult.isLoading,
    },
    published: {
      requiresConfirmation: true,
      action: async () =>
        Promise.all([
          updateTrivia({
            id: trivia.id,
            updateTriviaDto: {
              status: 'paused',
            },
          }).unwrap(),
          resetTrivia(trivia.id).unwrap(),
        ]),
      isLoading: updateResult.isLoading || resetResult.isLoading,
    },
  };

  const currentState = kind === 'template' ? 'template' : trivia.status;
  const {
    action,
    isLoading: isActionLoading,
    requiresConfirmation,
  } = states[currentState];

  return (
    <>
      <When condition={requiresConfirmation}>
        <ConfirmationModal
          descriptionKey={`triviasPage.states.${currentState}.confirmation`}
          open={showConfirmationModal}
          onAccept={async () => {
            await action();
            setShowConfirmationModal(false);
          }}
          onCancel={() => setShowConfirmationModal(false)}
          isSubmitting={isActionLoading}
        />
      </When>
      <TriviaStatusAlert
        color={
          currentState === 'template'
            ? 'info'
            : triviaStatusToColor[currentState]
        }
        state={currentState}
        isActionLoading={isActionLoading}
        isTriviaLoading={isLoading}
        onClick={
          requiresConfirmation ? () => setShowConfirmationModal(true) : action
        }
      />
    </>
  );
}
