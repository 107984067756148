import { AuthenticationForm } from './AuthenticationForm';
import { UpdateCompanySettingsDashboard } from './UpdateCompanySettingsDashboard';

export function AuthenticationPage() {
  return (
    <UpdateCompanySettingsDashboard
      Form={AuthenticationForm}
      title="sidebar.authentication"
    />
  );
}
