import { useTranslation } from 'react-i18next';
import {
  useUsersControllerRemoveAllMutation,
  useUsersControllerSearchQuery,
} from '../../app/services/futbolProdeApi';
import Page from '../../common/Page';
import { UsersTable } from './UsersTable';
import { usePaginationSearchParams } from './usePaginationSearchParams';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';
import ConfirmationModal from '../../common/ConfirmationModal';
import { useUserPermissions } from '../../common/hooks/useUserPermissions';
import { useDownloadFile } from './authUtils';
import { Download } from '@mui/icons-material';
import FloatingButton from '../../common/FloatingButton';
import { Stack } from '@mui/material';

export function ActiveUsersPage() {
  const { canDeleteUsers } = useUserPermissions();
  const { t } = useTranslation();
  const [showConfirmationModal, setShowDeleteConfirmation] = useState(false);
  const { params, pageSize, page, setPage, ...rest } =
    usePaginationSearchParams();
  const { data, isLoading } = useUsersControllerSearchQuery(params);
  const [deleteAll, deleteAllResult] = useUsersControllerRemoveAllMutation();
  const { downloadFile, isLoading: isDownloading } = useDownloadFile({
    url: 'users/download/csv',
    method: 'POST',
  });

  const users = data?.data ?? [];

  return (
    <Page
      breadcrumbs={[{ label: 'sidebar.users' }]}
      actions={
        <Stack direction="row" spacing={2}>
          {canDeleteUsers ? (
            <LoadingButton
              color="error"
              variant="contained"
              onClick={() => setShowDeleteConfirmation(true)}
              loading={deleteAllResult.isLoading}
            >
              {t('activeUsersPage.deleteAll')}
            </LoadingButton>
          ) : (
            <></>
          )}
          <FloatingButton
            color="primary"
            onClick={() => downloadFile()}
            isLoading={isDownloading}
          >
            <Download />
          </FloatingButton>
        </Stack>
      }
      title="sidebar.activeUsers"
    >
      <UsersTable
        kind="user"
        isLoading={isLoading}
        noElementsText={t('activeUsersPage.noActiveUsers')}
        users={users}
        pageSize={pageSize}
        page={page}
        setPage={setPage}
        count={data?.pagination?.count ?? 0}
        {...rest}
      />
      <ConfirmationModal
        descriptionKey={'activeUsersPage.confirmDeleteAll'}
        open={showConfirmationModal}
        onAccept={() => {
          deleteAll(false);
          setShowDeleteConfirmation(false);
        }}
        onCancel={() => setShowDeleteConfirmation(false)}
        isSubmitting={deleteAllResult.isLoading}
        isDelete
      />
    </Page>
  );
}
