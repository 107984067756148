import { Box, Grid, Divider, Typography, useTheme } from '@mui/material';
import { ElementType } from 'react';

export function ImportUsersResultsRow({
  Icon,
  text,
}: {
  Icon: ElementType;
  text: string;
}) {
  const theme = useTheme();
  return (
    <>
      <Grid item xs={12} p={2}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Icon sx={{ color: theme.palette.primary.light }} />
          <Typography pl={1} color="text.primary">
            {text}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </>
  );
}
