import LoadingButton from '@mui/lab/LoadingButton';
import { Box, BoxProps, Button, Modal, Stack, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

const style: BoxProps['sx'] = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 450,
  bgcolor: 'background.paper',
  borderRadius: '5px',
  boxShadow: 24,
  p: 4,
};

export default function ConfirmationModal({
  titleKey = 'confirmationModal.defaultTitle',
  descriptionKey,
  descriptionValues,
  open,
  onCancel,
  onAccept,
  isDelete = false,
  isSubmitting = false,
}: {
  titleKey?: string | null;
  descriptionKey: string;
  descriptionValues?: any;
  open: boolean;
  onCancel: () => void;
  onAccept: () => void;
  isSubmitting?: boolean;
  isDelete?: boolean;
}) {
  const { t } = useTranslation();
  return (
    <Modal
      open={open}
      onClose={onCancel}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Stack spacing={2}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {t(titleKey ?? '')}
          </Typography>
          <Typography id="modal-modal-description" textAlign="justify">
            <Trans
              i18nKey={descriptionKey}
              values={descriptionValues}
              components={{ i: <i />, b: <strong />, p: <p /> }}
            />
          </Typography>
          <Stack direction="row" justifyContent="flex-end" spacing={2}>
            <Button
              disabled={isSubmitting}
              onClick={() => onCancel && onCancel()}
            >
              {t('common.cancel')}
            </Button>
            <LoadingButton
              loading={isSubmitting}
              variant="contained"
              onClick={onAccept}
              color={isDelete ? 'error' : 'primary'}
            >
              {t(`common.${isDelete ? 'delete' : 'accept'}`)}
            </LoadingButton>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
}
