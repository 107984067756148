import Page from '../../common/Page';
import TranslationTabs, {
  VALID_LOCALES,
} from '../translations/TranslationsTabs';
import AnnouncementsForm from './AnnouncementsForm';
import { Formik } from 'formik';
import {
  Translation,
  UpdateCompanyDto,
  useCompaniesControllerFindMeQuery,
  useCompaniesControllerUpdateMeMutation,
  useTranslationsControllerAllCompanyLocalesQuery,
  useTranslationsControllerCreateMutation,
} from '../../app/services/futbolProdeApi';
import { compose, fromPairs, map, mergeDeepRight } from 'ramda';
import { useMemo } from 'react';
import { LoadingButton } from '@mui/lab';
import { deleteEmptyKeys } from '../../common/helpers/objectHelpers';
import { useTranslation } from 'react-i18next';
import { Nullable } from 'typescript-nullable';
import { LinearProgress, Stack } from '@mui/material';
import { If, Then, Else } from 'react-if';

export interface AnnouncementsDto {
  translations: {
    [key: string]: {
      announcements: {
        login: string;
        mainPage: string;
      };
      customization: {
        loginWelcomeMessage: string;
      };
    };
  };

  company: UpdateCompanyDto;
}

export default function AnnouncementsPage() {
  const { t } = useTranslation();
  const { data: company, isLoading: companyLoading } =
    useCompaniesControllerFindMeQuery();
  const { data: translations, isLoading: translationsLoading } =
    useTranslationsControllerAllCompanyLocalesQuery();

  const translationsPerLocale = useMemo(
    () =>
      Nullable.map(
        // @ts-expect-error
        compose(
          fromPairs,
          map((it: Translation) => [it.locale, JSON.parse(it.data)]),
        ),
        translations,
      ),
    [translations],
  );

  const [createTranslations, translationsResult] =
    useTranslationsControllerCreateMutation();
  const [updateCompany, companyResult] =
    useCompaniesControllerUpdateMeMutation();

  const announcementsDto: AnnouncementsDto = useMemo(
    () =>
      mergeDeepRight(
        {
          // @ts-expect-error
          translations: compose(
            fromPairs,
            map((locale: string) => [
              locale,
              {
                announcements: {
                  login: '',
                  mainPage: '',
                },
                customization: {
                  loginWelcomeMessage: '',
                },
              },
            ]),
          )(VALID_LOCALES) as AnnouncementsDto['translations'],
          company: {
            loginAnnouncementIsEnabled: false,
            loginAnnouncementSeverity: 'info',
            mainPageAnnouncementIsEnabled: false,
            mainPageAnnouncementSeverity: 'info',
          },
        },
        {
          translations: translationsPerLocale ?? {},
          company: (company as UpdateCompanyDto) ?? {},
        },
      ),
    [translationsPerLocale, company],
  );

  const handleSubmit = async (values: AnnouncementsDto) => {
    Object.entries(values.translations).forEach(([locale, values]) => {
      deleteEmptyKeys(values.announcements);

      const translationsBody = {
        locale,
        data: JSON.stringify(values),
      };

      createTranslations(translationsBody);
    });

    updateCompany(values.company);
  };

  return (
    <Page
      breadcrumbs={[{ label: 'sidebar.settings' }]}
      title="sidebar.announcements"
    >
      <If condition={companyLoading || translationsLoading}>
        <Then>
          <LinearProgress /> :
        </Then>
        <Else>
          <Formik
            initialValues={announcementsDto}
            onSubmit={handleSubmit}
            validateOnChange={false}
            handleSubmit
          >
            {({ handleSubmit, handleChange, values, setFieldValue }) => (
              <form onSubmit={handleSubmit}>
                <Stack spacing={2}>
                  <TranslationTabs>
                    {(locale) =>
                      AnnouncementsForm({
                        locale,
                        handleChange,
                        values,
                        setFieldValue,
                      })
                    }
                  </TranslationTabs>
                  <LoadingButton
                    loading={
                      translationsResult.isLoading || companyResult.isLoading
                    }
                    fullWidth
                    type="submit"
                    variant="contained"
                  >
                    {t('save')}
                  </LoadingButton>
                </Stack>
              </form>
            )}
          </Formik>
        </Else>
      </If>
    </Page>
  );
}
