import {
  FormHelperText,
  Stack,
  Button,
  Checkbox,
  Fab,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import {
  Company,
  useCompaniesControllerUpdateMutation,
} from '../../app/services/futbolProdeApi';
import { LoadingButton } from '@mui/lab';
import { Formik } from 'formik';
import { t } from 'i18next';
import { PageSection } from '../../common/PageSection';
import { PlusOne, Delete } from '@mui/icons-material';
import { isEmpty, reject } from 'ramda';
import { If, Then, Else } from 'react-if';
import { Input } from '../../common/form/Input';
import { LoginStrategy } from './LoginStrategy';
import InputWrapper from '../../common/form/InputWrapper';
import SwitchInput from '../../common/form/SwitchInput';

const EXTRA_FIELD_TYPES = ['string', 'number', 'boolean', 'email', 'date'];
export function AuthenticationForm({ company }: { company?: Company }) {
  const [updateCompany, updateResult] = useCompaniesControllerUpdateMutation();
  const handleSubmit = async (formValues: any) => {
    const values = {
      ...formValues,
    };
    const companyFormValues = values as any;

    companyFormValues.extraFields = companyFormValues.extraFields.filter(
      (it: { name: string }) => it.name,
    );
    if (company) {
      await updateCompany({
        id: company!.id.toString(),
        updateCompanyDto: companyFormValues,
      });
    }
  };

  const companyDto = {
    extraFields: company?.extraFields || [],
    loginStrategy: company?.loginStrategy || LoginStrategy.byEmail,
    requiresEmailVerification: company?.requiresEmailVerification || false,
  };

  return (
    <Formik
      initialValues={companyDto}
      onSubmit={handleSubmit}
      validateOnChange={false}
      handleSubmit
    >
      {({ handleSubmit, handleChange, values, setFieldValue }) => (
        <form onSubmit={handleSubmit}>
          <Stack spacing={2}>
            <PageSection title="companyForm.login">
              <SwitchInput
                name={'requiresEmailVerification'}
                label={'companiesDashboard.requiresEmailVerification'}
                value={values.requiresEmailVerification}
                onChange={handleChange}
              />
              <InputWrapper
                name={'loginStrategy'}
                label={'companiesDashboard.loginStrategy'}
              >
                <Select
                  labelId="login-strategy-select-label"
                  name="loginStrategy"
                  id="login-strategy-select"
                  value={values.loginStrategy}
                  label={t('companiesDashboard.loginStrategy')}
                  onChange={handleChange}
                  fullWidth
                >
                  <MenuItem value={0}>
                    {t('companiesDashboard.byEmail')}
                  </MenuItem>
                  <MenuItem value={1}>
                    {t('companiesDashboard.byExternalId')}
                  </MenuItem>
                </Select>
              </InputWrapper>
            </PageSection>

            <PageSection
              title={t('companiesDashboard.extraFields')}
              actions={
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    setFieldValue(
                      'extraFields',
                      (values.extraFields as any[]).concat({
                        name: '',
                        isRequired: false,
                        type: 'string',
                      }),
                    );
                  }}
                  startIcon={<PlusOne />}
                >
                  {t('companiesDashboard.addExtraField')}
                </Button>
              }
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{t('companiesDashboard.name')}</TableCell>
                    <TableCell>{t('companiesDashboard.type')}</TableCell>
                    <TableCell>{t('companiesDashboard.isRequired')}</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <If condition={!isEmpty(values.extraFields)}>
                    <Then>
                      {((values.extraFields as any[]) || []).map(
                        (extraField, i) => (
                          <TableRow key={i}>
                            <TableCell>
                              <Input
                                value={extraField.name || ''}
                                name={`extraFields[${i}].name`}
                                label={''}
                                onChange={handleChange}
                                error={''}
                              />
                            </TableCell>
                            <TableCell>
                              <Select
                                name={`extraFields[${i}].type`}
                                id={`extraFields[${i}].type`}
                                value={extraField.type}
                                label={t('companiesDashboard.type')}
                                onChange={handleChange}
                              >
                                {EXTRA_FIELD_TYPES.map((extraFieldType) => (
                                  <MenuItem
                                    key={extraFieldType}
                                    value={extraFieldType}
                                  >
                                    {t(
                                      `companiesDashboard.extraFieldType.${extraFieldType}`,
                                    )}
                                  </MenuItem>
                                ))}
                              </Select>
                            </TableCell>
                            <TableCell>
                              <Checkbox
                                id={`extraFields[${i}].isRequired`}
                                checked={extraField.isRequired}
                                onChange={handleChange}
                                name={`extraFields[${i}].isRequired`}
                              />
                            </TableCell>
                            <TableCell>
                              <Fab
                                onClick={() => {
                                  const deleteExtraFieldConfirmMessage = t(
                                    'companiesDashboard.deleteExtraFieldConfirmMessage',
                                    { name: extraField.name },
                                  );
                                  if (
                                    window.confirm(
                                      deleteExtraFieldConfirmMessage,
                                    )
                                  ) {
                                    const newExtraFields = reject(
                                      (it: { name: string }) =>
                                        it.name === extraField.name,
                                      values.extraFields as any[],
                                    );
                                    setFieldValue(
                                      'extraFields',
                                      newExtraFields,
                                    );
                                  }
                                }}
                                color="secondary"
                                size="small"
                              >
                                <Delete />
                              </Fab>
                            </TableCell>
                          </TableRow>
                        ),
                      )}
                    </Then>
                    <Else>
                      <TableRow>
                        <TableCell>
                          {t('companiesDashboard.noExtraFields')}
                        </TableCell>
                      </TableRow>
                    </Else>
                  </If>
                </TableBody>
              </Table>
            </PageSection>
            <FormHelperText error>
              {updateResult.error && t('companiesDashboard.error')}
            </FormHelperText>
            <LoadingButton
              loading={updateResult.isLoading}
              fullWidth
              type="submit"
              variant="contained"
            >
              {t('save')}
            </LoadingButton>
          </Stack>
        </form>
      )}
    </Formik>
  );
}
