import Page from '../../../common/Page';
import { Routes, useCompanyRoutes } from '../../../router';
import { CombinedRoundsForm } from './CombinedRoundsForm';

export function CreateCombinedRounds() {
  const { appRoute } = useCompanyRoutes();
  return (
    <Page
      breadcrumbs={[
        { label: 'sidebar.game' },
        {
          label: 'sidebar.combinedRounds',
          url: appRoute(Routes.COMBINED_ROUNDS),
        },
      ]}
      title="combinedRoundsDashboard.createCombinedRounds"
    >
      <CombinedRoundsForm />
    </Page>
  );
}
