import {
  useTranslationsControllerAllCompanyLocalesQuery,
  useTranslationsControllerAllCommonLocalesQuery,
  Translation,
  useTranslationsControllerCreateMutation,
  useTranslationsControllerCreateCommonMutation,
  CreateTranslationDto,
} from '../../app/services/futbolProdeApi';
import { compose, fromPairs, map } from 'ramda';
import { useMemo } from 'react';
import { Nullable } from 'typescript-nullable';
import { VALID_LOCALES } from './TranslationsTabs';
import { deepOmitEmptyValues } from '../../common/helpers/ramdaExtension';
import { FormikErrors, FormikHandlers, FormikHelpers } from 'formik';

export interface TranslationsDto<T extends Record<string, any>> {
  [locale: string]: T;
}

export type TranslationsKind = 'common' | 'company';

export interface TranslationFormProps<T extends Record<string, any>> {
  locale: string;
  handleChange: FormikHandlers['handleChange'];
  errors: FormikErrors<T>;
  values: T;
  setFieldValue: FormikHelpers<any>['setFieldValue'];
}

export function useTranslationsPerLocaleQuery<T extends Record<string, any>>(
  kind: TranslationsKind,
) {
  const commonResult = useTranslationsControllerAllCommonLocalesQuery(
    undefined,
    { skip: kind === 'company' },
  );

  const companyResult = useTranslationsControllerAllCompanyLocalesQuery(
    undefined,
    { skip: kind === 'common' },
  );

  const result = kind === 'common' ? commonResult : companyResult;

  const translationsPerLocale = useMemo(
    () =>
      Nullable.map<Translation[], TranslationsDto<T>>(
        compose(
          fromPairs,
          map((it) => [it.locale, JSON.parse(it.data)] as [string, T]),
        ),
        result.data,
      ),
    [result.data],
  );

  return { ...result, data: translationsPerLocale };
}

export function defaultTranslations<T extends Record<string, any>>(
  value: T,
): TranslationsDto<T> {
  return compose(
    fromPairs,
    map((locale: string) => [locale, value] as [string, T]),
  )(VALID_LOCALES);
}

function postTranslations<T extends Record<string, any>>(
  mutation: (arg: CreateTranslationDto) => void,
) {
  return (values: TranslationsDto<T>) =>
    Object.entries(values).forEach(([locale, data]) => {
      const cleanData = deepOmitEmptyValues(data);

      const translationsBody = {
        locale,
        data: JSON.stringify(cleanData),
      };

      mutation(translationsBody);
    });
}

export function useCreateTranslationsPerLocaleMutation(
  kind: 'company' | 'common',
) {
  const [createCompanyTranslations, companyResult] =
    useTranslationsControllerCreateMutation();

  const [createCommonTranslations, commonResult] =
    useTranslationsControllerCreateCommonMutation();

  const mutation = useMemo(
    () =>
      kind === 'company'
        ? {
            createTranslations: postTranslations(createCompanyTranslations),
            result: companyResult,
          }
        : {
            createTranslations: postTranslations(createCommonTranslations),
            result: commonResult,
          },
    [
      kind,
      createCompanyTranslations,
      createCommonTranslations,
      companyResult,
      commonResult,
    ],
  );

  return mutation;
}
