import { useCompanyRoutes } from '../../router';
import Page from '../../common/Page';
import TriviaForm from './TriviaForm';
import { TriviasPageProps } from './TriviasPage';
import { TriviaKindProps } from '../../router';

export default function TriviaCreatePage({ kind }: TriviasPageProps) {
  const { appRoute } = useCompanyRoutes();
  const triviaKindProps = TriviaKindProps[kind];

  return (
    <Page
      breadcrumbs={[
        triviaKindProps.baseBreadcrumb,
        { label: 'sidebar.trivias', url: appRoute(triviaKindProps.route) },
      ]}
      title="triviasPage.new"
    >
      <TriviaForm kind={kind} />
    </Page>
  );
}
