import { Tab, Tabs } from '@mui/material';
import { Image } from 'mui-image';
import CustomTabPanel from '../../common/CustomTabPanel';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const LOCALES_WITH_FLAG = [
  { code: 'es', flag: 'ar' },
  { code: 'en', flag: 'us' },
  { code: 'pt', flag: 'br' },
] as const;

export const VALID_LOCALES = LOCALES_WITH_FLAG.map((it) => it.code);

export default function TranslationTabs({
  children,
}: {
  children: (locale: string) => React.ReactNode;
}) {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState(0);
  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  return (
    <>
      <Tabs value={currentTab} onChange={handleTabChange} centered>
        {LOCALES_WITH_FLAG.map(({ code, flag }, index) => (
          <Tab
            key={index}
            icon={
              <Image
                alt={`Flag ${flag}`}
                src={`https://flagcdn.com/h40/${flag}.png`}
                style={{ height: 20 }}
                fit="contain"
              />
            }
            label={t(`common.languages.${code}`)}
            sx={{ cursor: 'pointer' }}
          />
        ))}
      </Tabs>

      <>
        {LOCALES_WITH_FLAG.map(({ code }, index) => (
          <CustomTabPanel value={currentTab} index={index} key={index}>
            {children(code)}
          </CustomTabPanel>
        ))}
      </>
    </>
  );
}
