import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ListItem,
  Stack,
  TypographyProps,
  useTheme,
} from '@mui/material';
import {
  Area,
  useAreasControllerFindAllQuery,
} from '../../app/services/futbolProdeApi';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { path } from 'ramda';
import { AreaItem } from './AreaItem';

const stylesPerLevel: Record<
  number,
  { typography: TypographyProps['variant']; backgroundColor: string }
> = {
  1: { typography: 'h5', backgroundColor: 'primary.light' },
  2: { typography: 'h6', backgroundColor: 'grey.300' },
  3: { typography: 'body1', backgroundColor: 'grey.200' },
};

export default function AreaDetails({
  area,
  level,
}: {
  area: Area;
  level: number;
}) {
  const { data } = useAreasControllerFindAllQuery(
    level < 3 ? level + 1 : skipToken,
  );

  const theme = useTheme();

  const subareas = data?.filter((subarea) => subarea.parentArea.id === area.id);
  const { typography, backgroundColor } = stylesPerLevel[level as 1 | 2 | 3];
  const color = theme.palette.getContrastText(
    path(backgroundColor.split('.'), theme.palette)!,
  );

  return level === 3 ? (
    <ListItem sx={{ backgroundColor, color }}>
      <AreaItem area={area} typography={typography} />
    </ListItem>
  ) : (
    <Accordion sx={{ width: '100%', backgroundColor, color }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`area-${area.id}-content`}
        id={`area-${area.id}-header`}
      >
        <AreaItem area={area} typography={typography} />
      </AccordionSummary>
      <AccordionDetails>
        <Stack spacing={1}>
          {subareas?.map((subarea) => (
            <AreaDetails area={subarea} level={level + 1} key={subarea.id} />
          ))}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
}
